/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME, LIVE_EVENT_ROUNDS, LIVE_EVENT_TYPES, PUBLISHING_SOURCE } from "../../../framework/constant/constant";
import * as API from "../../../framework/API/api";
import { toast } from 'react-toastify';
import { useTranslation } from "../../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import CollectionSelection from "../../../framework/forms/CollectionSelection";
import { utility } from "../../../framework/utility/utilityProvider";
import { TimePickerWithFormat } from '../../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import moment from "moment";
import InfoRoundButton from "../../../components/InfoRoundButton";
import SaveButton from "../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../framework/forms/helpercomponents/buttons/CancelButton";
import ActionButton from "../../../framework/forms/helpercomponents/buttons/ActionButton";
import { DataHelper } from "../../../framework/helper/DataHelper";

export const LiveMatchEditForm = (props) => {

  const [dataItem, setDataItem] = useState({
    ...props.item,
    SID: props.item.SID ?? 0,
    Title: props.item.MediaEpisode?.Title ?? '',
    Description: props.item.MediaEpisode?.Description ?? '',
    Content: props.item.MediaEpisode?.Content ?? {},
    AssetId: props.item.MediaEpisode?.AssetId ?? '',
    TeamA: props.item.TeamA ?? '',
    TeamB: props.item.TeamB ?? '',
    Round: props.item.Round ?? {},
    Type: props.item.Type ?? {},
    StartDate: props.item.SID > 0 ? moment(new Date(props.item.StartDateTime)).utc().format('YYYY-MM-DD') : moment(new Date()).utc().format('YYYY-MM-DD'),
    StartTime: props.item.SID > 0 ? moment(new Date(props.item.StartDateTime)).utc().format('HH:mm:ss:SS') : '00:00:00:00',
    Duration: props.item.SID > 0 ? utility.convertMilisecondsToStringWithFrames(props.item.Duration) : '',
    Venue: props.item.Venue ?? '',
    PosterUrl: props.item.PosterUrl ?? '',
    IsLive: props.item.MediaEpisode?.IsLive ?? false,
    Tournament: props.selectedTournament ?? {},
  });

  const [isAutoID,setIsAutoID] = useState(false);

  const [content, setContent] = useState([]);
  const [mediaCategory, setMediaCategory] = useState([]);
  const [mediaCategoryType, setMediaCategoryType] = useState([]);
  const [genre, setGenre] = useState([]);
  const [liveEventRound, setLiveEventRound] = useState([]);
  const [posterOpen, setPosterOpen] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  let isPlanning = props?.isPlanning ?? false;
  let mediaEpisodeRef = useRef();

  const lang = useTranslation();

  useEffect(() => {
    loadcombo();
  }, []);

  const isValid = () => {

    if (dataItem.Title == undefined || dataItem.Title === "") {
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (props.item.SID == 0 && isAutoID) {
      if (Object.keys(dataItem.Content).length === 0) {
        toast.error(`${lang.please_select_content_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }

    if (dataItem.StartDate == undefined || dataItem.StartDate == '') {
      toast.error(`${lang.please_select_start_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.StartTime == undefined || dataItem.StartTime == '') {
      toast.error(`${lang.please_enter_valid_start_time_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem.StartDate)) <= props?.selectedTournament?.StartDate ||
      utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem.StartDate)) >= props?.selectedTournament?.EndDate) {
      toast.error(`${lang.please_select_start_date_between_tournament_date_range_error}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Duration == undefined || dataItem.Duration.includes('_') || dataItem.Duration == '' || utility.convertStringWithFramesToMilliseconds(dataItem.Duration) == 0) {
      toast.error(`${lang.invalid_duration_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if(isPlanning && dataItem.Tournament == undefined || Object.keys(dataItem.Tournament).length == 0){
      toast.error(`${lang.please_select_tournament_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;

  }

  const loadcombo = async () => {
    let content = await API.getDataLookup(ENTITYNAME.Content, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    let filteredContent = content.data.filter((item) => item.MediaCategoryType.MediaCategorySID == props.mediaCategory)
    setContent(filteredContent);

    let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } });
    setMediaCategory(mediaCategory.data);

    let mediaCategoryType = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { Description: 1 } });
    setMediaCategoryType(mediaCategoryType.data);

    let genres = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
    setGenre(genres.data);

    let liveEventRound = await API.getDataLookup(ENTITYNAME.LiveEventRound, { sort: { SID: 1 } });
    setLiveEventRound(liveEventRound.data);

    let tournamentRes = await API.getDataLookup(ENTITYNAME.Tournament, { sort: { SID: 1 } });
    setTournaments(tournamentRes.data);

    let mediaEpisodeRes = await API.getData(ENTITYNAME.MediaEpisode, { query: ["_id", "=", props?.item?.MediaEpisode_id ?? props?.item?.MediaEpisode?._id] });
    mediaEpisodeRef.current = mediaEpisodeRes.data.length > 0 ? mediaEpisodeRes.data[0] : {};
  }

  const onchange = async (e) => {
    if (e.target.name == "IsLive") {
      setDataItem({ ...dataItem, IsLive: !dataItem.IsLive });
    } else if (e.target.name == "Content") {
      //check 
      const updatedContent = await API.getAndSaveNextNumber(e.target.value.SID, { ...dataItem.Content, isSaveNextnumber: false });
      if (!updatedContent.success) return;
      console.log(!updatedContent.success);
      setDataItem({ ...dataItem, Content: e.value, AssetId: updatedContent.data.HouseNumber });
    } else if (e.target.name == "Type") {
      setDataItem({ ...dataItem, Type: e.target.value, IsLive: e.target.value.IsLive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const handlePosterImageSelect = (dataList) => {
    setDataItem({ ...dataItem, PosterUrl: dataList[0].ImageUrl });
  }

  const handleSubmit = async () => {

    if (isValid()) {

      //Save MediaEpisode
      var mediaEpisode = {
        SID: 0,
        Title: dataItem?.Title,
        Description: dataItem?.Description,
        AssetId: dataItem?.AssetId,
        Content: isAutoID ? dataItem?.Content : {},
        Genres: genre.filter((obj) => obj.Description == 'Sports'),
        ReleaseDate: utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem?.StartDate)),
        MediaCategory: mediaCategory.find((obj) => obj.SID == props.mediaCategory),
        MediaCategoryType: mediaCategoryType.find((obj) => obj.SID == props.mediaCategoryType),
        Duration: utility.convertStringWithFramesToMilliseconds(dataItem?.Duration ?? '00:00:00:00'),
        ImageUrl: dataItem?.PosterUrl.length != 0 ? dataItem?.PosterUrl : props.selectedTournament.PosterUrl,
        IsLive: true,
        Archive: dataItem?.Archive ?? false,
        checkDuplicate: true,
        query: [["Tilte", "=", dataItem?.Title]]
      }

      if(props.item.SID == 0 && isAutoID){
        const updatedContent = await API.getAndSaveNextNumber(dataItem.Content.SID, { ...dataItem.Content, isSaveNextnumber: true });
        if (!updatedContent.success) return;
        console.log(updatedContent);
        mediaEpisode.AssetId = updatedContent.data.HouseNumber
      }

      if (props.item.SID > 0) {
        mediaEpisode._id = props.item.MediaEpisode._id
        mediaEpisode.SID = props.item.MediaEpisode.SID
      }

      let data = DataHelper.saveMediaEpisodeEntity(mediaEpisode, mediaEpisode.MediaCategory);

      var res = await API.saveData(ENTITYNAME.MediaEpisode, data);

      if (res.success) {

        //Save media episode live event detail here
        var localData = {
          SID: dataItem.SID,
          MediaEpisode_id: res.data._id.toString(),
          Tournament_id: dataItem?.Tournament._id,
          TeamA: dataItem?.TeamA ?? '',
          TeamB: dataItem?.TeamB ?? '',
          StartDateTime: utility.getLocalDateTimeToUtcMiliseconds(new Date(`${dataItem?.StartDate} ${dataItem?.StartTime}`)),
          Duration: utility.convertStringWithFramesToMilliseconds(dataItem?.Duration),
          Round: {
            _id: dataItem?.Round._id,
            SID: dataItem?.Round.SID,
            Description: dataItem?.Round.Description,
          },
          Type: LIVE_EVENT_TYPES.length > 0 ? LIVE_EVENT_TYPES[0] : {},
          Venue: dataItem?.Venue,
          PosterUrl: dataItem?.PosterUrl.length != 0 ? dataItem?.PosterUrl : props.selectedTournament.PosterUrl,
          ScheduleStatus: props.item.SID > 0 ? dataItem?.ScheduleStatus.map(x => {
            return {
              ProgramSchedule_id: x.ProgramSchedule_id,
              Channel: {
                _id: x.Channel._id,
                SID: x.Channel.SID,
                FullChannelName: x.Channel.FullChannelName,
              },
              SlotDateTime: x.SlotDateTime
            }
          }) : [], // array of object
          IsScheduled: props.item.SID > 0 ? dataItem?.IsScheduled : false
        }

        if (props.item.SID > 0) {
          localData._id = props.item._id
        }

        var response = await API.saveData(ENTITYNAME.MediaEpisodeDetailLiveEvent, localData);
        if (response.success) {
          //creating default rights, not waiting 
          API.updateMediaDealRights(res.data, [], [], PUBLISHING_SOURCE.MediaEpisode, true);
          props.cancelEdit();
          props.refresh(res.data, response.data);
          return;
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  return (
    <>
      {<Dialog
        title={props.item.SID > 0 ? props.item.MediaEpisode.Title : `${lang.live_match_dialog_header}`}
        onClose={props.cancelEdit}
        width={"60%"}
      >
        <Form
          render={(formRenderProps) => (
            <FormElement>
              <div className="row">
                <div className="col-12">
                  <SaveButton style={{marginLeft: '-0px', borderRadius: '4px'}} onClick={handleSubmit} />
                  {props.item.SID > 0 && <ActionButton btnColor="info" title={lang.more_button_tooltip} name={lang.more_button_text} onClick={() => window.open(`/home/MediaEpisode/Program/${mediaEpisodeRef.current?.SID}`, '_blank')}/>}
                  <CancelButton onClick={props.cancelEdit} />
                  <div className="row">
                  <div className="col-3 mt-4">
                      <input type="checkbox" name={"IsAuto"} value={isAutoID} checked={isAutoID} onChange={() => { setIsAutoID(!isAutoID)}} />
                      <label className="pl-2" htmlFor="TabView">{'Auto ID'}</label>
                    </div>
                    </div>

                  <div className="row mt-2">
                    <div className="col-6 form-group">
                      <label htmlFor="">{lang.title_label} *</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="Title"
                        value={dataItem.Title}
                        onChange={onchange}
                      />
                    </div>

                    <div className="col-6 form-group">
                      <label htmlFor="">{lang.asset_id_label}</label>
                      {!isAutoID ? <input
                        type="text"
                        className="form-control form-control-sm"
                        name="AssetId"
                        value={dataItem.AssetId}
                        onChange={onchange}
                      /> : <h4>{dataItem.AssetId}</h4>}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="">{lang.description_label}</label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="Description"
                        value={dataItem.Description}
                        onChange={onchange}
                      />
                    </div>

                    {isAutoID && <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.content_label} *</label>
                      <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
                      <DropDownList
                        data={content}
                        name="Content"
                        textField="Description"
                        dataItemKey="SID"
                        value={dataItem.Content}
                        onChange={onchange}
                        disabled={props.item.SID > 0 ? true : false}
                      />
                    </div>}
                  </div>

                  <div className="row">
                    <div className="col-3 form-group">
                      <label htmlFor="TabView">{lang.start_date_label} *</label>
                      <input type="date" className="form-control form-control-sm" name="StartDate" value={dataItem.StartDate} onChange={onchange}
                        min={utility.datetimeFormatV2(props?.selectedTournament?.StartDate ?? '')}
                        max={utility.datetimeFormatV2(props?.selectedTournament?.EndDate ?? '')}
                      />
                    </div>

                    <div className="col-3">
                      <label htmlFor="">{lang.start_time_label} *</label>
                      <TimePickerWithFormat className="form-control form-control-sm" name="StartTime" value={dataItem.StartTime} onChange={onchange} />
                    </div>

                    <div className="col-6">
                      <label htmlFor="">{lang.duration_label} *</label>
                      <TimePickerWithFormat className="form-control form-control-sm" name="Duration" value={dataItem.Duration} onChange={onchange} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3 form-group">
                      <label htmlFor="TabView">{lang.team_a_label} </label>
                      <input type="text" className="form-control form-control-sm" name={"TeamA"} onChange={onchange} value={dataItem.TeamA} />
                    </div>
                    <div className="col-3 form-group">
                      <label htmlFor="TabView">{lang.team_b_label} </label>
                      <input type="text" className="form-control form-control-sm" name={"TeamB"} onChange={onchange} value={dataItem.TeamB} />
                    </div>

                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.round_label} </label>
                      <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={liveEventRound}
                        name="Round"
                        textField="Description"
                        dataItemKey="SID"
                        value={dataItem.Round}
                        onChange={onchange}
                      />
                    </div>
                  </div>  

                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.venue_label} (Optional)</label>
                      <input className="form-control form-control-sm" type="text" name={"Venue"} onChange={onchange} value={dataItem.Venue} />
                    </div>

                    <div className="col-5">
                      <label htmlFor="">{lang.poster_url_label} (Optional)</label>
                      <input
                        name={"PosterUrl"}
                        className="form-control form-control-sm"
                        type={'text'}
                        value={dataItem.PosterUrl}
                        onChange={onchange}
                      />
                    </div>

                    <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      style={{ height: "30px", marginTop: "20px" }} onClick={(e) => { setPosterOpen(true); e.preventDefault(); }}>
                      {'...'}
                    </button>
                  </div>

                  <div className="row form-group">
                    <div className="col-6">
                      <label htmlFor="TabView">{lang.tournament_label} *</label>
                      <DropDownList
                        data={tournaments}
                        style={{ backgroundColor: "white" }}
                        name="Tournament"
                        textField="Name"
                        dataItemKey="SID"
                        value={dataItem.Tournament}
                        onChange={onchange}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </FormElement>
          )} />
      </Dialog>}
      {posterOpen && <CollectionSelection entityname={ENTITYNAME.OttPosters} closeForm={() => { setPosterOpen(false) }} setDataList={handlePosterImageSelect} title={lang.select_portal_image_url_collection_header} mode={"single"} width={"50vw"} height={"60vh"} />}

    </>
  )
}