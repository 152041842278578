import Card from 'react-bootstrap/Card';
import { CONTENT_TYPE, OTT_POSTER_TYPE } from '../../../constant/constant';
import { utility } from '../../../utility/utilityProvider';
import { useEffect, useState } from 'react';

export const ImageCell = (props) => {

  const [imageUrl, setImageUrl] = useState('https://media.comicbook.com/files/img/default-movie.png');
  const [value, setValue] = useState('');
  const field = props.field || "";
  const width = props.width ?? "50px";
  const height = props.height ?? "50px";
  const posterType = field.toLowerCase().includes('portrait') ? OTT_POSTER_TYPE.Portrait : OTT_POSTER_TYPE.Landscape;

  useEffect(() => {

    var temp = '';
    if (field.includes('.')) {
      //For field value coming as example :- MediaCategory.Duration
      var keys = field.split('.');
      temp = props.dataItem[keys[0]] ? props.dataItem[keys[0]][keys[1]] ? props.dataItem[keys[0]][keys[1]] : '' : '';
    } else {
      temp = props.dataItem[field];
    }

    setValue(temp);

    var url = utility.getPosterUrl(temp, posterType);
    setImageUrl(url);

  }, [])


  var imgStyle = { objectFit: "cover", height: height, borderRadius: "8px", width: width };

  const handleError = () => {
    setImageUrl('https://media.comicbook.com/files/img/default-movie.png');
  }

  return <td className={props?.className ?? ''} style={{ ...props?.style, height: height }} title={value}>
    <Card.Img src={imageUrl} onError={handleError} style={{ ...imgStyle, objectFit: props?.objectFit ?? imgStyle.objectFit }} />
  </td>;
};