/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import * as API from '../../../framework/API/api';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ParamFilters from "./IngestionTemplateParamFilters";
import { DATE_TIME_FORMATS, ENTITYNAME, INGESTION_MODULETYPES } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";
import InfoRoundButton from "../../../components/InfoRoundButton";
import { Dialog } from "@progress/kendo-react-dialogs";


const IngestionTemplateBottomSection = (props) => {

    const lang = useTranslation();
    const [formatPopup,setFormatPopup] = useState(false);
    const [selectedFormField,setSelectedFormField] = useState({});
    const [mappingObject, setMappingObject] = useState([]);
    const [mapping, setMapping] = useState([]);

    useEffect(() => {

        if (props.SID > 0) {
            setMapping(props.mapping);
            setMappingObjectFromArray(props.mapping);
        }

    }, [props.mapping])

    const setMappingObjectFromArray = (mappingArray) => {

        var localMappingObject = {};
        mappingArray.forEach(element => {
            localMappingObject = { ...localMappingObject, [element.Key]: { ...element, Description: element.PartnerKey } }
        });
        console.log(localMappingObject);
        setMappingObject(localMappingObject);

    }

    const handleSaveTemplateChange = (e) => {
        
        let keyName = e.target.name.includes('.') ? e.target.name.split('.')[0] : e.target.name

        //CREATING MAPPINGS ON THE CHANGE OF DROPDOWNS ITSELF
        var mapkey = { Key: keyName, PartnerKey: '', Format: '' };

        var mappingLocal = [...mapping];
        var existingKey = false;
        if (e.target.name.includes('_Format')) {
            // find key from mapping
            let keyWithoutFormat = e.target.name.replace('_Format', '');
            existingKey = mappingLocal.find((current) => current.Key === keyWithoutFormat);
            if (!existingKey || existingKey?.PartnerKey == '') {
                alert('Please select ' + keyWithoutFormat + ' first');
                return;
            } else {
                mapkey.Key = existingKey.Key;
                mapkey.PartnerKey = existingKey.PartnerKey;
                mapkey.Format = e.target.value.Format;
            }

            setFormatPopup(false);

        } else {
            mapkey.Key = keyName;
            mapkey.PartnerKey = e.target.value.Description;
            existingKey = mappingLocal.find((current) => current.Key === keyName);
        }

        if (existingKey) {
            var index = mappingLocal.indexOf(existingKey);
            mappingLocal.splice(index, 1, mapkey);
        } else {
            mappingLocal.push(mapkey);
        }
        setMapping(mappingLocal);
        setMappingObjectFromArray(mappingLocal)
        props.setMappingKeys(mappingLocal);
    };


    return (
        <div className="col mt-2" style={{ padding: props.isAPISelected ? '0px 15px 0px 5px' : '0px 15px 0px 15px' }}>

            <Form
                render={(formRenderProps) => (
                    <FormElement>

                        <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", minHeight: props.isAPISelected ? "80vh" : "65.4vh" }}>

                            <div className="row mt-1">
                                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                    <label className="mt-3 ml-3"><strong>{lang.template_details_label}</strong></label>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 text-right" style={{ padding: "10px 25px 0px 0px" }}>
                                    <button title={"Reset Mapping Selection"} type="button" onClick={() => { setMapping([]); }} className="btn-circle btn-primary " >
                                        <i className="fa-solid fa-rotate"></i>
                                    </button>
                                </div>
                            </div>
                            <hr></hr>

                            <div className="row" style={{ margin: "5px" }}>
                                <div className='col-12'>
                                    <div className="row">
                                     {props.moduleType && props.moduleType?.SID == INGESTION_MODULETYPES.PlanningGrid ? <div className="col"> <p className=" text-muted" style={{ fontSize: "13px" }}>{`* No Column Mapping Required ${props.moduleType.Description} is Fixed Template`} </p></div> :
                                        props.formFields && props.formFields.map((formField, i) => {
                                            return (
                                                    <div key={i} className={props.isAPISelected ? "col-md-6 col-lg-6 col-sm-12 col-xs-12" : "col-md-6 col-lg-4 col-sm-12 col-xs-12"}>
                                                        <div className="form-group">
                                                            <label htmlFor="">{`${formField.label} ${mappingObject[formField.name] && mappingObject[formField.name]?.Format?.length > 0 ? `(${mappingObject[formField.name].Format})` : '' }`}</label>
                                                            {(formField.type == "date" || formField.type == "time") && <InfoRoundButton message={'Format'} icon={'info'} onClick={() => {setFormatPopup(true); setSelectedFormField(formField);}}></InfoRoundButton>}
                                                            <DropDownList
                                                                style={{ backgroundColor: "white" }}
                                                                data={props.keysList}
                                                                name={formField.name}
                                                                textField="Description"
                                                                value={mappingObject[formField.name]}
                                                                onChange={handleSaveTemplateChange}
                                                            />
                                                        </div>
                                                    </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </FormElement>
                )}
            />

            {formatPopup &&
                <Dialog title={'Format'} width="300px" onClose={() => setFormatPopup(false)}>
                    <div>
                        {selectedFormField.type == "date" && <div className="form-group">
                            <label htmlFor="">{`${selectedFormField.name}'s Format`}</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={DATE_TIME_FORMATS.filter(x => x.Type == 'date')}
                                name={selectedFormField.name + "_Format"}
                                textField="Format"
                                value={mappingObject[selectedFormField.name]}
                                onChange={handleSaveTemplateChange}
                            />
                        </div>}
                        {selectedFormField.type == "time" && <div className="form-group">
                            <label htmlFor="">{`${selectedFormField.name}'s Format`}</label>
                            <DropDownList
                                style={{
                                    backgroundColor: "white"
                                }}
                                data={DATE_TIME_FORMATS.filter(x => x.Type == 'time')}
                                name={selectedFormField.name + "_Format"}
                                textField="Format"
                                value={mappingObject[selectedFormField.name]}
                                onChange={handleSaveTemplateChange}
                            />
                        </div>}
                    </div>

                </Dialog>
            }

        </div>)
};

export default IngestionTemplateBottomSection;
