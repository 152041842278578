//https://www.telerik.com/kendo-react-ui/components/scheduler/customization/form/editor/
/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Form,
  Field,
  FormElement,
} from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker, DateTimePicker } from "@progress/kendo-react-dateinputs";
import { utility } from "../../framework/utility/utilityProvider";
import * as API from '../../framework/API/api';
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { DropdownWithDefaultValue } from "../../framework/forms/helpercomponents/dropdown/DropdownWithDefaultValue";
import { ENTITYNAME, FrameEquivalentOfMilliSecond, MEDIACATEGORIES, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { PlanningHelper } from "./helper/PlanningHelper";

const PlanningEditForm = (props) => {

  // console.log(props);
  const [channels, setChannels] = useState([]);
  const [segments, setSegments] = useState([]);
  const [isEpisode, setIsEpisode] = useState(props.dataItem.mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Episode);
  const [isOpenFromSchedule, setIsOpenFromSchedule] = useState(props.isOpenFromSchedule ?? false); // default false
  const lang = useTranslation();
  //const [openForm,setFormOpen] = useState();
  const cancelCopySchedule = () => {
    //setFormOpen(false);
  }
  const dataEdit = {
    ...props.dataItem,
    ActualDuration: utility.convertMilisecondsToStringWithFrames(props.dataItem?.ActualDuration ?? 0),
    NominalDuration: utility.convertMilisecondsToStringWithFrames(props.dataItem?.NominalDuration ?? 0),
    customDuration: props.dataItem?.customDuration ? utility.convertMilisecondsToStringWithFrames(props.dataItem?.customDuration ?? 0) : null,
    SeasonNumber: props.dataItem.mediaEpisode?.SeasonNumber ?? 1,
    EpisodeNumber: props.dataItem.mediaEpisode?.EpisodeNumber ?? 1,
    SlotDateLocal: new Date(utility.datetimeFormat(props.dataItem.SlotDateTime)),
    SlotTimeLocal: utility.convertMilisecondsToStringWithFrames(props.dataItem.SlotDateTime),
    selectedSegment: props.dataItem.Segment_id
  }
  //to store masters getting from datalookup

  useEffect(
    () => {
      loadMaster();
    }, [])


  async function loadMaster() {

    var channels = await API.getDataLookup(ENTITYNAME.Channel);
    var segments = await API.getData(ENTITYNAME.MediaEpisodeSegment, {
      query: ['MediaEpisodeSID', '=', props.dataItem.mediaEpisode.SID],
    });

    setChannels(channels.data);

    let validSegments = PlanningHelper.getValidSegmentsByChannel(segments.data,props.dataItem.Channel);
    setSegments(validSegments);

  }

  //handle submit for data fetch of this custom form
  const handleSubmit = async (event) => {

    console.log(event);
    const [hours, minutes, seconds, frames] = event.SlotTimeLocal.replaceAll('_', '0').split(':');
    var slotDate = new Date(event.SlotDateLocal).setHours(parseInt(hours), parseInt(minutes), parseInt(seconds), parseInt(frames) * FrameEquivalentOfMilliSecond);

    var newSlotDateTime = utility.getLocalDateTimeToUtcMiliseconds(new Date(slotDate));

    let updatedData = {
      SID: event.SID,
      _id: event._id,
      ScheduleDate: props?.module == MODULE.PLANNING ? newSlotDateTime : utility.getLocalDateTimeToUtcMiliseconds(event.SlotDateLocal),
      SlotDateTime: newSlotDateTime,
      EndSlotDateTime: newSlotDateTime + event.Duration,
      Segment_id: event.selectedSegment?._id ?? props.dataItem.Segment_id,
      customDuration:props.dataItem?.customDuration,
    }

    console.log(props?.module);

    //to edit in ProgramScheduleTable
    var res = await API.savePlanning(updatedData, segments);
    //var res = await API.saveData(ENTITYNAME.PLANNER, updatedData);
    if (res.success) {

      //default duation  from the main item
      updatedData.NominalDuration = (typeof event.NominalDuration == 'string' ? utility.convertStringWithFramesToMilliseconds(event.NominalDuration) : event.NominalDuration);
      updatedData.ActualDuration = (typeof event.ActualDuration == 'string' ? utility.convertStringWithFramesToMilliseconds(event.ActualDuration) : event.ActualDuration);

      //if selected segment changed.
      if (updatedData.Segment_id) {
        let segment = segments.find((seg) => seg._id == updatedData.Segment_id);
        if (segment) {
          updatedData.ActualDuration = segment.ActualDuration;
        }

      }

      props.onUpdateFromPlanningEditForm(updatedData);
      props.onClose();

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  };

  return (
    <Dialog title={dataEdit.Title} onClose={props.onClose} autoFocus={true} width={1000} height={550}>
      <Form
        onSubmit={handleSubmit}
        initialValues={dataEdit}
        render={(formRenderProps) => (
          <FormElement>
            <div className="row">
              <div className="col-10">
                <fieldset>
                  <div className="mb-2">
                    <Field
                      name={"mediaEpisode.AssetId"}
                      component={Input}
                      label={lang.house_number_label}
                      disabled
                    />
                  </div>
                  <div className="row mb-2">
                    <div className="col-6">
                      <Field name={"Title"} component={Input} label={lang.title_label} disabled />
                    </div>

                    {isEpisode && <div className="col-3">
                      <Field
                        name={"SeasonNumber"}
                        component={NumericTextBox}
                        label={lang.season_no_label}
                        disabled

                      />
                    </div>}
                    {isEpisode && <div className="col-3">
                      <Field
                        name={"EpisodeNumber"}
                        component={NumericTextBox}
                        label={lang.episode_number_label}
                        disabled
                      />
                    </div>}

                  </div>
                  <div className="mb-2">
                    <Field
                      name={"mediaEpisode.EpisodeTitle"}
                      component={Input}
                      label={lang.episode_title_label}
                      disabled
                    />
                  </div>
                  <div className="row mb-2">
                    <div className="col-8">
                      {/* to show data by using data-lookup */}
                      <Field
                        name={"mediaEpisode.MediaCategory.Description"}
                        component={Input}
                        label={lang.category_type_label}

                        disabled
                      />
                    </div>

                  </div>
                  <div className="row mb-2">
                    <div className="col-3">
                      <Field
                        name={"ExhibitionNumber"}
                        component={NumericTextBox}
                        label={lang.exhibition_label}
                        disabled
                      />
                    </div>
                    <div className="col-3">
                      <Field
                        name={"RepeatNumber"}
                        component={NumericTextBox}
                        label={lang.repeat_label}
                        disabled
                      />
                    </div>
                    <div className="col-6">
                      <Field
                        name={"ChannelSID"}
                        component={DropdownWithDefaultValue}
                        data={channels}
                        datakey="SID"

                        textField={'FullChannelName'}
                        label={lang.channel_label}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-6">
                      <Field
                        name={"SlotDateLocal"}
                        component={DatePicker}
                        placeholder={""}
                        label={"Slot Date"}
                      />
                    </div>
                    <div className="col-6">
                      <Field
                        name={"SlotTimeLocal"}
                        component={TimePickerWithFormat}
                        label={lang.slot_time_label}
                      />
                    </div>
                    {/* <div className="col-6">
                      <Field
                        name={"SlotTime"}
                        component={TimePicker}
                        label={"Actual Start"}
                      />
                    </div> */}
                  </div>
                  <div className="row mb-2">


                  </div>
                  <div className="row mb-2">

                    <div className="col-3">
                      <Field
                        name={"ActualDuration"}
                        component={TimePickerWithFormat}
                        label={lang.actual_duration_label}
                        disabled={isOpenFromSchedule}
                      />
                    </div>

                    <div className="col-3">
                      <Field
                        name={"NominalDuration"}
                        component={TimePickerWithFormat}
                        label={lang.nominal_duration_label}
                        disabled={isOpenFromSchedule}
                      />
                    </div>

                    {dataEdit?.customDuration && <div className="col-3">
                      <Field
                        name={"customDuration"}
                        component={TimePickerWithFormat}
                        label={'Custom Duration'}
                        disabled={true}
                      />
                    </div>
                    }

                    <div className="col-3 text-right">
                      <Field
                        name={"selectedSegment"}
                        component={DropdownWithDefaultValue}
                        data={segments}
                        textField={"SegmentType.Title"}
                        label={lang.segments_label}
                        datakey="_id"
                        disabled={isOpenFromSchedule}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className=" col-2">
                <button
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary col-12"
                >
                  {lang.save_button_text}
                </button>
                <button
                  style={{ marginTop: "5px" }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base col-12"
                  onClick={() => {
                    props.onClose();
                    //props.closeEditForm(props.dataItem);
                  }}
                >
                  {lang.cancel_button_text}
                </button>
                {/* <button
                 style={{ marginTop: "5px" }}
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary col-12"
                  onClick={() => setFormOpen(true)}
                >
                  {lang.recursive_button_text}
                </button> */}
                {/* <button
                  style={{ marginTop: "30px" }}
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base col-12"
                  disabled={!formRenderProps.allowSubmit}
                >
                  History
                </button>
                <button
                  style={{ marginTop: "5px" }}
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base col-12"
                  //   onClick={props.cancelEdit}
                >
                  Edit Media
                </button>
                <button
                  style={{ marginTop: "5px" }}
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base col-12"
                  disabled={!formRenderProps.allowSubmit}
                >
                  Black Out
                </button>
                <button
                  style={{ marginTop: "5px" }}
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base col-12"
                  //   onClick={props.cancelEdit}
                >
                  Recursive
                </button> */}
              </div>
            </div>
          </FormElement>
        )}
      />
      {/* {openForm && 
       <Recursive closeForm = {cancelCopySchedule}/>} */}
    </Dialog>
  );
};

export default PlanningEditForm;
