/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOGEVENT, MODULE, STORAGE_DESTINATION } from "../../framework/constant/constant";
import { toast } from "react-toastify";
import { utility } from "../../framework/utility/utilityProvider";

const InstanceEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        BaseUrl: props.item.BaseUrl ?? "",
        DbName: props.item.DbName ?? "",
        Description: props.item.Description ?? "",
        DigiSignBaseUrl: props.item.DigiSignBaseUrl ?? "",
        Name: props.item.Name ?? "",
        SocketBaseUrl: props.item.SocketBaseUrl ?? "",
        File: props.item.File ?? "",
        Quality: props.item.Quality ?? "",
        cloud_name: props.item?.StorageCredentials?.Cloudinary?.cloud_name ?? "",
        api_key: props.item?.StorageCredentials?.Cloudinary?.api_key ?? "",
        api_secret: props.item?.StorageCredentials?.Cloudinary?.api_secret ?? "",
        accessKeyId: props.item?.StorageCredentials?.S3?.accessKeyId ?? "",
        secretAccessKey: props.item?.StorageCredentials?.S3?.secretAccessKey ?? "",
        region: props.item?.StorageCredentials?.S3?.region ?? "",
        bucketName: props.item?.StorageCredentials?.S3?.bucketName ?? "",
        StorageDestination: props.item.StorageDestination ? { _id: props.item.StorageDestination, Description: utility.getKeyByValue(STORAGE_DESTINATION, props.item.StorageDestination) } : { _id: STORAGE_DESTINATION.Cloudinary, Description: utility.getKeyByValue(STORAGE_DESTINATION, STORAGE_DESTINATION.Cloudinary) },
        CdnUrl: props.item?.CdnUrl,
        KeepOrg: props.item.KeepOrg ?? false,
        Archive: props.item.Archive ?? false

    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [storageDestinations, setStorageDestinations] = useState([]);
    const [imageSelected, setImageSelected] = useState(false);

    useEffect(() => {
        loadCombo()
    }, [])

    const loadCombo = async () => {
        let storageDestinationRes = Object.keys(STORAGE_DESTINATION).map((x) => {
            return {
                _id: STORAGE_DESTINATION[x],
                Description: x
            }
        })
        setStorageDestinations(storageDestinationRes)
    }

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive })
        } else if (e.target.name == "KeepOrg") {
            setDataItem({ ...dataItem, KeepOrg: !dataItem.KeepOrg })
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value })
        }
    }

    const isValid = () => {
        if (dataItem.BaseUrl == undefined || dataItem.BaseUrl == "") {
            toast.error(lang.please_enter_base_url_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.DbName == undefined || dataItem.DbName == "") {
            toast.error(lang.please_enter_bdname_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.Description == undefined || dataItem.Description == "") {
            toast.error(lang.please_enter_description_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.DigiSignBaseUrl == undefined || dataItem.DigiSignBaseUrl == "") {
            toast.error(`${lang.please_enter_digisign_base_url_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.SocketBaseUrl == undefined || dataItem.SocketBaseUrl == "") {
            toast.error(lang.please_enter_socket_base_url_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.Quality > 100) {
            toast.error(lang.quality_should_be_less_than_100_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false
        }
        if (dataItem.CdnUrl == undefined || dataItem.CdnUrl == "") {
            toast.error(lang.please_enter_cdn_url_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false

        }
        return true;
    }

    const handleSubmit = async () => {
        if (!isValid()) return;
        let finalData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            BaseUrl: dataItem.BaseUrl,
            DbName: dataItem.DbName,
            Description: dataItem.Description,
            DigiSignBaseUrl: dataItem.DigiSignBaseUrl,
            Name: dataItem.Name,
            SocketBaseUrl: dataItem.SocketBaseUrl,
            File: dataItem.File,
            Quality: dataItem.Quality,
            StorageDestination: dataItem.StorageDestination._id,
            StorageCredentials: dataItem.StorageDestination._id == STORAGE_DESTINATION.Cloudinary ? {
                Cloudinary: {
                    cloud_name: dataItem.cloud_name,
                    api_key: dataItem.api_key,
                    api_secret: dataItem.api_secret,
                }
            } : {
                S3: {
                    accessKeyId: dataItem.accessKeyId,
                    secretAccessKey: dataItem.secretAccessKey,
                    region: dataItem.region,
                    bucketName: dataItem.bucketName
                }
            },
            CdnUrl: dataItem.CdnUrl,
            KeepOrg: dataItem.KeepOrg ?? false,
            Archive: dataItem.Archive ?? false

        }

        console.log(finalData);

        let res = await API.saveData(ENTITYNAME.Instance, finalData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE_INSTANCE : LOGEVENT.INSERT_INSTANCE, module: MODULE.INSTANCE, data: res.data, message: res.message };
            API.SaveLogs(logData);
            utility.deleteLocalStorageItem(ENTITYNAME.Instance);
            props.cancelEdit();
            props.refresh();
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }
    const borderStyle = {
        border: "1px solid #ced4da",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        height: "31vh"
    }
    return (
        <>
            <Dialog
                title={props?.item?.Description ? props?.item?.Description : lang.create_instance_dialog_header}
                onClose={props.cancelEdit}
                width={"50%"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row mt-2" >
                            <div className="col-4">
                                <label>{lang.base_url_label} *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="BaseUrl"
                                    onChange={onChange}
                                    value={dataItem.BaseUrl}
                                />
                            </div>
                            <div className="col-4">
                                <label>{lang.db_name_label} *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="DbName"
                                    onChange={onChange}
                                    value={dataItem.DbName}
                                />
                            </div>
                            <div className="col-4">
                                <label>{lang.description_label} *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="Description"
                                    onChange={onChange}
                                    value={dataItem.Description}
                                />
                            </div>
                        </div>
                        <div className="row mt-2 mb-2" >
                            <div className="col-4">
                                <label>{lang.digisign_base_url_label} *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="DigiSignBaseUrl"
                                    onChange={onChange}
                                    value={dataItem.DigiSignBaseUrl}
                                />
                            </div>
                            <div className="col-4">
                                <label>{lang.name_label} *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="Name"
                                    onChange={onChange}
                                    value={dataItem.Name}
                                />
                            </div>
                            <div className="col-4">
                                <label>{lang.socket_base_url_label} *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="SocketBaseUrl"
                                    onChange={onChange}
                                    value={dataItem.SocketBaseUrl}
                                />
                            </div>
                        </div>
                        <span className="font-weight-bold  fs-1">{lang.file_label}</span>
                        <div className="border form-group p-2 with-3d-effect" style={borderStyle}>
                            <div className="row">
                                <div className="col-4">
                                    <label>{lang.storage_destination_label} </label>
                                    <DropDownList
                                        style={{ backgroundColor: "white" }}
                                        data={storageDestinations}
                                        name="StorageDestination"
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.StorageDestination}
                                        onChange={onChange} />
                                </div>
                                <div className="col-4">
                                    <label>{lang.quality_label} *</label>
                                    <input
                                        type="number"
                                        name="Quality"
                                        className="form-control form-control-sm"
                                        value={dataItem.Quality}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="col-4 mt-3">
                                    <input
                                        type="checkbox"
                                        name="KeepOrg"
                                        value={dataItem.KeepOrg}
                                        checked={dataItem.KeepOrg}
                                        onChange={onChange}
                                    />
                                    <label className="mr-1 pl-1">
                                        {lang.keep_org_label}
                                    </label>
                                </div>
                            </div>
                            {dataItem.StorageDestination._id == STORAGE_DESTINATION.Cloudinary &&
                                <div className="row mt-2" >
                                    <div className="col-4">
                                        <label>{lang.cloud_name_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="cloud_name"
                                            onChange={onChange}
                                            value={dataItem.cloud_name}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <label>{lang.api_key_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="api_key"
                                            onChange={onChange}
                                            value={dataItem.api_key}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <label>{lang.api_secret_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="api_secret"
                                            onChange={onChange}
                                            value={dataItem.api_secret}
                                        />
                                    </div>
                                </div>}
                            {dataItem.StorageDestination._id == STORAGE_DESTINATION.S3 &&
                                <div className="row mt-2">
                                    <div className="col-3">
                                        <label>{lang.access_key_id_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="accessKeyId"
                                            onChange={onChange}
                                            value={dataItem.accessKeyId}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <label>{lang.secret_access_key_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="secretAccessKey"
                                            onChange={onChange}
                                            value={dataItem.secretAccessKey}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <label>{lang.region_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="region"
                                            onChange={onChange}
                                            value={dataItem.region}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <label>{lang.bucket_name_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="bucketName"
                                            onChange={onChange}
                                            value={dataItem.bucketName}
                                        />
                                    </div>
                                </div>}

                            <div className="row mt-3">
                                <div className="col-6">
                                    <label>{lang.cdn_url_label} *</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name="CdnUrl"
                                        onChange={onChange}
                                        value={dataItem.CdnUrl}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col">
                                <input
                                    type="checkbox"
                                    name="Archive"
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive}
                                    onChange={onChange}
                                />
                                <label className="mr-1 pl-1">
                                    {lang.archive}
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </Dialog>
        </>
    )
}
export default InstanceEditForm;