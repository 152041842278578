import React, {
  useState,
  useEffect,
  useRef,
  memo,
  forwardRef,
  useImperativeHandle,
  startTransition,
} from "react";
import {
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { toast } from "react-toastify";
import {
  AUTOMATION,
  COLUMNSTYPE,
  ENTITYNAME,
  FILEURL,
  FORMATVARIATIONTYPE,
  FrameEquivalentOfMilliSecond,
  LOCALSTORAGE_KEY,
  LOGEVENT,
  MEDIACATEGORIES,
  MEDIACATEGORYTYPES,
  MEDIATYPE,
  MEDIATYPEENUM,
  MEDIA_TYPE,
  MILLISECONDSINADAY,
  MODULE,
  OFFSET,
  PLATFORMTYPE,
  REPLACEMENTMODE,
  SCHEDULECONSTRAINTS,
  SCHEDULETYPE,
  SCHEDULE_CHILDREN_TYPE,
  SOCKET_ACTION,
  SOCKET_EVENTS,
  TX_MODE,
  TX_STATUS
} from "../../../framework/constant/constant";
import { utility } from "../../../framework/utility/utilityProvider";
import { SchedulingHelper } from "../helper/SchedulingHelper";
import * as API from "../../../framework/API/api";
import { orderBy } from "@progress/kendo-data-query";
import { TimeCell, TimeHyperLinkCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { DateOnlyCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ScheduleTypeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ScheduleTypeCell";
import SecondaryEventIcon from "@mui/icons-material/Image";
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import MicIcon from '@mui/icons-material/Mic';
import { ContextMenu } from "primereact/contextmenu";
import { useTranslation } from "../../../locale/useTranslation";
import PlanningEditForm from "../../Planning/PlanningEditForm";
import { ConfirmAlert, ConfirmAlertOneTime } from "../../../ConfirmAlert";
import moment from "moment";
import CollectionSelection from "../../../framework/forms/CollectionSelection";
import GlobalReplacement from "../GlobalReplacement";
import { SchedulePrototypeOverview } from "../SchedulePrototypeOverview";
import useSocket from "../../../framework/socket/useSocket";
import CheckLists from "../../CheckList/CheckLists";
import ContractCollectionSelection from "../../Contract/ContractCollectionSelection";
import { PlanningHelper } from "../../Planning/helper/PlanningHelper";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoPlotPromo from "../AutoPlotPromo";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TimePickerWithFormat } from "../../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { VirtualSegmentationPopup } from "../VirtualSegmentationPopup";
import MediaFastSegmentationPopup from "../../Segment/MediaFastSegmentationPopup";
import { SalesHelper } from "../../Sales/SalesHelper";
import GoToSchedulePopup from "./GoToSchedulePopup";
import { TreeList, TreeListDraggableRow } from "@progress/kendo-react-treelist";
import ArchiveIconCell from "../../../components/ArchiveIconCell";
import MyInfoCell from "../../../framework/forms/helpercomponents/CustomGridCells/MyInfoCell";
import contextMenuItem from "../../../components/contextMenuItem";
import ScheduleDraftEditForm from "./ScheduleDraftEditForm";
import { SegmentCountCell } from "../../../framework/forms/helpercomponents/CustomGridCells/SegmentCountCell";
import { EditPageHeader } from "../../../components/EditPageHeader";
import { TXModeCell, TXStatusCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TXModeCell";

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const EXPAND_FIELD = "expanded";
const SUB_ITEM_FIELD = "children";
const SELECTED_HEADER = "isHeaderSelected";
const editField = "inEdit";
const IS_BREAK = "IsBreak";
const IS_BREAK_CHILDREN = "IsBreakChildren";
const UNDERRUN = "UR";
const OVERRUN = "OR";
const ROW_HEIGHT = 40;

const SchedulingMainTree = (props, ref) => {

  // const lastScrollTop = useRef(0);
  const gridRef = useRef(null);
  const treeListRef = useRef(null);

  const selectedDateRef = useRef(props.date.getTime());
  const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
  const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
  const offsetTime = offSetHours * 3600000;
  const { selectedChannel, lockStatus, isBreakOnly, showBreakOnly, handleLiveEventPopup, showGoToSchedulePopup, setShowGoToSchedulePopup, setIsDraftMode, isDraftMode } = props;
  const pageSize = 40;

  const isDraftModeRef = useRef(isDraftMode);
  const isBreakOnlyRef = useRef(isBreakOnly);
  //user
  let user = utility.getValue(LOCALSTORAGE_KEY.userData);
  const applicationConfigurationValue = utility.getValue(LOCALSTORAGE_KEY.applicationConfiguration);
  const enableScheduleInsertLiveEvent = applicationConfigurationValue ? (applicationConfigurationValue.find(x => x.Key == 'EnableScheduleInsertLiveEvent')?.Value ?? false) : false;
  const appconfig = utility.getValue(LOCALSTORAGE_KEY.applicationConfiguration);

  var savingSchedule = false;

  // Schedule Related Properties
  const [skip, setSkip] = useState(0);
  const [schedulerData, setSchedulerData] = useState([]);
  const schedulerDataRef = useRef(schedulerData);
  const [isSchduleLoading, _setIsSchduleLoading] = useState(false);
  const setIsSchduleLoading = (data) => {
    _setIsSchduleLoading(data)
    props.onIsSchduleLoading(data)
  }
  const schedulerDataRefSetter = (data) => {
    schedulerDataRef.current = data;
    setSchedulerData(data);
    props.setSchedulingData(data);
    let statusCount = SchedulingHelper.getCommercialOverRunUnderRunCounts(data);
    props.setStatusInfo({ ...statusCount, selectedChannel: selectedChannel, selectedScheduleDate: props.selectedScheduleDate });
  };

  // Selected Item Properties
  const [selectedState, setSelectedState] = useState([]); //children multiple selection grid
  const selectedStateRef = useRef(selectedState);
  const selectedStateRefSetter = (data) => {
    selectedStateRef.current = data;
    setSelectedState(data);
    //seeting header data
    setSelectedHeadersDataItems(data.filter(x => x.Type == SCHEDULETYPE.Header));
    props.setSelectedState(data);
  };

  const copyPasteDataItemRef = useRef([]);
  const copyPasteDataItemSetter = (data) => {
    copyPasteDataItemRef.current = data;
    props.setCopyPasteDataItem(data.length);
  };

  const payloadref = useRef();
  const [categories, setCategories] = useState([]);

  const selectedHeadersDataItemsRef = useRef([]);
  const setSelectedHeadersDataItems = (data) => {
    selectedHeadersDataItemsRef.current = data;
  }
  const selectedHeader = useRef({});
  const setSelectedHeader = (data) => {
    selectedHeader.current = data;
    props.setSelectedHeader(data);
  };

  const [dataColumns, setDataColumns] = useState([]);
  const setStatusMessage = (message) => {
    props.setStatusMessage(message);
  };

  const [showPlanningEditForm, setShowPlanningEditForm] = useState(false);
  const [showFilterGrid, _setShowFilterGrid] = useState(false);
  const showFilterGridRef = useRef(showFilterGrid);
  const setShowFilterGrid = (data) => {
    _setShowFilterGrid(data);
    showFilterGridRef.current = data;
  }

  const txStatusRef = useRef();
  const [txStatus, _setTxStatus] = useState({});
  const setTxStatus = (data) => {
    _setTxStatus(data);
    txStatusRef.current = data
  }

  const commentRef = useRef();
  const [comment, _setComment] = useState({});
  const setComment = (data) => {
    _setComment(data);
    commentRef.current = data
  }


  const hcm = useRef(); //FOR SCHEDULER RIGHT CLICK ON HEADER: CONTEXT MENU
  const cm = useRef(); //FOR SCHEDULER RIGHT CLICK ON CHILDREN ELEMENT : CONTEXT MENU
  const bc = useRef(); //FOR SCHEDULER break children right Click
  const fg = useRef(); //FOR SCHEDULER filter grid right Click
  const lockStatusRef = useRef(lockStatus);
  const [showInsertPlaceHolderFilter, setShowInsertPlaceHolderFilter] =
    useState(false);
  const [showProgramReplacementDialog, setShowProgramReplacementDialog] =
    useState(false);
  const [showGlobalReplacementDialog, setShowGlobalReplacementDialog] =
    useState(false);

  const [showFilterGridData, _setShowFilterGridData] = useState([]);
  const showFilterGridDataRef = useRef(showFilterGridData);
  const filterSearchWordRef = useRef('');
  const setShowFilterGridData = (data) => {

    startTransition(() =>
      _setShowFilterGridData(data)
    );

    showFilterGridDataRef.current = data;
  }
  const [showCheckList, setShowCheckList] = useState(false);
  const lang = useTranslation();

  const schedulerChildrenFilterRef = useRef();
  const [styleSheetSelectionOpen, setStyleSheetSelectionOpen] = useState(false);

  const [showFormatSelection, setShowFormatSelection] = useState(false);

  const [showSecondaryEventOverviewDialog, setShowSecondaryEventOverviewDialog] = useState(false);
  const [showSaveScheduleDraftPopup, setShowSaveScheduleDraftPopup] = useState(false);

  const [showProgressBar, setShowProgressBar] = useState({
    okButton: false,
    progressBarPopup: false,
  });
  const [progressData, setProgressData] = React.useState({
    message: '',
    progress: 0,
  });

  const [txStatusData, setTxStatusData] = useState([]);

  // const [interstitialGroupSelectionOpen, setInterstitialGroupSelectionOpen] = useState(false);
  const [fastSegmentationPopupOpen, setFastSegmentationPopupOpen] = useState(false);
  const [updateTxStatusPopupOpen, setUpdateTxStatusPopupOpen] = useState(false);
  const [updateCommentPopupOpen, setUpdateCommentPopupOpen] = useState(false);

  const [editDurationPopupOpen, setEditDurationPopupOpen] = useState(false);

  const [customSlotDuration, _setCustomSlotDuration] = useState("00:00:00:00");
  const customSlotDurationRef = useRef("00:00:00:00");
  const setCustomSlotDuration = (data) => {
    _setCustomSlotDuration(data);
    customSlotDurationRef.current = data
  }

  const [showVirtualSegmentation, setShowVirtualSegmentation] = useState(false);
  const [virtualSegmentationData, setVirtualSegmentationData] = useState({
    duration: "00:00:00:00",
    noOfSeg: 2,
    diffDur: "00:00:00:00",
    totalDur: "00:00:00:00",
    gridData: []
  });

  const outSideFocusRef = useRef(props.outSideFocus);
  const scheduleScrollRef = useRef();
  const scrollPosition = useRef(0);

  const isCut = useRef(false);
  const setIsCut = (data) => {
    isCut.current = data;
  }

  // for program replacement
  const programToReplaceRef = useRef({});
  const setProgramToReplace = (data) => {
    programToReplaceRef.current = data;
  };
  const [showMediaDealRightsPopup, setShowMediaDealRightsPopup] = useState(false);

  //
  const selectedPromoRef = useRef([]);
  const setSelectedPromo = (data) => {
    selectedPromoRef.current = data
  }
  const [showAutoPlotPromo, setShowAutoPlotPromo] = useState(false);
  const [mediaCategory, setMediaCategory] = useState([]);
  const [mediaCategoryType, setMediaCategoryType] = useState([]);
  const [filterMediaCategoryType, setFilterMediaCategoryType] = useState([]);
  const [treeListHeight, setTreeListHeight] = useState('100%');

  let blankDataItem = {
    AssetId: 'BREAK',
    Title: "",
    Content: {},
    Description: "",
    MediaCategory: {},
    MediaCategoryType: {},
    Duration: "00:00:00:00",
    IsBreak: true
  };

  const [breakdataItemForPopup, setBreakdataItemForPopup] = useState(blankDataItem);
  //socket
  const socket = useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {
    console.log(socketData);
    //  console.log(socketData);
    if (socketData.module != MODULE.SCHEDULING) return;

    // if same user working on same date but different channel, it was reflecting in both channels.
    if (selectedChannel.SID != socketData.data.selectedChannelSID || socketData.data.selectedScheduleDate != props.selectedScheduleDate) return; //no need to filter active user from module 

    // let remoteUser = socketData.user;
    // setStatusMessage(`${lang.last_updated_from_label_msg}` + remoteUser?.name)

    switch (socketData.action) {
      case SOCKET_ACTION.SCHEDULE_REFRESH:
        loaddata(true);
        break;
      case SOCKET_ACTION.SCHEDULE_ADDITEM:

        console.log("coming in socket schedule add");
        // addScheduleItemsMain(socketData.data.destinationDataItem, socketData.data.dataItems, false)
        let schedulerDataLocal = schedulerDataRef.current;
        let level_0_index = socketData.data.level_0_index;
        let level_1_index = socketData.data.level_1_index;

        if (socketData.data.insertType == SCHEDULE_CHILDREN_TYPE.break_children) {
          schedulerDataLocal[level_0_index].children[level_1_index].children = socketData.data.children;
          //ripple
          schedulerDataLocal[level_0_index].children[level_1_index].children = getRippleBreakChildrens(schedulerDataLocal[level_0_index].children[level_1_index], schedulerDataLocal[level_0_index].children[level_1_index].children);
        }
        else {
          schedulerDataLocal[level_0_index].children = socketData.data.children
          //ripple
          schedulerDataLocal[level_0_index].children = getRippleChildrens(schedulerDataLocal[level_0_index], schedulerDataLocal[level_0_index].children, schedulerDataLocal);
        }

        // setting updated values on UI binded property and reference.
        schedulerDataRefSetter(schedulerDataLocal);
        break;
      case SOCKET_ACTION.SCHEDULE_DELETEITEM:
        deleteScheduleItemsExecute(socketData.data.dataItems);
        break;
      case SOCKET_ACTION.SCHEDULE_BOOKING_REFRESH:
        props.loadBookingCommercialdata();
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    if (isSchduleLoading == false) {
      treeListRef?.current?.element?.addEventListener('scroll', onTreeViewscroll);
    }

    return () => {
      treeListRef?.current?.element?.removeEventListener('scroll', onTreeViewscroll);
    }
  }, [isSchduleLoading]);




  // on screen RESOLUTION change
  useEffect(() => {
    let divHeight = document.getElementById('content-scroll')?.offsetHeight;
    setTreeListHeight(divHeight + 'px');
    // listen for resize event
    window.addEventListener('resize', () => {
      let divHeight = document.getElementById('content-scroll')?.offsetHeight;
      setTreeListHeight(divHeight + 'px');
    });

    return () => {
      window.removeEventListener('resize', () => {
        let divHeight = document.getElementById('content-scroll')?.offsetHeight;
        setTreeListHeight(divHeight + 'px');
      });
    }
  }, []);

  useEffect(() => {
    lockStatusRef.current = lockStatus;
    isDraftModeRef.current = isDraftMode;
    isBreakOnlyRef.current = isBreakOnly;
  }, [lockStatus, isDraftMode, isBreakOnly]);


  useEffect(() => {
    createPayload();
    loaddata();
    loadCombo();
    selectedDateRef.current = props.date.getTime();
  }, [props.doLoad]);

  useEffect(() => {
    loaddata(true);
  }, [props.doSilentLoad]);

  useEffect(() => {
    outSideFocusRef.current = props.outSideFocus;
  }, [props.outSideFocus]);


  useEffect(() => {
    document.addEventListener("copy", onCopy);
    document.addEventListener("paste", onPaste);
    document.addEventListener("keyup", handleDeleteByKeyboard);

    return () => {
      document.removeEventListener("copy", onCopy);
      document.removeEventListener("paste", onPaste);
      document.removeEventListener("keyup", handleDeleteByKeyboard);

    };
  }, [showVirtualSegmentation]);

  useImperativeHandle(ref, () => {
    return {
      onExpand(e) {
        onHeaderExpandChange(e)
      },

      onDeleteAllFooter() {
        ConfirmAlert(
          () => {
            deleteAllFooter();
          }, // on Click yes
          () => { }, // on click no
          'Confirm', //title
          'Do you really want to delete all footers ?' // message
        );
      },

      onDeleteAllSecondary() {
        ConfirmAlert(
          () => {
            deleteAllSecondaryEvent();
          }, // on Click yes
          () => { }, // on click no
          'Confirm', //title
          'Do you really want to delete all secondary events ?' // message
        );
      },

      onAppleStyleSheet() {
        onStyleSheetSelection()
      },

      onShowGlobalReplacement() {

        showGlobalReplacement();
      },
      onSetDoOnCheckList() {
        onCheckList()
      },

      onExportSchedule(automation, channel, doSftp, isPartial) {
        exportSchedule(automation, channel, [], doSftp, isPartial)
      },

      onExportAnnouncement() {
        exportScheduledAnnouncement()
      },

      onFillUnderOverRun(scheduleTimeInfo, isForMultipleDays = false, multipleDayPayload = undefined) {
        //setInterstitialGroupSelectionOpen(true);
        fillUnderOverRun(scheduleTimeInfo, isForMultipleDays, multipleDayPayload);
      },
      onSearch(e) {
        onSearchLocal(e)
      },
      onAutomaticBreakDistribution() {
        onAutomaticBreakDistribution();
      },
      onAutoFillBookedCommercial(bookingData) {


        ConfirmAlert(
          () => {
            autoFillBookedCommercial(bookingData, true)
          }, // on Click yes
          () => {
            autoFillBookedCommercial(bookingData, false)
          }, // on click no
          'Place spots inside breaks ?', //title
          'Press YES to fill booked spots inside available commercial breaks or click NO to fill outside commercial breaks' // message
        );

      },
      onAutoPlotPromoInSchedule(media) {

        ConfirmAlert(
          () => {
            onAutoPlotPromoInSchedule(media, true)
          }, // on Click yes
          () => {
            onAutoPlotPromoInSchedule(media, false)
          }, // on click no
          'Fill Selected Promos ?', //title
          'Press YES to fill selected promos or click NO to fill all available promos having targets configured' // message
        );
        
      },
      onReturnSlotDateTimeToPlannedNominalTime() {
        onReturnSlotDateTimeToPlannedNominalTime()
      },
      onDeleteAnnouncement(announcement) {
        //need selected item 
        handleDeleteAnnouncement(announcement);
      },
      onLiveEventInsertInSchedule(liveEventData) {
        let data = SchedulingHelper.prepareData(ENTITYNAME.MediaEpisode, selectedChannel, [liveEventData], schedulerChildrenFilterRef.current.dataItem, schedulerDataRef.current, true);
        console.log(data)
        addScheduleItems(schedulerChildrenFilterRef.current.dataItem, data)
      },
      onResetAutoFillPlaylistChildren() {

        ConfirmAlert(
          () => {
            ResetAutoFillPlaylistChildren(false);
          }, // on Click yes
          () => {

          }, // on click no
          'Reset Auto Filled Elements', //title
          'Press YES to delete all items from schedule which were added by auto fill playlist.' // message
        );
      },
      onResetSchedule() {

        ConfirmAlert(
          () => {
            ResetAutoFillPlaylistChildren(true);
          }, // on Click yes
          () => {

          }, // on click no
          'Reset Playlist', //title
          'Do you want to delete all schedule elements other than segments.' // message
        );
      },
      onDelete() {
        onDelete();
      },
      onCut() {
        onDelete(true);
      },
      onCopy() {
        onCopy();
      },
      onPaste() {
        onPaste();
      },
      deleteSelectedSecondaryEvent() {
        deleteSelectedSecondaryEvent();
      },
      showSecondaryEventOverviewDialogProp() {
        showSecondaryEventOverviewDialogProp();
      },
      showInsertBreakPopup(e) {
        showInsertBreakPopup(e);
      },
      onRevertVirtualSegmentation() {
        onRevertVirtualSegmentation();
      },
      handleLiveEventPopup() {
        handleLiveEventPopup();
      },
      handleSaveScheduleDraftPopup() {
        handleSaveScheduleDraftPopup();
      },
      loadDraftdata(draftSchedule) {
        loaddata(false, true, draftSchedule);
      },
      onMatchPreviousDayEndTime(scheduleTimeInfo) {
        onMatchPreviousDayEndTime(scheduleTimeInfo);
      },
      onManageUnderOverRunForSelectedHeader() {

        const selectedHead = selectedStateRef.current.filter(x => x.Type == SCHEDULETYPE.Header);
        if (selectedHead == undefined || selectedHead.length == 0) {
          toast.info("No header selected");
          return;
        }
        ManageUnderAndOverRuns(selectedHead.map(x => x._id), false)
      },
    };
  }, []);


  const onTreeViewscroll = (e) => {
    scrollPosition.current = e.target.scrollTop;
  }

  const onAutomaticBreakDistribution = async () => {

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }

    var parents = schedulerDataRef.current;

    //LOOP : parents
    if (parents.length > 0) {

      setShowProgressBar({ okButton: false, progressBarPopup: true });

      for (var i = 0; i < parents.length; i++) {
        const parent = parents[i];

        // updating progress bar
        setProgressData({ message: 'creating promo & commercial breaks', progress: Math.ceil((100 * (i + 1)) / parents.length) });

        const parentSegments = parent.children.filter(x => x.Type == SCHEDULETYPE.Segment);


        //SKIP : if breaks are already attached 
        const alreadyBreakAttached = parent.children.some(x => x.IsBreak == true);
        if (alreadyBreakAttached) continue;


        //FETCH : break formats
        var breakFormats = await API.getData(ENTITYNAME.BreakFormat, { query: [['SlotDuration', '=', parent.Duration], ['SegmentCount', '=', `${parentSegments.length}`]] });
        // console.log(breakFormats)


        //INSERT : breaks after every segment
        if (breakFormats.data.length == 0) continue;

        var formatDetail = breakFormats.data[0].BreakFormatDetail;

        while (formatDetail.length > 0) {
          var breakFormatDetail = formatDetail.sort((a, b) => a.BreakNumber - b.BreakNumber)[0];

          formatDetail = formatDetail.filter(
            (obj) => obj.BreakNumber != breakFormatDetail.BreakNumber
          );

          const breakCommercialData = await SchedulingHelper.getCommercialPromoBreak(
            utility.convertStringWithFramesToMilliseconds(breakFormatDetail.CommercialDuration),
            MEDIACATEGORIES.Commercial,
            MEDIACATEGORYTYPES.Commercial
          );

          const breakPromoData = await SchedulingHelper.getCommercialPromoBreak(
            utility.convertStringWithFramesToMilliseconds(breakFormatDetail.PromoDuration),
            MEDIACATEGORIES.Interstitial,
            MEDIACATEGORYTYPES.Promo
          );

          if (parentSegments && parentSegments.length > 0) {
            console.log("breakFormatDetail.BreakNumber--" + breakFormatDetail.BreakNumber);
            var destination = breakFormatDetail.BreakNumber == 0
              ? parent
              : parentSegments.find(x => x.BreakNo == breakFormatDetail.BreakNumber);

            const dataToSaveCommercial = SchedulingHelper.prepareData(
              ENTITYNAME.MediaEpisode,
              selectedChannel,
              breakCommercialData,
              destination,
              schedulerDataRef.current
            );
            console.log("adding item in schedule");
            console.log(dataToSaveCommercial);
            await addScheduleItems(destination, dataToSaveCommercial);

            if (utility.convertStringWithFramesToMilliseconds(breakFormatDetail.PromoDuration) > 0) {
              const dataToSavePromo = SchedulingHelper.prepareData(
                ENTITYNAME.MediaEpisode,
                selectedChannel,
                breakPromoData,
                destination,
                schedulerDataRef.current
              );
              await addScheduleItems(destination, dataToSavePromo);
            }

          }
        }

        let logData = { event: LOGEVENT.CREATE_AUTOMATIC_BREAKS, module: MODULE.SCHEDULING, data: parent, message: LOGEVENT.CREATE_AUTOMATIC_BREAKS };
        API.SaveLogs(logData);

      }

      setProgressData({ message: 'process completed', progress: 100 });
      setShowProgressBar({ progressBarPopup: true, okButton: true })

    }
    console.log('here in main')
  }


  const updateScheduleColorPreferenceOnFirstLoad = async () => {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    await API.getDataLookup(ENTITYNAME.UserPreference, {
      query: [["entityName", "=", "schedulepreference"], ["user_id", "=", user._id]],
      sort: { SID: 1 },
      page: null,
      perPage: null
    }, LOCALSTORAGE_KEY.schedulepreference);
  }
  const isNotValidBreakOnlyCutPasteDelete = (isCutDelete = false) => {

    let isValid = selectedStateRef.current.some((data) => {
      let { isBreak, isInsideBreak } = SchedulingHelper.getChildInfo(data, schedulerDataRef.current);
      if (isCutDelete) return isInsideBreak;
      else return isInsideBreak || isBreak;
    });
    if (!isValid) {
      return true;
    }

    return false;
  }
  //paste
  const onPaste = async () => {

    if (isBreakOnly && isNotValidBreakOnlyCutPasteDelete()) {
      toast.error(`You can only paste in break items.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    };

    if (savingSchedule) return;

    if (outSideFocusRef.current) return;

    if (copyPasteDataItemRef.current.length == 0) {
      toast.error(`${lang.first_cut_or_copy_item_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (selectedStateRef.current.length == 0) {
      toast.error(`${lang.please_select_Item_to_paste_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (selectedStateRef.current.length != 1) {
      toast.error(`${lang.Please_select_one_Item_where_need_to_paste_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!selectedStateRef.current.some(data => SchedulingHelper.canModify(data, schedulerDataRef.current))) {
      toast.error("Cannot paste because header is locked", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let draggedData = copyPasteDataItemRef.current;
    let destinationDataItem = selectedStateRef.current[0];
    let src = ENTITYNAME.ProgramSchedule; //becouase it should ne new item no source required

    const dataToSave = SchedulingHelper.prepareData(
      src,
      selectedChannel,
      draggedData,
      destinationDataItem,
      schedulerDataRef.current,
      true
    );
    addScheduleItems(destinationDataItem, dataToSave);

    if (isCut.current) {
      copyPasteDataItemSetter([]);
      setIsCut(false);
    }
  };

  //delete
  const onDelete = (isCut = false) => {

    if (isDraftModeRef.current) {
      toast.error("Schedule is in draft mode");
      return;
    }

    if (isBreakOnlyRef.current && isNotValidBreakOnlyCutPasteDelete(true)) {
      toast.error(`You can only ${isCut ? 'cut' : 'delete'} break items.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!lockStatusRef.current.canModify) {
      toast.error("Schedule Locked by " + lockStatusRef.current.lockedBy.name);
      return;
    }

    let message = isCut ? `${lang.cut_msg}` : `${lang.delete_msg}`;
    setIsCut(isCut);
    if (!lockStatusRef.current.canModifyHeader &&
      selectedStateRef.current.some((data) => data.Type == SCHEDULETYPE.UnderRun || data.Type == SCHEDULETYPE.OverRun)
    ) {
      setStatusMessage("Planning is locked.");

      toast.error("Planning is locked.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }


    if (selectedStateRef.current.some((data) => data.Type == SCHEDULETYPE.Header)) {
      setStatusMessage(lang.cannot_delete_header_label_msg);

      toast.error(lang.cannot_delete_header_please_unselect_header_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!selectedStateRef.current.some(data => SchedulingHelper.canModify(data, schedulerDataRef.current))) {
      toast.error("Your selected item contains locked schedule headers", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const canDeleteSegment = (appconfig?.length > 0 && appconfig?.find(x => x?.Key == "canDeleteSegment")?.Value) ?? false;
    if (!canDeleteSegment && selectedStateRef.current.some((data) => data.Type == SCHEDULETYPE.Segment)) {
      setStatusMessage(lang.cannot_delete_segment_label_msg);

      toast.error(lang.cannot_segment_please_unselect_segment_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (selectedStateRef.current.length == 0) {
      toast.error(`${lang.select_item_to_error_message} ${message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      selectedStateRef.current.some((data) => data.Type == SCHEDULETYPE.UnderRun || data.Type == SCHEDULETYPE.OverRun) && isCut
    ) {
      setStatusMessage(lang.cannot_cut_footer_msg_scheduling);
      toast.error(`${lang.cannot_cut_footer_msg_scheduling}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }



    if (selectedStateRef.current.some((data) => data.Type == SCHEDULETYPE.UnderRun || data.Type == SCHEDULETYPE.OverRun) && selectedStateRef.current.length > 1) {
      setStatusMessage(`${lang.cannot_delete_multiple_footer_msg_scheduling}`);
      toast.error(`${lang.cannot_delete_multiple_footer_please_select_one_footer_at_a_time_msg_scheduling}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (selectedStateRef.current.some((data) => data.Type == SCHEDULETYPE.UnderRun || data.Type == SCHEDULETYPE.OverRun)) {

      ConfirmAlert(
        () => {
          deleteFooter(selectedStateRef.current[0]);
        }, // on Click yes
        () => { }, // on click no
        `${lang.Confirmation_label}`, //title
        `${lang.are_you_sure_you_want_to_delete_footer_label}` // message
      );
      return;

    }
    //if isBreak has childrens than dont allow
    if (selectedStateRef.current.some((data) => data[IS_BREAK] && data.children.filter(x => x.Type != SCHEDULETYPE.UnderRun && x.Type != SCHEDULETYPE.OverRun).length > 0)) {
      toast.error("There are some break items present. Please delete break items")
      return;

    }
    if (isCut) {

      //save in copy
      copyPasteDataItemSetter(
        selectedStateRef.current.filter(
          (data) => data.Type != SCHEDULETYPE.Segment && !data[IS_BREAK]
        )
      );

      deleteScheduleItems(selectedStateRef.current, true);


    }
    else {

      let categoryTypes = [...new Set(selectedStateRef.current.map((x) => x.mediaEpisode?.MediaCategoryType?.Description))];
      let confirmAlertOneTime = utility.getValue(LOCALSTORAGE_KEY.schedulepreference);
      confirmAlertOneTime = confirmAlertOneTime?.data?.length > 0 ? confirmAlertOneTime?.data[0]?.value?.hideDeleteConfirmation : false;
      if (confirmAlertOneTime) {
        deleteScheduleItems(selectedStateRef.current, true);
      } else {
        ConfirmAlertOneTime(
          () => {
            deleteScheduleItems(selectedStateRef.current, true);
          }, // on Click yes
          () => { }, // on click no
          `${lang.Confirmation_label}`, //title
          `${lang.Are_you_sure_you_want_to_items_label_confirm_alert} ${message} ${selectedStateRef.current.length} items ${categoryTypes.join('/')}`, // message
        );
      }

    }

  };

  const handleShiftMultiSelect = (dataItem) => {

    if (dataItem.Type == SCHEDULETYPE.OverRun || dataItem.Type == SCHEDULETYPE.UnderRun) {
      toast.error(lang.cannot_select_footer_error_message);
      return;
    };

    if (selectedStateRef.current.length == 0) {
      selectedStateRefSetter([dataItem]);
      return;
    }

    let lastSelectedDataItem = selectedStateRef.current[0];
    var dataItems = [];

    //getting index of first selected and current seklected

    if (showFilterGrid) {

      let firstItemIndex = showFilterGridData.indexOf(lastSelectedDataItem);
      let lastSelectedItemIndex = showFilterGridData.indexOf(dataItem);
      dataItems = showFilterGridData.slice(Math.min(firstItemIndex, lastSelectedItemIndex), Math.max(firstItemIndex, lastSelectedItemIndex) + 1);

    }
    else {
      let { level_0_index: lst_selected_dataItem_lvl_0_index, level_1_index: lst_selected_dataItem_lvl_1_index, level_2_index: lst_selected_dataItem_lvl_2_index, isInsideBreak: isLastChildInsideBreak } = SchedulingHelper.getChildInfo(lastSelectedDataItem, schedulerDataRef.current)

      let { level_0_index: curr_lvl_0_index, level_1_index: curr_lvl_1_index, level_2_index: curr_lvl_2_index, isInsideBreak } = SchedulingHelper.getChildInfo(dataItem, schedulerDataRef.current)

      if (lst_selected_dataItem_lvl_0_index != curr_lvl_0_index && !showFilterGrid) {
        toast.error(lang.cannot_multi_select_items_from_different_headers_error_message);
        return;
      }

      //if inside break item
      if (isInsideBreak) {
        //if last selected item is inside break then select all items from last selected item to current item
        if (isLastChildInsideBreak) {
          dataItems = schedulerDataRef.current[lst_selected_dataItem_lvl_0_index].children[curr_lvl_1_index].children.slice(
            Math.min(lst_selected_dataItem_lvl_2_index, curr_lvl_2_index),
            Math.max(lst_selected_dataItem_lvl_2_index, curr_lvl_2_index) + 1
          );
        }
        else {
          let firstLevelDataItems = schedulerDataRef.current[lst_selected_dataItem_lvl_0_index].children.slice(
            Math.min(lst_selected_dataItem_lvl_1_index, curr_lvl_1_index),
            Math.max(lst_selected_dataItem_lvl_1_index, curr_lvl_1_index) + 1
          ).filter(data => data.Type != SCHEDULETYPE.UnderRun && data.Type != SCHEDULETYPE.OverRun && data._id != dataItem.ParentProgramSchedule_id);

          let startIndexForBreakChildren = 0;
          if (curr_lvl_1_index < lst_selected_dataItem_lvl_1_index) {
            startIndexForBreakChildren = schedulerDataRef.current[lst_selected_dataItem_lvl_0_index].children[curr_lvl_1_index].children.length - 1;
          }

          dataItems = [...firstLevelDataItems,
          ...schedulerDataRef.current[lst_selected_dataItem_lvl_0_index].children[curr_lvl_1_index].children.slice(
            Math.min(startIndexForBreakChildren, curr_lvl_2_index),
            Math.max(startIndexForBreakChildren, curr_lvl_2_index) + 1
          ).filter(data => data.Type != SCHEDULETYPE.UnderRun && data.Type != SCHEDULETYPE.OverRun)];
        }

      }
      else if (isLastChildInsideBreak) {
        let startIndexForBreakChildren = 0;
        if (curr_lvl_1_index > lst_selected_dataItem_lvl_1_index) {
          startIndexForBreakChildren = schedulerDataRef.current[lst_selected_dataItem_lvl_0_index].children[lst_selected_dataItem_lvl_1_index].children.length;
        }
        let breakLevelDataItems = schedulerDataRef.current[lst_selected_dataItem_lvl_0_index].children[lst_selected_dataItem_lvl_1_index].children.slice(
          Math.min(startIndexForBreakChildren, lst_selected_dataItem_lvl_2_index),
          Math.max(startIndexForBreakChildren, lst_selected_dataItem_lvl_2_index) + 1
        ).filter(data => data.Type != SCHEDULETYPE.UnderRun && data.Type != SCHEDULETYPE.OverRun);
        dataItems = [...breakLevelDataItems,
        ...schedulerDataRef.current[lst_selected_dataItem_lvl_0_index].children.slice(
          Math.min(lst_selected_dataItem_lvl_1_index + 1, curr_lvl_1_index),
          Math.max(lst_selected_dataItem_lvl_1_index - 1, curr_lvl_1_index) + 1
        ).filter(data => data.Type != SCHEDULETYPE.UnderRun && data.Type != SCHEDULETYPE.OverRun)];
      }
      else {
        dataItems = schedulerDataRef.current[lst_selected_dataItem_lvl_0_index].children.slice(
          Math.min(lst_selected_dataItem_lvl_1_index, curr_lvl_1_index),
          Math.max(lst_selected_dataItem_lvl_1_index, curr_lvl_1_index) + 1
        );
      }
    }


    selectedStateRefSetter(dataItems);
  }

  const handleShiftUpDown = (shiftUp) => {
    //shiftUp = true means shift up
    //shiftUp = false means shift down
    if (selectedStateRef.current.length == 0) {
      toast.error(lang.please_select_first_error_message)
      return;
    };

    var selectedItems = selectedStateRef.current;
    let lastSelectedItem = selectedStateRef.current[selectedStateRef.current.length - 1];

    //for search grid
    if (showFilterGridRef.current) {
      let lastSelectedItemIndex = showFilterGridDataRef.current.findIndex(x => x._id == lastSelectedItem._id);

      if ((lastSelectedItemIndex == 0 && shiftUp) || (lastSelectedItemIndex == showFilterGridDataRef.current.length - 1 && !shiftUp)) {
        return;
      } else {
        let item = showFilterGridDataRef.current[shiftUp ? lastSelectedItemIndex - 1 : lastSelectedItemIndex + 1];
        selectedItems = [...selectedItems, item];
      }
      selectedStateRefSetter(selectedItems);
      return;
    }

    let { level_0_index, level_1_index, level_2_index, isInsideBreak } = SchedulingHelper.getChildInfo(lastSelectedItem, schedulerDataRef.current);

    let lastIndex = schedulerDataRef.current[level_0_index].children.length - 1;

    if ((level_1_index == 0 && shiftUp) || (level_1_index == lastIndex && !shiftUp)) {
      //if the last selected item is the first item in the header, then cannot shift up
      toast.error(lang.cannot_select_items_from_different_headers_error_message);
    } else {
      //get item above last selected item
      let item = schedulerDataRef.current[level_0_index].children[shiftUp ? level_1_index - 1 : level_1_index + 1];
      if (item.Type == SCHEDULETYPE.OverRun || item.Type == SCHEDULETYPE.UnderRun) {
        toast.error(lang.cannot_select_items_from_different_headers_error_message);
        return;
      }
      selectedItems = [...selectedItems, item];
    }
    selectedStateRefSetter(selectedItems);

    return;
    //dont delete it we need reference from this code for conditions --rajat
    //for shift up
    if (shiftUp) {
      if (isInsideBreak) {
        //if inside break, then get the item above the last selected break item

        if (level_2_index == 0 && level_1_index == 0) {
          //if the item above break item is header, then cannot shift up
          toast.error("Cannot select items from different headers.");
          return;
        }
        if (level_2_index == 0) {
          //if the last selected item is the first item in the break, then get the item above break item
          let itemAbove = schedulerDataRef.current[level_0_index].children[level_1_index - 1];
          selectedItems = [...selectedItems, itemAbove];
        } else {
          //get item above last selected item
          let itemAbove = schedulerDataRef.current[level_0_index].children[level_1_index].children[level_2_index - 1];
          selectedItems = [...selectedItems, itemAbove];
        }
      }
      //if not inside break, then get the item above the last selected item
      else {
        let lastIndex = schedulerDataRef.current[level_0_index].children[level_1_index].children.length - 1;
        if (level_1_index == 0 || level_1_index == lastIndex) {
          //if the last selected item is the first item in the header, then cannot shift up
          toast.error("Cannot select items from different headers.");
        } else {
          //get item above last selected item
          let item = schedulerDataRef.current[level_0_index].children[shiftUp ? level_1_index - 1 : level_1_index + 1];
          selectedItems = [...selectedItems, item];
        }
      }


    }
    //for shift down
    else {
      //get item below the last selected item
      if (isInsideBreak) {
        //check if the last selected item is the last item in the break
        let lastIndex = schedulerDataRef.current[level_0_index].children[level_1_index].children.length - 1;

        if (level_2_index + 1 == lastIndex) {
          let itemBelow = schedulerDataRef.current[level_0_index].children[level_1_index].children[level_2_index + 1];
          //check if the item below is not a OverRun or underRun
          if (itemBelow.Type != SCHEDULETYPE.OverRun && itemBelow.Type != SCHEDULETYPE.UnderRun) {
            selectedItems = [...selectedItems, itemBelow];
          }
          //if it is OverRun or underRun, then get the item below the break
          else {
            let itemBelow = schedulerDataRef.current[level_0_index].children[level_1_index + 1];
            if (itemBelow.Type == SCHEDULETYPE.OverRun || itemBelow.Type == SCHEDULETYPE.UnderRun) {
              toast.error("Cannot select items from different headers.");
              return;
            }
            selectedItems = [...selectedItems, itemBelow];
          }
        } else {
          let itemBelow = schedulerDataRef.current[level_0_index].children[level_1_index].children[level_2_index + 1];
          selectedItems = [...selectedItems, itemBelow];
        }
      } else {
        let lastIndex = schedulerDataRef.current[level_0_index].children.length - 1;

        if (level_1_index == lastIndex) {
          toast.error("Cannot select items from different headers.");
        } else {
          let itemBelow = schedulerDataRef.current[level_0_index].children[level_1_index + 1];
          if (itemBelow.Type == SCHEDULETYPE.OverRun || itemBelow.Type == SCHEDULETYPE.UnderRun) {
            toast.error("Cannot select items from different headers.");
            return;
          }
          selectedItems = [...selectedItems, itemBelow];
        }
      }

    }
    selectedStateRefSetter(selectedItems);
  }

  const handleArrows = (isUp = true) => {
    if (outSideFocusRef.current) return;
    if (selectedStateRef.current.length == 0) return;
    let lastSelectedItem = selectedStateRef.current[selectedStateRef.current.length - 1];
    if (isUp) {
      let itemAbove = SchedulingHelper.getItemAbove(lastSelectedItem, schedulerDataRef.current);
      selectedStateRefSetter([itemAbove]);

    } else {
      let itemBelow = SchedulingHelper.getItemBelow(lastSelectedItem, schedulerDataRef.current);
      selectedStateRefSetter([itemBelow]);
    }

  }

  const handleExpandOnArrows = (expand = true) => {
    if (outSideFocusRef.current) return;
    if (selectedStateRef.current.length == 0) return;
    let lastSelectedItem = selectedStateRef.current[selectedStateRef.current.length - 1];
    if (lastSelectedItem.ParentProgramSchedule_id == null || lastSelectedItem[IS_BREAK]) {
      treeListRef.current.element.scrollTo({ left: 0, behavior: 'smooth' });
      expandChange({ dataItem: lastSelectedItem, value: expand });
    }
  }

  const handleDeleteByKeyboard = async (event) => {

    event.preventDefault();

    if (event.shiftKey && event.key == "ArrowUp") {
      //true for up
      handleShiftUpDown(true);
      return;
    }
    if (event.shiftKey && event.key == "ArrowDown") {
      //false for down
      handleShiftUpDown(false);
      return;
    }

    if (event.key == "ArrowUp") {
      handleArrows(true);
      return;
    }
    if (event.key == "ArrowDown") {
      handleArrows(false);
      return;
    }
    if (event.key == "ArrowLeft") {
      handleExpandOnArrows(false);
      return;
    }
    if (event.key == "ArrowRight") {
      handleExpandOnArrows(true);
      return;
    }

    //NEED TO CHANGE THIS 'b' to 'g'
    if (event.ctrlKey && event.key == "b") {
      setShowGoToSchedulePopup(true);
      console.log(`You pressed handleGoToSchedule`);
      return;
    }

    //remove copy items and selected items on excape key
    if (event.keyCode == 27 || event.key == "Escape") {
      copyPasteDataItemSetter([]);
    }


    if (outSideFocusRef.current) return;

    //8  code is for mac boook

    // console.log(event)

    if ((event.key == "Delete" || event.keyCode == 46 || event.code == "Delete") || (event.ctrlKey && event.code == "Backspace")) {
      if (!showVirtualSegmentation) {
        onDelete();
      }
    }

    if (event.ctrlKey && event.key == "x") {
      onDelete(true);
    }


  };

  const onCopy = () => {

    console.log(outSideFocusRef.current);

    if (outSideFocusRef.current) return;



    if (selectedStateRef.current.length == 0) {
      toast.info(`${lang.no_item_selected_to_copy_msg_scheduling}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setStatusMessage(lang.no_item_selected_to_copy_msg_scheduling);
      return;
    }



    if (selectedStateRef.current.some((data) => data.Type == SCHEDULETYPE.Header)) {

      toast.info(`${lang.cannot_copy_header_msg_scheduling}`, {
        position: toast.POSITION.TOP_RIGHT,
      });


      setStatusMessage(lang.cannot_copy_header_msg_scheduling);
      return;
    }

    if (
      selectedStateRef.current.some((data) => data.Type == SCHEDULETYPE.Segment)
    ) {

      toast.info(`${lang.cannot_copy_segment_msg_scheduling}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setStatusMessage(lang.cannot_copy_segment_msg_scheduling);
      return;
    }

    setIsCut(false);

    setStatusMessage(lang.copied_label);

    copyPasteDataItemSetter(
      selectedStateRef.current.filter(
        // (data) => data.Type != SCHEDULETYPE.Segment && !data[IS_BREAK]
        (data) => data.Type != SCHEDULETYPE.Segment // ENABLE COPY BREAK
      )
    );
  };

  const deleteAllFooter = async () => {

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }

    if (schedulerDataRef.current.length == 0) return;

    if (schedulerDataRef.current.some((data) => (data?.isLocked ?? false) == true)) {
      toast.error("Some Schedule is locked so can't delete footer");
      return;
    }

    let footerCount = 0;
    schedulerDataRef.current.map((parent) => {
      setStatusMessage(lang.deleting_footer_msg_scheduling);
      var footer = parent.children.find(
        (maindata) =>
          maindata.Type == SCHEDULETYPE.UnderRun ||
          maindata.Type == SCHEDULETYPE.OverRun
      );

      if (footer) {
        deleteFooter(footer);
        footerCount++;
      }
    });
    loaddata();
    setStatusMessage(footerCount + `${lang.footer_deleted_msg_scheduling}`);
    if (footerCount == 0) {
      toast.error(`${lang.no_footer_to_deleted_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.info(`${lang.all_footer_deleted_error_message}`, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const deleteFooter = async (selectedItem) => {
    console.log("deletefooter");

    try {
      selectedStateRefSetter([]);
      var schedulerDataRefLocal = schedulerDataRef.current;
      let canDeleteFooter = SchedulingHelper.canDeleteFooter(selectedItem, schedulerDataRefLocal);
      if (!canDeleteFooter) {
        toast.error("Cannot delete footer because next item is locked");
        return;
      }
      var parent = SchedulingHelper.getParent(
        selectedItem.ParentProgramSchedule_id,
        schedulerDataRefLocal
      );
      var nextParent = SchedulingHelper.getNextParent(
        parent,
        schedulerDataRefLocal
      );
      var nextParentIndex = schedulerDataRef.current.indexOf(nextParent);

      deleteScheduleItems([selectedItem]);

      // Setting Header Start Time First and then rrippling its children

      if (nextParent) {
        schedulerDataRefLocal[nextParentIndex].SlotDateTime =
          selectedItem.SlotDateTime;
        schedulerDataRefLocal[nextParentIndex].children = getRippleChildrens(
          nextParent,
          nextParent.children.filter((item) => item._id != selectedItem._id),
          schedulerDataRefLocal
        );

        schedulerDataRefSetter(schedulerDataRefLocal);
        console.log(schedulerDataRefLocal[nextParentIndex]);

        // via socket updating header in planning and scheduling on footer delete
        updateHeaderSocket(schedulerDataRefLocal[nextParentIndex]);

        saveSchedule({ dataToSave: [schedulerDataRefLocal[nextParentIndex], ...schedulerDataRefLocal[nextParentIndex].children] });
      }

      let currentParentIndex = schedulerDataRefLocal.indexOf(parent);
      if (currentParentIndex > -1) {

        // ripple current header
        schedulerDataRefLocal[currentParentIndex].children = getRippleChildrens(
          parent,
          parent.children.filter((item) => item._id != selectedItem._id),
          schedulerDataRefLocal
        );

      }

      scheduleLog([selectedItem], LOGEVENT.DELETE_FOOTER);

    } catch (error) {

      console.log(error)
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

    }

  };

  const MySecondaryEventCountCell = (props) => {
    const IconsStyle = (color) => {
      return {
        color: color ?? "#E28C00",
        fontSize: "1.6em",
        size: "2.6em",
        marginRight: "0.12em",
      };
    };

    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...useTableKeyboardNavigation(props.id)}
        style={props?.style ?? {}}
      >
        <SecondaryEventIcon style={IconsStyle(props.style.color)} />
        <span style={{ fontWeight: "400", fontSize: "14px" }}>
          {props.dataItem?.Prototypes?.length ?? 0}
        </span>
      </td>
    );
  };

  const MySubtitleCell = (props) => {

    let hasSubtitle = false;

    if (props.dataItem?.Type == SCHEDULETYPE.Header) {
      hasSubtitle = props.dataItem?.segment?.Subtitle
    } else if (props.dataItem?.Type == SCHEDULETYPE.Segment) {
      let parent = SchedulingHelper.getParent(props.dataItem.ParentProgramSchedule_id, schedulerDataRef.current);
      if (parent) {
        hasSubtitle = parent?.segment?.Subtitle
      }
    }

    const IconsStyle = (color) => {
      return {
        color: color ?? "#E28C00",
        fontSize: "1.8em",
        size: "2.8em"
      };
    };

    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...useTableKeyboardNavigation(props.id)}
        style={props?.style ?? {}}
      >
        {hasSubtitle && <ClosedCaptionIcon style={IconsStyle(props.style.color)} />}
      </td>
    );
  };

  const MyAnnouncementCountCell = (props) => {
    const IconsStyle = (color) => {
      return {
        color: color ?? "#E28C00",
        fontSize: "1.6em",
        size: "2.6em",
        marginRight: "0.12em",
      };
    };

    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...useTableKeyboardNavigation(props.id)}
        style={props?.style ?? {}}
      >
        <MicIcon style={IconsStyle(props.style.color)} />
        <span style={{ fontWeight: "400", fontSize: "14px" }}>
          {props.dataItem?.Announcements?.length ?? 0}
        </span>
      </td>
    );
  };

  const MyTXReadyImageCell = (props) => {
    var isVisibleInCell = false;
    var isTXReady = false;
    // console.log('here')
    // console.log(props.dataItem)

    if (props.dataItem.Type == SCHEDULETYPE.Segment) {
      isVisibleInCell = true;
      isTXReady = props.dataItem.TXReady
    } else if (props.dataItem.Type == SCHEDULETYPE.ShortForm) {
      isVisibleInCell = true
      isTXReady = props.dataItem.mediaEpisode.TXReady ?? false
    } else {
      isVisibleInCell = false
    }

    const IconsStyle = (color) => {
      return {
        color: color ?? "#E28C00",
        fontSize: "1.6em",
        size: "2.6em",
        // backgroundColor : 'white'
      };
    };

    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...useTableKeyboardNavigation(props.id)}
        style={props?.style ?? {}}
      >
        {isVisibleInCell ? isTXReady ?
          <CheckCircleIcon style={IconsStyle('black')} /> :
          <CancelIcon style={IconsStyle('black')} /> : ''
        }
      </td>
    );
  };


  const createPayload = async (date = props.selectedScheduleDate, channelSid = props.selectedChannelSID) => {
    payloadref.current = SchedulingHelper.getPayload(
      date,
      channelSid,
      offsetTime
    );
  };

  const loaddata = async (isFromSocket = false, isFromDraft = false, draftSchedule = []) => {
    // try {
    await updateScheduleColorPreferenceOnFirstLoad();

    if (
      !payloadref.current.channelSID ||
      !payloadref.current.ScheduleDate ||
      !payloadref.current.ScheduleEndDate
    ) {
      return;
    }

    let lastParentExpandedValues = getlastparentExpandedStatus();
    setSelectedHeader({});
    selectedStateRefSetter([]);
    copyPasteDataItemSetter([]);
    setSelectedHeadersDataItems([]);
    setShowFilterGrid(false);
    !isFromSocket && schedulerDataRefSetter([]);
    !isFromSocket && setIsSchduleLoading(true);

    //columns
    var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
    var columns = await API.getFormData(
      ENTITYNAME.SCHEDULE_MAIN_GRID,
      currentUserId
    );
    console.log(columns);

    if (!columns) {
      toast.error(`${lang.there_are_some_at_server_side_please_contact_admin}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setDataColumns(columns);

    setStatusMessage(`${lang.loading_schedule_please_wait_msg_scheduling}`);
    var json = { success: false, data: [], message: 'failed' };
    if (isFromDraft) {
      json = { success: true, data: draftSchedule, message: 'success' };
    } else {
      json = await API.getScheduling(payloadref.current);
    }
    setStatusMessage(() => `${lang.loading_schedule_completed_msg_scheduling}`);
    console.log(json);
    console.log(payloadref.current);

    if (!json || json?.success == false) {
      toast.error(json?.message, { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    var parentData = json.data.filter((data) => {
      if (!data.ParentProgramSchedule_id) {
        return data;
      }
    });

    var orderedParent = parentData.sort(
      (a, b) => a.SlotDateTime - b.SlotDateTime
    );

    var finalData = [];

    for (var i = 0; i < orderedParent.length; i++) {
      var parent = orderedParent[i];
      var style = SchedulingHelper.getScheduleColorPreference(parent);
      parent.Style = style;
      // 1st level children
      var childrens = json.data
        .filter((maindata) => (maindata.ParentProgramSchedule_id == parent._id && maindata.Type != SCHEDULETYPE.Header))
        .sort((a, b) => a.SlotDateTime - b.SlotDateTime);
      var rippledChildrens = getRippleChildrens(
        parent,
        childrens,
        orderedParent,
        false
      );

      //to update info in header
      parent.Info = "";
      parent.SegmentCount = rippledChildrens.filter(x => x.Type == SCHEDULETYPE.Segment)?.length ?? 0;
      let lastRippleChildren = rippledChildrens[rippledChildrens.length - 1];
      if (lastRippleChildren?._id.includes("footer")) {
        parent.Info = setHeaderInfo(lastRippleChildren);
      }

      //expanded value
      parent.expanded = lastParentExpandedValues.find((item) => item._id == parent._id)?.expanded;

      // 2nd level children
      var childrensWithBreakChildrens = rippledChildrens.map((child) => {
        // if child is break
        if (child[IS_BREAK]) {
          var breakChildrens = json.data
            .filter(
              (breakChild) => (breakChild.ParentProgramSchedule_id == child._id && breakChild.Type != SCHEDULETYPE.Header)
            )
            .sort((a, b) => a.SlotDateTime - b.SlotDateTime);
          breakChildrens = getRippleBreakChildrens(child, breakChildrens);

          //to update info in break header
          let lastBreakRippleChildren =
            breakChildrens[breakChildrens.length - 1];
          child.Info = "";
          if (lastBreakRippleChildren?._id.includes("footer")) {
            child.Info = setHeaderInfo(lastBreakRippleChildren);
          }

          return { ...child, children: breakChildrens };
        } else {
          return child;
        }
      });

      finalData.push({
        ...parent,
        BreakNo: 0,
        children: childrensWithBreakChildrens,
      });
    }

    schedulerDataRefSetter(finalData);
    console.log(finalData);

    setScheduleTimeInfoValues(finalData, json.prevScheduleDayEndTime);


    setIsSchduleLoading(false);

    setTimeout(() => {

      treeListRef.current?.element?.scrollTo({
        left: 0, top: scrollPosition?.current, behavior: 'smooth'
      });
    }, 500);

    // } catch (error) {
    //     console.log("error", error);
    // }
  };

  const loadCombo = async () => {

    var mediaCategoryData = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    var filterMedia = mediaCategoryData.data.filter((obj) => obj.isLong === false);
    setMediaCategory(filterMedia);


    var mediaCategoryTypeData = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    setMediaCategoryType(mediaCategoryTypeData.data);

    let txStatusRes = await API.getDataLookup(ENTITYNAME.TXStatus, { sort: { SID: 1 } });
    setTxStatusData(txStatusRes.data);

    //this code is for?
    if (props.item?.SID > 0) {
      var mediaCategoryTypeTemp = mediaCategoryTypeData.data.filter((x) => x.MediaCategorySID == props.item.MediaCategory.SID);
      setFilterMediaCategoryType(mediaCategoryTypeTemp);
    }
  };


  const getlastparentExpandedStatus = () => {
    return schedulerDataRef.current.map((item, index) => {
      return { _id: item._id, expanded: item.expanded, index: index }
    })
  }

  const setHeaderInfo = (lastRippleChildren) => {
    return lastRippleChildren.Type == SCHEDULETYPE.OverRun
      ? OVERRUN +
      "-" +
      utility.convertMilisecondsToStringWithFrames(
        lastRippleChildren.Duration
      )
      : UNDERRUN +
      "-" +
      utility.convertMilisecondsToStringWithFrames(
        lastRippleChildren.Duration
      );

  }

  const setScheduleTimeInfoValues = (finalData, prevScheduleDayEndTime) => {

    let previousScheduleDayEndDateTime = prevScheduleDayEndTime == undefined || prevScheduleDayEndTime == 0
      ? 0 : prevScheduleDayEndTime;

    if (finalData.length > 0) {

      if (finalData[0]?.children.length > 0) {
        var firstChildren = finalData[0]?.children[0];
        var lastChildren = finalData[finalData.length - 1].children.length > 0 ?
          finalData[finalData.length - 1].children[finalData[finalData.length - 1].children.length - 1] :
          finalData[finalData.length - 2].children[finalData[finalData.length - 2].children.length - 1];
        props.setScheduleTimeInfo((old) => {
          return {
            StartDateTime: firstChildren.SlotDateTime,
            EndDateTime: lastChildren ? lastChildren.SlotDateTime + lastChildren.Duration : 0,
            PreviousScheduleEndDateTime: prevScheduleDayEndTime ? previousScheduleDayEndDateTime : old.PreviousScheduleEndDateTime,
          }
        })
      }
      else {
        props.setScheduleTimeInfo((old) => {
          return {
            StartDateTime: finalData[0].SlotDateTime,
            EndDateTime: finalData[0].SlotDateTime + finalData[0].Duration,
            PreviousScheduleEndDateTime: prevScheduleDayEndTime ? previousScheduleDayEndDateTime : old.PreviousScheduleEndDateTime,
          }
        })
      }
    } else {
      props.setScheduleTimeInfo({
        StartDateTime: 0,
        EndDateTime: 0,
        PreviousScheduleEndDateTime: previousScheduleDayEndDateTime,
      })

    }

  }


  // auto save is true by default
  const addScheduleItems = async (destinationDataItem, dataItems, autoSave = true) => {
    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }
    const itemInfo = SchedulingHelper.getChildInfo(destinationDataItem, schedulerDataRef.current);

    if (itemInfo.isBreak || itemInfo.isInsideBreak) {
      if (!dataItems.some(item => item.mediaEpisode.MediaCategory.SID == destinationDataItem.mediaEpisode.MediaCategory.SID)) {
        toast.error(`${lang.please_choose_right_media_categor_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        if (dataItems.some(item => item.Source == "Booking")) {
          props.loadBookingCommercialdata();
        }
        return;
      }
    }

    const destinationItemInfo = SchedulingHelper.getDestinationItemInfo(itemInfo);
    const { level_0_index, level_1_index, level_2_index } = destinationItemInfo;
    console.log({ level_0_index, level_1_index, destinationDataItem });
    let schedulerDataLocal = [...schedulerDataRef.current];
    let dataToSave = [];

    if (destinationItemInfo.insertType == SCHEDULE_CHILDREN_TYPE.break_children) {
      console.log(schedulerDataLocal[level_0_index].children[level_1_index]);
      //adding
      schedulerDataLocal[level_0_index].children[level_1_index].children.splice(level_2_index, 0, ...dataItems);
      //rippling
      dataToSave = schedulerDataLocal[level_0_index].children[level_1_index].children = getRippleBreakChildrens(schedulerDataLocal[level_0_index].children[level_1_index], schedulerDataLocal[level_0_index].children[level_1_index].children);
    } else {
      //adding
      console.log(schedulerDataLocal[level_0_index].children);
      schedulerDataLocal[level_0_index].children.splice(level_1_index, 0, ...dataItems);
      console.log(schedulerDataLocal[level_0_index].children);
      //rippling
      dataToSave = schedulerDataLocal[level_0_index].children = getRippleChildrens(schedulerDataLocal[level_0_index], schedulerDataLocal[level_0_index].children, schedulerDataLocal);
    }

    //ui
    // also inserting elements in filtergrid ui if it is open
    if (showFilterGrid) {
      const filterGridDataLocal = [...showFilterGridDataRef.current];
      const destinationItemIndex = filterGridDataLocal.findIndex(item => item._id == destinationDataItem._id);
      if (destinationItemIndex == -1) {
        filterGridDataLocal.push(...dataItems);
      } else {
        filterGridDataLocal.splice(destinationItemIndex + 1, 0, ...dataItems);
      }
      setShowFilterGridData(filterGridDataLocal);
    }
    schedulerDataRefSetter(schedulerDataLocal);


    // saving rippled data in database
    if (autoSave) {
      console.log(dataToSave);
      await saveSchedule({
        destinationItemInfo: destinationItemInfo,
        dataToSave: dataToSave,
      });
    }


    //logging
    //debugger
    scheduleLog(dataItems, LOGEVENT.SAVE_SCHEDULING);

  }

  const scheduleLog = (dataItems, logEvent) => {

    console.log(dataItems);
    console.log(logEvent);

    dataItems.map((item) => {
      let logData = {
        event: logEvent,
        data: item,
        message: logEvent,
        module: MODULE.SCHEDULING,
      };
      API.SaveLogs(logData);
    });
  }

  const removeItemsFromFilterGrid = (dataItems) => {

    let filterGridData = showFilterGridDataRef.current.filter(item => !dataItems.some(item2 => item2._id == item._id));
    setShowFilterGridData(filterGridData);
    if (filterGridData.length == 0 && filterSearchWordRef.current.length == 0) {
      setShowFilterGrid(false);
    }
  }

  const deleteScheduleItems = async (dataItems, deleteFromDatabase = false, isInternal = false) => {
    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }

    showFilterGridRef.current && removeItemsFromFilterGrid(dataItems);

    await deleteScheduleItemsExecute(dataItems, deleteFromDatabase, isInternal);

    socket.emit(SOCKET_EVENTS.onSocketData,
      {
        action: SOCKET_ACTION.SCHEDULE_DELETEITEM,
        module: MODULE.SCHEDULING,
        data: {
          selectedChannelSID: selectedChannel.SID,
          selectedScheduleDate: props.selectedScheduleDate,
          dataItems: dataItems
        },
        user: { name: user.name, _id: user._id }
      });

    scheduleLog(dataItems, LOGEVENT.DELETE_SCHEDULING);

  }

  const deleteScheduleItemsExecute = async (dataItems, deleteFromDatabase = false, isInternal = false) => {

    // removing duplicate enrty
    if (dataItems && dataItems.length > 0) {
      dataItems = Array.from(new Set(dataItems));
    }

    const schedulerDataLocal = [...schedulerDataRef.current];

    var deleteIndexes = [];

    dataItems.map((item) => {
      let {
        isHeader,
        isInsideBreak,
        isBreak,
        level_0_index,
        level_1_index,
        level_2_index,
      } = SchedulingHelper.getChildInfo(item, schedulerDataLocal);
      console.log(item);
      console.log(isHeader);
      if (isInsideBreak) {
        deleteIndexes.push([level_0_index, level_1_index, level_2_index]);
      } else if (isHeader) {
        deleteIndexes.push([level_0_index]);
      } else {
        console.log("Here i found");
        deleteIndexes.push([level_0_index, level_1_index]);
      }
    });

    // finally removing items by indexes
    const newDeleteIndexes = deleteIndexes
      .sort((a, b) => b[2] - a[2])
      .sort((a, b) => b[1] - a[1])
      .sort((a, b) => b[0] - a[0]);
    console.log(newDeleteIndexes);

    let uniqueParentIndex = [];


    newDeleteIndexes.map((index) => {
      if (index.length == 1) { // OK IT is Header
        schedulerDataLocal.splice(index[0], 1);
      } else if (index.length == 2) { // IT iS from commercial and segments
        schedulerDataLocal[index[0]].children.splice(index[1], 1);
        //ripple
        let parent = schedulerDataLocal[index[0]];
        let children = schedulerDataLocal[index[0]].children;
        schedulerDataLocal[index[0]].children = getRippleChildrens(parent, children, schedulerDataLocal);

        // adding in unique parent Index For saving children in schedule
        if (!uniqueParentIndex.includes(index[0])) uniqueParentIndex.push(index[0]);

      } else if (index.length == 3) { //IT is from break
        schedulerDataLocal[index[0]].children[index[1]].children.splice(index[2], 1);

        //ripple
        let parent = schedulerDataLocal[index[0]].children[index[1]];
        let children = schedulerDataLocal[index[0]].children[index[1]].children;
        schedulerDataLocal[index[0]].children[index[1]].children = getRippleBreakChildrens(parent, children);

        // saving children directly if it is inside break
        if (deleteFromDatabase) saveSchedule({ dataToSave: schedulerDataLocal[index[0]].children[index[1]].children });
      }
    });


    // Final Saving Children In DB
    if (deleteFromDatabase) {
      var finalSaveChildrenArray = [];
      uniqueParentIndex.map((pi) => {
        finalSaveChildrenArray = [...finalSaveChildrenArray, ...schedulerDataLocal[pi].children];
      });
      saveSchedule({ dataToSave: finalSaveChildrenArray });
    }

    // setting UI
    schedulerDataRefSetter(schedulerDataLocal);

    //reset
    selectedStateRefSetter([]);

    // updating in DB
    if (deleteFromDatabase) await saveSchedule({ deletedSchedules: dataItems });


    //refeshing booking
    if (dataItems.length > 0 && !isInternal && dataItems.some((item) => item.Booking_id || item.Source == "Booking")) {
      props.loadBookingCommercialdata();

      //ADD LOG
      SalesHelper.removeBookingLogFromSchedule(dataItems);
    }

  };

  const saveSchedule = async ({ destinationItemInfo, dataToSave = [], deletedSchedules = [], }) => {

    //   console.log(dataToSave);
    savingSchedule = true;
    let response = await API.saveSchedule({
      schedules: SchedulingHelper.prepareDataforbackend(dataToSave),
      deletedschedules:
        SchedulingHelper.prepareDataforbackend(deletedSchedules),
    });
    // console.log(response)

    // after update need to set saved elements on UI,
    // ONLY ON NEW ITEMS ADDITION, NO Need In Case of Deleted Items
    if (response.success && dataToSave.length > 0 && destinationItemInfo) {
      const { level_0_index, level_1_index } = destinationItemInfo;
      let schedulerDataLocal = [...schedulerDataRef.current];

      //assuming during insertion and update full children would be ppassing to update the ripple time;
      response.data.savedschedules.map((res, i) => {

        if (res.success && res.message == "inserted") {

          if (destinationItemInfo.insertType == SCHEDULE_CHILDREN_TYPE.break_children) {
            schedulerDataLocal[level_0_index].children[level_1_index].children[i]._id = res.data._id;
          }
          else {
            schedulerDataLocal[level_0_index].children[i]._id = res.data._id;
          }

          // setting updated values on UI binded property and reference.
          schedulerDataRefSetter(schedulerDataLocal);
        }
      });

      savingSchedule = false;

      //notification to other client.
      socket.emit(SOCKET_EVENTS.onSocketData,
        {
          action: SOCKET_ACTION.SCHEDULE_ADDITEM,
          module: MODULE.SCHEDULING,
          data: {
            selectedChannelSID: selectedChannel.SID,
            selectedScheduleDate: props.selectedScheduleDate,
            insertType: destinationItemInfo.insertType,
            level_0_index: level_0_index,
            level_1_index: level_1_index,
            children: destinationItemInfo.insertType == SCHEDULE_CHILDREN_TYPE.break_children ? schedulerDataLocal[level_0_index].children[level_1_index].children : schedulerDataLocal[level_0_index].children

          },
          user: { name: user.name, _id: user._id }
        });

    }
    else if (!response.success) {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }

    savingSchedule = false;


  };

  const getRippleChildrens = (parent, childrens, allParentsData, isUpdateHeader = true) => {
    var startTime = parent.SlotDateTime;

    // removing under rover run if exists
    childrens = childrens.filter((data) => {
      if (
        data.Type != SCHEDULETYPE.OverRun &&
        data.Type != SCHEDULETYPE.UnderRun
      ) {
        return data;
      }
    });

    var index = 0;
    var rippledChildren = childrens.map((item) => {
      item.ScheduleDate = startTime;
      item.SlotDateTime = startTime;
      startTime = startTime + item.Duration;
      item.Prototypes = item.Prototypes ?? [];
      item.Announcements = item.Announcements ?? [];
      item.Style = SchedulingHelper.getScheduleColorPreference(item);
      item.local_id = parent._id.toString() + "_" + index++;
      item.EndSlotDateTime = item.SlotDateTime + item.Duration;
      return item;
    });

    var nextParent = SchedulingHelper.getNextParent(parent, allParentsData);

    if (nextParent) {
      var footer = SchedulingHelper.getFooter(
        parent,
        rippledChildren,
        nextParent,
        selectedChannel
      );

      if (footer) {
        footer.Style = SchedulingHelper.getScheduleColorPreference(footer);
        footer.local_id = parent._id.toString() + "_" + index;
        footer.EndSlotDateTime = footer.SlotDateTime + footer.Duration;

        rippledChildren.push(footer);
        isUpdateHeader && updateParentInfo(parent, footer);
      }
      else {
        // no footer means all OK.
        updateParentInfo(parent, undefined);
      }
    }

    try {
      var parentIndex = allParentsData.findIndex((x) => x.Type == SCHEDULETYPE.Header && x._id == parent._id);

      //FOR LAST PARENT 
      if (parentIndex == allParentsData.length - 1) {
        setScheduleTimeInfoValues(schedulerDataRef.current)
      }


    } catch (error) {
      console.log(error)
    }

    return rippledChildren;
  };

  const updateParentInfo = (parent, footer) => {
    try {

      let schedulerDataRefLocal = schedulerDataRef.current;
      if (schedulerDataRefLocal.length == 0) return;

      let { level_0_index, level_1_index, isHeader } =
        SchedulingHelper.getChildInfo(parent, schedulerDataRefLocal);

      let info = ''
      if (footer != undefined) {
        info = setHeaderInfo(footer);
      }


      if (isHeader) {
        if (schedulerDataRefLocal[level_0_index]) schedulerDataRefLocal[level_0_index]["Info"] = info ?? '';
      } else {
        if (schedulerDataRefLocal[level_0_index]) schedulerDataRefLocal[level_0_index].children[level_1_index]["Info"] = info ?? '';
      }

      schedulerDataRefSetter(schedulerDataRefLocal);
    } catch (e) {
      alert(e)
    }
  };

  const getRippleBreakChildrens = (parent, childrens, isUpdateHeader = true) => {
    var startTime = parent.SlotDateTime;
    childrens = childrens.filter((data) => {
      if (
        data.Type != SCHEDULETYPE.OverRun &&
        data.Type != SCHEDULETYPE.UnderRun
      ) {
        return data;
      }
    });
    var index = 0;
    var rippledChildren = childrens.map((item) => {
      item.ScheduleDate = startTime;
      item.SlotDateTime = startTime;
      startTime = startTime + item.Duration;
      item.Style = SchedulingHelper.getScheduleColorPreference(item);
      item.local_id = parent._id.toString() + "_" + index++;
      item[IS_BREAK_CHILDREN] = true;
      item.EndSlotDateTime = item.SlotDateTime + item.Duration;
      return item;
    });

    var footer = SchedulingHelper.getBreakFooter(
      parent,
      childrens,
      selectedChannel
    );

    if (footer) {
      footer.Style = SchedulingHelper.getScheduleColorPreference(footer);
      footer.local_id = parent._id.toString() + "_" + index++;
      footer.EndSlotDateTime = footer.SlotDateTime + footer.Duration
      rippledChildren.push(footer);
      isUpdateHeader && updateParentInfo(parent, footer);
    }
    else {
      // no footer means packed schedule so we will update info column as blank
      updateParentInfo(parent, undefined);
    }

    return rippledChildren;
  };

  // on drop end

  //grid rowRender start==============================================

  const GridRowRenderFilterGrid = (tr, props) => {

    const trProps = {
      style: props.dataItem.Style,
      draggable: false,
      onDragOver: (e) => {
        e.preventDefault();
      },
      onContextMenu: (e) => {
        e.preventDefault();
        fg.current?.show(e)
        hcm.current?.hide(e);
        bc.current?.hide(e);
        cm.current?.hide(e);
        schedulerChildrenFilterRef.current =
          props.children[e.target.cellIndex].props;
        let dataToSelect =
          selectedStateRef.current.length > 1
            ? selectedStateRef.current
            : [props.dataItem];

        selectedStateRefSetter(dataToSelect);
      },
      onDrop: (e) => onRowDrop(e, props.dataItem),
    };
    if (!props.dataItem[IS_BREAK]) {
      var children = tr.props.children.map((d, i) => {
        if (i !== 0) {
          return d;
        } else {
          return <th className="k-header k-hierarchy-cell k-header"></th>;
        }
      });
      return React.cloneElement(tr, { ...trProps }, children);
    } else {
      return React.cloneElement(tr, { ...trProps }, tr.props.children);
    }
  };

  const deleteUsedBooking = (draggedData) => {
    props.deleteUsedBooking(draggedData);

    //emit
    socket.emit(SOCKET_EVENTS.onSocketData,
      {
        action: SOCKET_ACTION.SCHEDULE_BOOKING_REFRESH,
        module: MODULE.SCHEDULING,
        data: {
          selectedChannelSID: selectedChannel.SID,
          selectedScheduleDate: props.selectedScheduleDate,
          draggedData: draggedData
        },
        user: { name: user.name, _id: user._id }
      });
  };

  //grid rowRender end==============================================

  const onSelectionChange = (event, isVirtual = true, datatem) => {
    //console.log(event);
    const skip = isVirtual ? event.target.props.skip : 0;
    const ctrlKey = event.ctrlKey || event.metaKey;
    const shiftKey = event.shiftKey;
    //remove event.target.props.skip to remove virtual scrolling
    const rowIndex = event.startRowIndex + skip;
    const dataItems = event.dataItems;
    const dataItem = datatem ? datatem : dataItems[rowIndex];
    if (ctrlKey) {
      let alreadySelected = selectedState.some(
        (item) => item._id == dataItem._id
      );
      if (selectedState.some((item) => (item.Type == SCHEDULETYPE.OverRun || item.Type == SCHEDULETYPE.UnderRun))) {
        toast.info("Please unselect OverRun or UnderRun");
        return;
      }

      if (alreadySelected) {
        selectedStateRefSetter(
          selectedState.filter((item) => item._id != dataItem._id)
        );
      } else {
        if (
          dataItem.Type == SCHEDULETYPE.OverRun ||
          dataItem.Type == SCHEDULETYPE.UnderRun
        ) {
          return;
        }
        selectedStateRefSetter([...selectedState, dataItem]);
      }
    }
    else if (shiftKey) {
      if (selectedState.some((item) => (item.Type == SCHEDULETYPE.OverRun || item.Type == SCHEDULETYPE.UnderRun))) {
        toast.info("Please unselect OverRun or UnderRun");
        return;
      }
      handleShiftMultiSelect(dataItem);
    }
    else {

      let alreadySelected =
        selectedState.length == 1 && selectedState[0]._id == dataItem._id
          ? true
          : false;
      if (alreadySelected) {
        selectedStateRefSetter([]);
      } else {

        // Single Footer Can be Selecetd, Commented Code By Vishal 04 Apr 24
        // if (
        //   dataItem.Type == SCHEDULETYPE.OverRun ||
        //   dataItem.Type == SCHEDULETYPE.UnderRun
        // ) {
        //   return;
        // }
        selectedStateRefSetter([dataItem]);
      }


    }

    console.log(selectedStateRef.current);
  }

  const hideContextMenus = (e) => {
    hcm.current?.hide(e);
    cm.current?.hide(e);
    bc.current?.hide(e);
    fg.current?.hide(e)
  }


  const expandChange = (event) => {
    // console.log(event);
    event.dataItem[EXPAND_FIELD] = event.value;
    setCategories([...categories]);
    if (!event.value || event.dataItem.details) {
      return;
    }
    let index = event.dataIndex;
    setCategories(schedulerDataRef.current[index].children);
  };

  const onUpdateFromPlanningEditForm = async (updatedData) => {

    // checking if updated slot date time is other schedule range 
    if (updatedData.SlotDateTime < payloadref.current.ScheduleDate || updatedData.SlotDateTime > payloadref.current.ScheduleEndDate) {
      toast.error("Cannot change header start time outside schedule 24 hour range", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var parent = SchedulingHelper.getParent(
      updatedData._id,
      schedulerDataRef.current
    );
    var parentIndex = schedulerDataRef.current.indexOf(parent);

    let schedulerDataRefLocal = schedulerDataRef.current;
    schedulerDataRefLocal[parentIndex].SlotDateTime = updatedData.SlotDateTime;
    schedulerDataRefLocal[parentIndex].EndSlotDateTime = updatedData.EndSlotDateTime;

    // ripplig cureent item
    schedulerDataRefLocal[parentIndex].children = getRippleChildrens(
      schedulerDataRefLocal[parentIndex],
      schedulerDataRefLocal[parentIndex].children,
      schedulerDataRefLocal
    );

    // previous item if exist should also be rippled to update footer, not saved 
    if (parentIndex > 0) {
      schedulerDataRefLocal[parentIndex - 1].children = getRippleChildrens(
        schedulerDataRefLocal[parentIndex - 1],
        schedulerDataRefLocal[parentIndex - 1].children,
        schedulerDataRefLocal
      );
    }

    saveSchedule({ dataToSave: [schedulerDataRefLocal[parentIndex], ...schedulerDataRefLocal[parentIndex].children] });
    if (parentIndex == 0) {
      setScheduleTimeInfoValues(schedulerDataRefLocal);
    }


    // via socket updating header in schedule and planning for other users...
    updateHeaderSocket(schedulerDataRefLocal[parentIndex]);


  };

  const updateHeaderSocket = (udpatedHeader, isForDelete = false) => {

    // socket emit for updating planning on real time
    if (isForDelete) {
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_DELETE, module: MODULE.PLANNING, data: [udpatedHeader] });
    }
    else {
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_UPDATE, module: MODULE.PLANNING, data: { ...udpatedHeader, NominalDuration: udpatedHeader.Duration } })
    }

    // socket for updating other schedulers as well
    refreshScheduleSocket(payloadref.current.channelSID, payloadref.current.ScheduleDate);

  }

  const addHeaderInPlanningSocket = (udpatedHeader, newMediaEpisode) => {

    const planningObjectForUI = {
      ...udpatedHeader,
      Channel: selectedChannel,
      MediaEpisode_id: newMediaEpisode._id,
      ParentProgramSchedule_id: null,
      ActualDuration: newMediaEpisode.Duration,
      NominalDuration: newMediaEpisode.Duration,
      Description: newMediaEpisode.Description,
      ChannelSID: selectedChannel.SID,
      mediaEpisode: newMediaEpisode,
      Title: newMediaEpisode.Title,
      StartDate: new Date(udpatedHeader.SlotDateTime),
      EndTime: new Date(udpatedHeader.SlotDateTime + newMediaEpisode.Duration),
    }


    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_NEW_DROP, module: MODULE.PLANNING, data: planningObjectForUI })

  }

  const refreshScheduleSocket = (channelSID, date) => {

    if (channelSID == 0) return;

    let userData = utility.getValue(LOCALSTORAGE_KEY.userData);
    socket.emit(SOCKET_EVENTS.onSocketData, {
      action: SOCKET_ACTION.SCHEDULE_REFRESH,
      module: MODULE.SCHEDULING,
      data: {
        selectedChannelSID: channelSID, selectedScheduleDate: date
      },
      user: { name: userData.name, _id: userData._id }
    });
  }

  const HasChildrenLocal = (selectedheader) => {
    console.log(selectedheader);
    return selectedheader.children.some(
      (x) =>
        x.Type != SCHEDULETYPE.Segment &&
        ((x.Booking_id && x.Booking_id.length > 0) ||
          (x.children &&
            x.children.length > 0 &&
            x.children.some((c) => c.Booking_id && c.Booking_id.length > 0)))
    );
  };

  const HasReconciledChildrenLocal = (selectedheader) => {
    console.log(selectedheader);
    return selectedheader.children.some(
      (x) =>
        x.IsReconciled ||
        (x.children &&
          x.children.length > 0 &&
          x.children.some((c) => c.IsReconciled))
    );
  };

  const selectAllHeaders = () => {
    let headers = schedulerDataRef.current.filter((x) => x.Type == SCHEDULETYPE.Header);
    selectedStateRefSetter(headers);
    setSelectedHeadersDataItems(headers);
  }

  const scheduleHeaderDelete = async () => {
    // delete footer
    if (!selectedHeader.current) {
      toast.error(`${lang.please_select_header_firsts_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (selectedHeader.current.isLocked) {
      toast.error('Header is locked', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    console.log(selectedHeader);

    if (HasChildrenLocal(selectedHeader.current)) {
      toast.error(
        `${lang.cannot_delete_selected_header_contains_booked_commercials_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }

    if (HasReconciledChildrenLocal(selectedHeader.current)) {
      toast.error(
        `${lang.cannot_delete_selected_header_contains_reconciled_items_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }

    ConfirmAlert(
      async () => {
        deleteScheduleItems([selectedHeader.current], false);

        // socket
        updateHeaderSocket(selectedHeader.current, true);

        var deleteResp = await API.deletePlanning(selectedHeader.current);

        toast.info(deleteResp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        // refreshing if delete not success.
        if (!deleteResp.success) {
          loaddata();
        }

      }, // on Click yes
      () => { }, // on click no
      `${lang.confirm_delete_header_confirm_alert_msg}`, //title
      `${lang.are_you_sure_you_want_to_delete_confirm_alert_msg} ${selectedHeader.current.mediaEpisode.Title
      } ${lang.header_from_confirm_alert_msg} ${moment(selectedHeader.current.SlotDateTime)
        .utc()
        .format("hh:mmA")}?` // message
    );
  };

  const setSelectedPlaceHolderMedia = async () => {
    console.log(breakdataItemForPopup)

    if (Object.keys(breakdataItemForPopup.MediaCategory).length == 0 || breakdataItemForPopup.MediaCategory == undefined || breakdataItemForPopup.MediaCategory == null) {
      toast.error("Please select Media Category", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (Object.keys(breakdataItemForPopup.MediaCategoryType).length == 0 || breakdataItemForPopup.MediaCategoryType == undefined || breakdataItemForPopup.MediaCategoryType == null) {
      toast.error("Please select Media Category Type ", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (breakdataItemForPopup.Duration == "00:00:00:00") {
      toast.error("Duration cant be 0", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let duration = utility.convertStringWithFramesToMilliseconds(breakdataItemForPopup.Duration)
    const breakRes = await API.getBreakItemForSchedule(breakdataItemForPopup.MediaCategory, breakdataItemForPopup.MediaCategoryType, duration);
    console.log(breakRes);
    if (breakRes.success) {
      let mediaEpisode = breakRes.data;
      var destinationDataItem = selectedStateRef.current[0];
      const isParent =
        destinationDataItem.ParentProgramSchedule_id == null ||
        destinationDataItem.ParentProgramSchedule_id == undefined;
      var dataItem = SchedulingHelper.getBreakItem(
        isParent,
        destinationDataItem,
        mediaEpisode,
        mediaEpisode.Duration,
        selectedChannel
      );
      addScheduleItems(destinationDataItem, [dataItem]);
    } else {
      toast.error(breakRes.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setBreakdataItemForPopup(blankDataItem);
    setShowInsertPlaceHolderFilter(false);

  };

  //programe replacement
  const programReplacement = async (replaceDataItem) => {

    if (selectedHeader.current == {}) {
      toast.error(lang.please_select_header_firsts_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (replaceDataItem.length == 0) {
      toast.error(lang.select_one_program_to_replace_with_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    var mediaEpisode = replaceDataItem[0];

    // checking rights
    const dealRights = await PlanningHelper.getMediaEpisodeDealRights(mediaEpisode);

    if (!PlanningHelper.isMediaEpisodeValidForThisChannel({ ...mediaEpisode, MediaDealRights: dealRights }, selectedChannel)) {
      toast.error(`${lang.media_not_valid_for_selected_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    var validMediaDealRight = PlanningHelper.getMediaDealRight(
      { ...mediaEpisode, MediaDealRights: dealRights },
      selectedHeader.current.SlotDateTime,
      selectedChannel
    );

    // here if media rights is more then one then it will return false and the popup to choose the media right
    if (validMediaDealRight == false) {
      setProgramToReplace(mediaEpisode);
      setShowMediaDealRightsPopup(true);
    } else {
      replaceProgram(mediaEpisode);
    }

  };

  const replaceProgramWithSelectedDealRight = (dataItem) => {
    if (dataItem.length > 0) {
      const selectedMediaDealRight = dataItem[0];
      replaceProgram(programToReplaceRef.current, selectedMediaDealRight._id.toString());
      setProgramToReplace({});
    }
    else {
      toast.error(
        `${lang.select_atleast_one_mediadealright_planning_performance_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const replaceProgram = async (newMediaEpisode, mediaDealRight_id = null) => {

    if (newMediaEpisode == undefined || newMediaEpisode._id == undefined) {
      toast.error(`${lang.replacement_media_lost_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    const saveData = {
      SID: 0,
      Channel: selectedChannel,
      MediaEpisode_id: newMediaEpisode._id,
      ScheduleDate: selectedHeader.current.SlotDateTime,
      SlotDateTime: selectedHeader.current.SlotDateTime,
      Segment_id: null,
      ParentProgramSchedule_id: null,
      IsValid: true,
      MediaDealRight_id: mediaDealRight_id,
    };

    var response = await API.ReplaceProgram(saveData, selectedHeader.current); // true for loadchildren
    console.log(response);

    if (response.success) {

      //socket
      updateHeaderSocket(selectedHeader.current, true);
      console.log(response.data);
      addHeaderInPlanningSocket(response.data.header, newMediaEpisode);

      var parentIndex = schedulerDataRef.current.findIndex(
        (header) => header._id == selectedHeader.current._id
      );
      var schedlerDataCopy = [...schedulerDataRef.current];
      schedlerDataCopy.splice(parentIndex, 1, {
        ...response.data.header,
        mediaEpisode: newMediaEpisode,
        Duration: newMediaEpisode.Duration,
        children: response.data.children,
        expanded: selectedHeader.current.expanded ?? true,
        EndSlotDateTime: response.data.header.SlotDateTime + newMediaEpisode.Duration,
        Style: SchedulingHelper.getScheduleColorPreference(
          response.data.header
        ),
      });
      schedlerDataCopy[parentIndex].children = getRippleChildrens(
        schedlerDataCopy[parentIndex],
        response.data.children,
        schedlerDataCopy
      );
      schedulerDataRefSetter(schedlerDataCopy);
      scheduleLog([selectedHeader.current], LOGEVENT.PROGRAM_REPLACEMENT);

    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const onCancelMediaDealRightPopup = () => {
    setProgramToReplace({});
    setShowMediaDealRightsPopup(false);
  };

  //global replacement
  const showGlobalReplacement = () => {
    console.log(lockStatus);
    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }
    if (selectedStateRef.current.length == 0) {
      toast.error(lang.please_select_schedule_item_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (selectedStateRef.current.some(data => utility.canModify(data, schedulerDataRef.current))) {
      toast.error('Locked header found. Please unlock and try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (selectedStateRef.current.some((x) => (x.mediaEpisode?.MediaCategory?.SID ?? 0) != selectedStateRef.current[0].mediaEpisode.MediaCategory.SID)) {
      toast.error(lang.please_select_same_media_category_media_only_global_replacement_error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      selectedStateRef.current.some(
        (item) =>
          item.Type == SCHEDULETYPE.Segment ||
          item.Type == SCHEDULETYPE.UnderRun ||
          item.Type == SCHEDULETYPE.OverRun ||
          item[IS_BREAK]
      )
    ) {
      toast.error(lang.not_allowed_on_this_postion_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setShowGlobalReplacementDialog(true);
  };

  const gloabalFindAndReplace = async (
    selectedItems,
    replaceWith,
    isBreakInclude,
    replaceMethod,
  ) => {
    var replaceArrayIndexes = [];
    var schedulerDataCopy = [...schedulerDataRef.current];

    if (REPLACEMENTMODE[replaceMethod] != REPLACEMENTMODE.Selected) {
      schedulerDataCopy.map((parent, pi) => {

        parent.children.map((children, ci) => {

          // if you choose include break from replacement window it will also replace child inside level 2 (0,1,2)
          if (children[IS_BREAK] && isBreakInclude) {
            children.children.map((breackChild, bi) => {
              if (
                breackChild?.mediaEpisode?._id ==
                selectedItems[0]?.mediaEpisode?._id
              ) {
                replaceArrayIndexes.push([pi, ci, bi]);
              }
            });
          }
          if (
            !children[IS_BREAK] &&
            children?.mediaEpisode?._id == selectedItems[0]?.mediaEpisode?._id
          ) {
            replaceArrayIndexes.push([pi, ci]);
          }
        });
      });
    }

    switch (REPLACEMENTMODE[replaceMethod]) {
      case REPLACEMENTMODE.ALL:
        await globalReplace(replaceWith, replaceArrayIndexes);
        break;
      case REPLACEMENTMODE.Alternate:
        await globalReplace(
          replaceWith,
          replaceArrayIndexes.filter((replaceArrayIndex, i) => i % 2 == 0)
        );
        break;
      case REPLACEMENTMODE.Random:
        await globalReplace(replaceWith, replaceArrayIndexes, true);
        break;
      case REPLACEMENTMODE.Selected:
        replaceArrayIndexes = selectedItems.map((item) => {
          let { level_0_index, level_1_index, level_2_index, isInsideBreak } =
            SchedulingHelper.getChildInfo(item, schedulerDataRef.current);
          if (isInsideBreak) {
            return [level_0_index, level_1_index, level_2_index];
          } else {
            return [level_0_index, level_1_index];
          }
        });
        await globalReplace(replaceWith, replaceArrayIndexes);

        break;

      default:
        await globalReplace(replaceWith, replaceArrayIndexes);
        break;
    }

    // sometimes search does not work but after refresh it works, so we are calling load data after global replacement.
    await loaddata(true);
  };

  const globalReplace = async (
    replaceWith,
    replaceArrayIndexes,
    random = false
  ) => {
    var replaceWithIndex = 0;
    var schedulerDataCopy = [...schedulerDataRef.current];
    var filterGridCopy = [...showFilterGridData];

    setShowProgressBar({ okButton: false, progressBarPopup: true });

    for (let index = 0; index < replaceArrayIndexes.length; index++) {
      const replaceIndex = replaceArrayIndexes[index];

      setProgressData({
        message: "Applying Global Replacement...",
        progress: Math.ceil((100 * (index + 1)) / replaceArrayIndexes.length)
      });

      if (random) {
        replaceWithIndex = Math.floor(Math.random() * replaceWith.length);
      }
      if (!random && replaceWith.length == replaceWithIndex) {
        replaceWithIndex = 0;
      }
      //CHILD
      if (replaceIndex.length == 2) {

        schedulerDataCopy[replaceIndex[0]].children.splice(replaceIndex[1], 1, {
          ...schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]],
          MediaEpisode_id: replaceWith[replaceWithIndex]._id,
          MediaId: replaceWith[replaceWithIndex]?.AssetId,
          Duration: replaceWith[replaceWithIndex]?.Duration,
          TcIn: replaceWith[replaceWithIndex]?.TcIn,
          TcOut: replaceWith[replaceWithIndex]?.TcOut,
          mediaEpisode: replaceWith[replaceWithIndex]
        });


        let filterIndex = filterGridCopy.findIndex(
          (filterData) =>
            filterData._id ==
            schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]]._id
        );
        if (filterIndex > -1) {
          filterGridCopy.splice(filterIndex, 1, {
            ...schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]],
            MediaEpisode_id: replaceWith[replaceWithIndex]._id,
            MediaId: replaceWith[replaceWithIndex]?.AssetId,
            Duration: replaceWith[replaceWithIndex]?.Duration,
            TcIn: replaceWith[replaceWithIndex]?.TcIn,
            TcOut: replaceWith[replaceWithIndex]?.TcOut,
            mediaEpisode: replaceWith[replaceWithIndex]
          });
        }
        replaceWithIndex++;

        //-----Saving------------
        await childrenSave(replaceIndex[0]);
        scheduleLog(replaceWith, LOGEVENT.GLOBAL_REPLACEMENT);
        //-----Saved-------------
      }

      //BREAK CHILD
      if (replaceIndex.length == 3) {

        schedulerDataCopy[replaceIndex[0]].children[
          replaceIndex[1]
        ].children.splice(replaceIndex[2], 1, {
          ...schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]]
            .children[replaceIndex[2]],
          MediaEpisode_id: replaceWith[replaceWithIndex]._id,
          MediaId: replaceWith[replaceWithIndex]?.AssetId,
          Duration: replaceWith[replaceWithIndex]?.Duration,
          TcIn: replaceWith[replaceWithIndex]?.TcIn,
          TcOut: replaceWith[replaceWithIndex]?.TcOut,
          mediaEpisode: replaceWith[replaceWithIndex]
        });

        let filterIndex = filterGridCopy.findIndex(
          (filterData) =>
            filterData._id ==
            schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]]
              .children[replaceIndex[2]]._id
        );
        if (filterIndex > -1) {
          filterGridCopy.splice(filterIndex, 1, {
            ...schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]]
              .children[replaceIndex[2]],
            MediaEpisode_id: replaceWith[replaceWithIndex]._id,
            MediaId: replaceWith[replaceWithIndex]?.AssetId,
            Duration: replaceWith[replaceWithIndex]?.Duration,
            TcIn: replaceWith[replaceWithIndex]?.TcIn,
            TcOut: replaceWith[replaceWithIndex]?.TcOut,
            mediaEpisode: replaceWith[replaceWithIndex]
          });
        }
        replaceWithIndex++;
        //-----Saving------------
        await breakChildrenSave(replaceIndex[0], replaceIndex[1]);
        //-----Saved-------------
      }
    }

    setProgressData({ message: "Completed", progress: 100 });
    setShowProgressBar({ progressBarPopup: true, okButton: true });

    setShowFilterGridData(filterGridCopy);
    schedulerDataRefSetter(schedulerDataCopy);
  };

  const childrenSave = async (parentIndex) => {
    let schedulerDataLocal = [...schedulerDataRef.current];
    schedulerDataLocal[parentIndex].children = getRippleChildrens(schedulerDataLocal[parentIndex], schedulerDataLocal[parentIndex].children, schedulerDataRef.current)

    let destinationIndexes = SchedulingHelper.getDestinationItemIndexes(parentIndex);

    await saveSchedule({
      destinationItemInfo: destinationIndexes,
      dataToSave: schedulerDataLocal[parentIndex].children,
    });
    schedulerDataRefSetter(schedulerDataLocal);

  };

  const breakChildrenSave = async (parentIndex, childIndex) => {
    let schedulerDataLocal = [...schedulerDataRef.current];

    schedulerDataLocal[parentIndex].children[childIndex].children = getRippleBreakChildrens(
      schedulerDataLocal[parentIndex].children[childIndex],
      schedulerDataLocal[parentIndex].children[childIndex].children
    )

    let destinationIndexes = SchedulingHelper.getDestinationItemIndexes(
      parentIndex,
      childIndex
    );
    await saveSchedule({
      destinationItemInfo: destinationIndexes,
      dataToSave: schedulerDataLocal[parentIndex].children[childIndex].children,
    });
    schedulerDataRefSetter(schedulerDataLocal);
  };

  //delete secondary events
  const deleteSelectedSecondaryEvent = async () => {
    console.log("deleteSelectedSecondaryEvent");

    //check selectedItem is not empty
    if (
      selectedStateRef.current.length == 0
    ) {
      toast.error(lang.please_select_one_item_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (selectedStateRef.current[0]?.Prototypes.length == 0) {
      toast.error(lang.no_secondary_events_attached_to_delete_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let saveData = {
      ...selectedStateRef.current[0],
      Prototypes: [], //making blank
    };

    //UI
    let schedulerDataRefLocal = schedulerDataRef.current;
    const { level_0_index, level_1_index } = SchedulingHelper.getChildInfo(
      selectedStateRef.current[0],
      schedulerDataRefLocal
    );
    schedulerDataRefLocal[level_0_index].children[level_1_index] = saveData;
    selectedStateRefSetter([saveData]);
    schedulerDataRefSetter(schedulerDataRefLocal);

    saveData = SchedulingHelper.prepareDataforbackend([saveData])[0];
    console.log(saveData);
    var res = await API.saveData(ENTITYNAME.ProgramSchedule, saveData);
    console.log(res);
    if (res.success) {
      setStatusMessage(lang.deleted_secondary_events_msg_scheduling);
      toast.success(`${lang.lang.deleted_secondary_events_msg_scheduling}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteAllSecondaryEvent = async () => {

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }

    console.log("started");
    let schedulerDataRefLocal = [...schedulerDataRef.current];
    selectedStateRefSetter([]);

    //UI

    let dataToSave = [];
    schedulerDataRefLocal.map((item, index) => {

      // TO CAPTURE THE PARENTS FROM WHERE ALL SECONDARY EVENTS ARE DELETED
      var parent = item;

      item = item.children.map((child) => {
        //break
        if (child[IS_BREAK]) {
          child.children = child.children.map((grandChild) => {
            let finalChildData = { ...grandChild, Prototypes: [] };
            if (grandChild.Prototypes?.length > 0) dataToSave.push(finalChildData);
            return finalChildData;
          })

        }
        let finalData = { ...child, Prototypes: [] };
        if (child.Prototypes?.length > 0) dataToSave.push(finalData);
        return finalData;
      });


      let saveData = SchedulingHelper.prepareDataforbackend(dataToSave);
      console.log(saveData);
      API.saveData(ENTITYNAME.ProgramSchedule, saveData).then((res) => {
        console.log(res);
      });
      schedulerDataRefLocal[index].children = item;
      scheduleLog([parent], LOGEVENT.DELETE_ALL_SECONDARY_EVENT);

    });

    schedulerDataRefSetter(schedulerDataRefLocal);
    setStatusMessage(() => `${lang.all_secondary_events_deleted_successfully_msg_scheduling}`);
    toast.success(`${lang.all_secondary_events_deleted_successfully_msg_scheduling}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleDropPrototype = async (destinationDataItem, dataToSave) => {
    console.log("handleprototypeDrop");
    let schedulerDataRefLocal = schedulerDataRef.current;


    let destinationDataItems = [destinationDataItem, ...selectedStateRef.current.filter(item => item._id != destinationDataItem._id)];

    for (let index = 0; index < destinationDataItems.length; index++) {
      const { level_0_index, level_1_index, level_2_index, isInsideBreak, isBreak } = SchedulingHelper.getChildInfo(
        destinationDataItems[index],
        schedulerDataRefLocal
      );

      //not allowed for Break
      if (isBreak) {
        toast.error(lang.prototype_drop_not_allowed_for_break_error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      //adding prototype in primary event as embedded array

      var isValidPrototype = SchedulingHelper.IsValidPrototype(
        dataToSave,
        destinationDataItems[index],
        schedulerDataRefLocal,
        setStatusMessage
      );

      let item = {};

      if (isValidPrototype) {
        // UpdateDynamicTextValue will insert Text1, text2 etc values if prototype is dynamic
        item = isInsideBreak ? schedulerDataRefLocal[level_0_index].children[level_1_index].children[level_2_index] : schedulerDataRefLocal[level_0_index].children[level_1_index];
        item.Prototypes.push(UpdateDynamicTextValue(dataToSave, item));

        //UI
        if (isInsideBreak) {
          schedulerDataRefLocal[level_0_index].children[level_1_index].children[level_2_index] = item;
        } else {
          schedulerDataRefLocal[level_0_index].children[level_1_index] = item;
        }
        //save
        await saveSchedule({
          destinationItemInfo: { level_0_index, level_1_index, level_2_index },
          dataToSave: [item],
        });
        schedulerDataRefSetter(schedulerDataRefLocal);

        setStatusMessage(() => dataToSave.Name + " applied successfully")
      }

      //ui
      selectedStateRefSetter([]);
      schedulerDataRefSetter(schedulerDataRefLocal);

      scheduleLog([{ ...item, AppliedPrototype: dataToSave }], LOGEVENT.APPLY_SECONDARY_EVENT);

      //backend
      // let saveData = SchedulingHelper.prepareDataforbackend([item])[0];
      // var res = await API.saveData(ENTITYNAME.ProgramSchedule, saveData);
      // if (res.success) {
      //   toast.success(lang.added_secondary_events_success_message, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }

      console.log(schedulerDataRefLocal[level_0_index].children);
    }


  };

  const handleDropAnnouncement = async (destinationDataItem, dataToSave) => {
    console.log("handleDropAnnouncement");
    let schedulerDataRefLocal = schedulerDataRef.current;
    let destinationDataItems = [destinationDataItem];

    for (let index = 0; index < destinationDataItems.length; index++) {
      const { level_0_index, level_1_index, level_2_index, isInsideBreak, isBreak } = SchedulingHelper.getChildInfo(
        destinationDataItems[index],
        schedulerDataRefLocal
      );

      //not allowed for Break
      if (isBreak) {
        toast.error(lang.prototype_drop_not_allowed_for_break_error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      let item = {};

      // UpdateDynamicTextValue will insert Text1, text2 etc values if prototype is dynamic
      item = isInsideBreak ? schedulerDataRefLocal[level_0_index].children[level_1_index].children[level_2_index] : schedulerDataRefLocal[level_0_index].children[level_1_index];

      if (item.Announcements == undefined) item.Announcements = [];
      item.Announcements = [...item.Announcements, ...dataToSave];

      console.log(item);

      //UI
      if (isInsideBreak) {
        schedulerDataRefLocal[level_0_index].children[level_1_index].children[level_2_index] = item;
      } else {
        schedulerDataRefLocal[level_0_index].children[level_1_index] = item;
      }
      //save
      await saveSchedule({
        destinationItemInfo: { level_0_index, level_1_index, level_2_index },
        dataToSave: [item],
      });
      schedulerDataRefSetter(schedulerDataRefLocal);

      setStatusMessage(() => "Announcement applied successfully")

      //ui
      selectedStateRefSetter([]);
      deleteUsedBooking(dataToSave);

      schedulerDataRefSetter(schedulerDataRefLocal);

      // scheduleLog([{ ...item, Announcement: dataToSave }], LOGEVENT.APPLY_SECONDARY_EVENT);

      console.log(schedulerDataRefLocal[level_0_index].children);
    }


  };

  const handleDeleteAnnouncement = async (announcement) => {
    console.log("handleDeleteAnnouncement");
    console.log(announcement);
    let schedulerDataRefLocal = schedulerDataRef.current;
    let destinationDataItems = selectedStateRef.current;

    console.log(destinationDataItems)

    for (let index = 0; index < destinationDataItems.length; index++) {
      const { level_0_index, level_1_index, level_2_index, isInsideBreak, isBreak } = SchedulingHelper.getChildInfo(
        destinationDataItems[index],
        schedulerDataRefLocal
      );

      //not allowed for Break
      if (isBreak) {
        toast.error(lang.prototype_drop_not_allowed_for_break_error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      let item = {};

      // UpdateDynamicTextValue will insert Text1, text2 etc values if prototype is dynamic
      item = isInsideBreak ? schedulerDataRefLocal[level_0_index].children[level_1_index].children[level_2_index] : schedulerDataRefLocal[level_0_index].children[level_1_index];

      if (item.Announcements == undefined) item.Announcements = [];
      item.Announcements = [...item.Announcements.filter((x) => x._id != announcement._id)];

      console.log(item);

      //UI
      if (isInsideBreak) {
        schedulerDataRefLocal[level_0_index].children[level_1_index].children[level_2_index] = item;
      } else {
        schedulerDataRefLocal[level_0_index].children[level_1_index] = item;
      }
      //save
      await saveSchedule({
        destinationItemInfo: { level_0_index, level_1_index, level_2_index },
        dataToSave: [item],
      });
      schedulerDataRefSetter(schedulerDataRefLocal);
      setStatusMessage(() => "Announcement delete successfully");

      //Update announcement nominal
      var res = await API.saveData(ENTITYNAME.Booking, { _id: announcement._id, NominalOnAir: 0, ParentHeaderMediaEpisode_id: '' });
      if (!res.success) {
        console.log(res)
      }

      //ui
      selectedStateRefSetter([item]);
      //refeshing booking
      props.loadBookingCommercialdata();

      schedulerDataRefSetter(schedulerDataRefLocal);

      // scheduleLog([{ ...item, Announcement: dataToSave }], LOGEVENT.APPLY_SECONDARY_EVENT);

      console.log(schedulerDataRefLocal[level_0_index].children);
    }


  };

  const onStyleSheetSelectionClose = (styleSheets) => {
    applyStyleSheet(styleSheets);
  };

  const onApplyFormat = async (list) => {

    const selectedFormat = list[0];

    console.log(selectedHeader.current);


    if (selectedHeader.current == undefined || selectedHeader.current.mediaEpisode._id == undefined) {
      toast.error('header not selected.',
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }

    // attaching format
    var media = {
      _id: selectedHeader.current.mediaEpisode._id,
      SID: selectedHeader.current.mediaEpisode.SID,
      AssetId: selectedHeader.current.mediaEpisode.AssetId,
      Duration: selectedHeader.current.mediaEpisode.Duration
    };

    var payload = {
      media: [media],
      formats: [selectedFormat]
    }
    await API.attachFormats(payload);

    // get valid segment for same count as of selected format.
    var validSegment = await SchedulingHelper.getMediaEpisodeValidSegment(selectedFormat, selectedHeader.current.mediaEpisode);

    if (validSegment == null) {
      toast.error("valid segment not found after attaching format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const saveData = {
      SID: 0,
      Channel: selectedChannel,
      MediaEpisode_id: selectedHeader.current.mediaEpisode._id,
      ScheduleDate: selectedHeader.current.SlotDateTime,
      SlotDateTime: selectedHeader.current.SlotDateTime,
      Segment_id: validSegment._id.toString(),
      ParentProgramSchedule_id: null,
      IsValid: true,
      MediaDealRight_id: selectedHeader.current.MediaDealRight_id,
    };

    var response = await API.ReplaceProgram(saveData, selectedHeader.current); // true for loadchildren
    console.log(response);

    if (response.success) {
      var parentIndex = schedulerDataRef.current.findIndex(
        (header) => header._id == selectedHeader.current._id
      );
      var schedlerDataCopy = [...schedulerDataRef.current];
      schedlerDataCopy.splice(parentIndex, 1, {
        ...response.data.header,
        mediaEpisode: selectedHeader.current.mediaEpisode,
        Duration: selectedHeader.current.mediaEpisode.Duration,
        children: response.data.children,
        Style: SchedulingHelper.getScheduleColorPreference(
          response.data.header
        ),
      });
      schedlerDataCopy[parentIndex].children = getRippleChildrens(
        schedlerDataCopy[parentIndex],
        response.data.children,
        schedlerDataCopy
      );
      schedulerDataRefSetter(schedlerDataCopy);
      scheduleLog([selectedHeader.current], LOGEVENT.ATTACH_FORMAT_FROM_SCHEDULE);

      toast.success("Format Attached !!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      var event = { dataItem: schedlerDataCopy[parentIndex], dataIndex: parentIndex, value: true };
      expandChange(event);

    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }



  };

  const applyStyleSheet = async (styleSheetList) => {

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }
    console.log("applyStyleSheet");
    setShowProgressBar({ okButton: false, progressBarPopup: true });

    var parentData = selectedHeadersDataItemsRef.current;

    // fill prototypes in stylesheet
    const styleSheetRes = await API.getSecondaryEventStyleSheetsWithPrototypes(styleSheetList)
    console.log(styleSheetRes);

    if (!styleSheetRes.success) {
      toast.error(styleSheetRes.message);
      return;
    }

    styleSheetList = styleSheetRes.data;

    // looping all parents of playlist for now
    for (var i = 0; i < parentData.length; i++) {
      let { level_0_index } = SchedulingHelper.getChildInfo(
        parentData[i],
        schedulerDataRef.current
      );
      setProgressData({ message: 'Applying Style Sheet', progress: Math.ceil((100 * (i + 1)) / parentData.length) });

      let schedlerDataCopy = [...schedulerDataRef.current];
      // looping style sheet list
      for (let s = 0; s < styleSheetList.length; s++) {
        // looping prototypes attached inside style sheet
        for (let x = 0; x < styleSheetList[s].Prototypes.length; x++) {
          //adding prototype in primary event as embedded array
          schedlerDataCopy[level_0_index].children = schedlerDataCopy[level_0_index].children.map((child) => {
            child.Prototypes = child.Prototypes ? child.Prototypes : [];
            if (
              child.Type != SCHEDULETYPE.UnderRun &&
              child.Type != SCHEDULETYPE.OverRun
            ) {
              var isValidPrototype = SchedulingHelper.IsValidPrototype(
                styleSheetList[s].Prototypes[x],
                child,
                schedulerDataRef.current,
                setStatusMessage
              );
              if (isValidPrototype)
                child.Prototypes.push(
                  UpdateDynamicTextValue(
                    styleSheetList[s].Prototypes[x],
                    child
                  )
                );
            }
            return child;
          }
          );

          schedulerDataRef.current = schedlerDataCopy;
          console.log('waiting')
          await API.saveSchedule({
            schedules: schedlerDataCopy[level_0_index].children,
            deletedschedules: [],
          });
          console.log('done')


        }
        setSchedulerData(schedlerDataCopy);
        scheduleLog([{ ...parentData[i], AppliedStyleSheet: styleSheetList[s] }], LOGEVENT.APPLY_STYLE_SHEET);
        // scheduleLog(response.data.savedschedules, LOGEVENT.APPLY_STYLE_SHEET);

      }
    }

    setProgressData({ message: 'Process completed', progress: 100 });
    setShowProgressBar({ progressBarPopup: true, okButton: true });
    loaddata(true);

    setStatusMessage(() => `${lang.style_sheet_applied_successfully_msg_scheduling}`);
  };

  const UpdateDynamicTextValue = (prototype, scheduleItem) => {
    if (
      prototype.SecondaryEventDynamicRules &&
      prototype.SecondaryEventDynamicRules.length > 0
    ) {
      for (
        let index = 0;
        index < prototype.SecondaryEventDynamicRules.length;
        index++
      ) {
        /// dynamically creating properties inside prototype object of type Text + TextNumber in prototype Rule
        prototype[
          "Text" + prototype.SecondaryEventDynamicRules[index].TextNumber
        ] = SchedulingHelper.GetParsedText(
          scheduleItem,
          prototype.SecondaryEventDynamicRules[index],
          schedulerDataRef.current
        );
      }
    }
    return SchedulingHelper.PreparePrototypeData(prototype);
  };

  const onCheckList = () => {
    setShowCheckList(() => !showCheckList);
  };


  const onGoToSchedule = (dataItem, hideFilterGrid = false) => {
    console.log(dataItem)
    setShowFilterGrid(hideFilterGrid);
    setShowCheckList(false);

    selectedStateRefSetter([dataItem]);
    setShowGoToSchedulePopup(false);
    let { rowIndex, updatedSchedulerData } = SchedulingHelper.getScheduleRowIndex(dataItem, schedulerDataRef.current);

    if (treeListRef?.current?.element == null) {
      // TIMEOUT FOR UI TO RENDER
      setTimeout(() => {
        treeListRef.current.element.scrollTo({
          left: 0, top: rowIndex * ROW_HEIGHT, behavior: 'smooth'
        });
      }, 500)
    } else {
      treeListRef.current.element.scrollTo({
        left: 0, top: rowIndex * ROW_HEIGHT, behavior: 'smooth'
      });
    }

    schedulerDataRefSetter(updatedSchedulerData);

  }

  //EXPORT SCHEDULE
  const exportSchedule = (automation, channel, checkList = [], doSftp = false, isPartial = false) => {
    let date_ttc_milisecond = selectedDateRef.current;

    console.log('here in main');
    console.log(checkList);

    var failure = checkList.filter((obj) => obj.Status == 'Failure')

    if (selectedStateRef.current.length > 0) {

      var parent = {};
      if (selectedStateRef.current[0]?.Type == SCHEDULETYPE.Header) {
        parent = selectedStateRef.current[0];
      }
      else {
        parent = SchedulingHelper.getParent(selectedStateRef.current[0].ParentProgramSchedule_id, schedulerDataRef.current);
      }

      if (isPartial) {
        ConfirmAlert(
          () => {
            //yes -- partial export
            let payload = SchedulingHelper.getPayload(date_ttc_milisecond, channel.SID, offsetTime);
            SchedulingHelper.downloadschedule(payload, automation, parent.SlotDateTime, doSftp, channel);

            let logData = { event: LOGEVENT.SCHEDULE_EXPORT, module: MODULE.SCHEDULING, data: user, message: 'Partial Playlist Export' };
            API.SaveLogs(logData);

          },
          () => {
            //no -- noraml export
            let payload = SchedulingHelper.getPayload(date_ttc_milisecond, channel.SID, offsetTime)
            SchedulingHelper.downloadschedule(payload, automation, 0, doSftp, channel);
            let logData = { event: LOGEVENT.SCHEDULE_EXPORT, module: MODULE.SCHEDULING, data: user, message: 'SCHEDULE_EXPORT' };
            API.SaveLogs(logData);

          },
          "Confirm Export",
          `Do you want to export playlist from ${utility.convertMilisecondsToStringWithFrames(parent.SlotDateTime)} onwards?`
        );
      } else {
        let payload = SchedulingHelper.getPayload(date_ttc_milisecond, channel.SID, offsetTime)
        SchedulingHelper.downloadschedule(payload, automation, 0, doSftp, channel);
        let logData = { event: LOGEVENT.SCHEDULE_EXPORT, module: MODULE.SCHEDULING, data: user, message: 'SCHEDULE_EXPORT' };
        API.SaveLogs(logData);
      }
      return;
    }

    if (props.statusInfo.overRunUnderRunCount > 0 || failure.length > 0) {
      ConfirmAlert(
        () => {
          let payload = SchedulingHelper.getPayload(date_ttc_milisecond, channel.SID, offsetTime)
          console.log(payload);
          SchedulingHelper.downloadschedule(payload, automation, 0, doSftp, channel);
          if (failure.length > 0) {
            let logData = { event: LOGEVENT.SCHEDULE_EXPORT, module: MODULE.SCHEDULING, data: failure, message: 'CheckList Failure' };
            API.SaveLogs(logData);
          }
        },
        () => { },
        "Confirm Export",
        failure.length > 0 ?
          `Current playlist have ${failure.length} checklist case fails, Click YES to export with failed checklist cases and NO to cancel and fix playlist` :
          "Current playlist contains " + props.statusInfo.overRunUnderRunCount + " under/over runs, Click YES to export with under/over runs and NO to cancel and fix the under/over runs."
      );
    } else {
      console.log(channel)
      let payload = SchedulingHelper.getPayload(date_ttc_milisecond, channel.SID, offsetTime)
      console.log(payload);
      SchedulingHelper.downloadschedule(payload, automation, 0, doSftp, channel);

      let logData = { event: LOGEVENT.SCHEDULE_EXPORT, module: MODULE.SCHEDULING, data: user, message: 'SCHEDULE_EXPORT' };
      API.SaveLogs(logData);

    }

  }

  const exportScheduledAnnouncement = async () => {
    console.log('here in exportScheduledAnnouncement');
    var tempData = []
    if (schedulerDataRef.current.length == 0) {
      toast.error(lang.no_scheduling_found_auto_fill_commercial_error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    schedulerDataRef.current.map((x) => {
      var [date, time] = utility.convertMilisecondsToDateTimeString(x.SlotDateTime).split(" ");

      var item = {
        ProgramDate: date,
        ProgramTime: time,
        ProgramTitle: x.mediaEpisode.Title,
      }

      if (x.children.length > 0) {
        x.children.map((y) => {
          var [childDate, childTime] = utility.convertMilisecondsToDateTimeString(y.SlotDateTime).split(' ');
          item.Time = childTime;
          if (y.Announcements && y.Announcements.length > 0) {
            y.Announcements.map((z) => {
              item.Title = z.Title;
              item.Description = z.Description ? z.Description : '';
              item.Duration = utility.convertMilisecondsToStringWithFrames(z.Duration);
              item.BookedTimeRange = `${utility.convertMilisecondsToShortTimeString(z.TimeRangeFrom)}-${utility.convertMilisecondsToShortTimeString(z.TimeRangeTo)}`
              tempData.push(item);
            })
          }
        })
      }
    })
    if (tempData.length == 0) {
      toast.error('No Announcement attached', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var res = await API.exportCSV(tempData);
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onReturnSlotDateTimeToPlannedNominalTime = async () => {

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }

    if (schedulerDataRef.current == undefined || schedulerDataRef.current.length == 0) {
      toast.info(`${lang.please_load_schedule_first_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }



    var scheduleLocal = schedulerDataRef.current;

    for (let index = 0; index < scheduleLocal.length; index++) {

      const parent = scheduleLocal[index];
      var parentIndex = schedulerDataRef.current.indexOf(parent);

      let schedulerDataRefLocal = schedulerDataRef.current;
      schedulerDataRefLocal[parentIndex].SlotDateTime = schedulerDataRefLocal[parentIndex].ScheduleDate;

      // ripplig cureent item
      schedulerDataRefLocal[parentIndex].children = getRippleChildrens(
        schedulerDataRefLocal[parentIndex],
        schedulerDataRefLocal[parentIndex].children,
        schedulerDataRefLocal
      );
      saveSchedule({ dataToSave: [schedulerDataRefLocal[parentIndex], ...schedulerDataRefLocal[parentIndex].children] });

    }

    // refresh schedule
    await loaddata(true);

  }


  const autoFillBookedCommercial = async (bookingData, fillInsideBreaksOnly = true) => {

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }
    if (schedulerDataRef.current.length == 0) {
      toast.error(lang.no_scheduling_found_auto_fill_commercial_error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var breakElements = [];
    schedulerDataRef.current.map((x) => {
      var breakItem = x.children.filter((y) => y.IsBreak == true);
      if (breakItem.length > 0) {
        breakElements = [...breakElements, ...breakItem]
      }
    })

    if (fillInsideBreaksOnly && breakElements.length == 0) {
      toast.error(lang.no_break_item_found_auto_fill_commercial_error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (bookingData.length == 0) {
      toast.error(lang.no_booked_spot_found_auto_fill_commercial_error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const bookingDataLenght = bookingData.length;

    var totalCommercialPlotted = 0;
    var tempPlottedCommercial = 1; // for starting while loop

    setShowProgressBar({ okButton: false, progressBarPopup: true });

    // SEttiung Schedule Constraints
    var enabledScheduleConstraint = await getEnabledScheduleConstraints();

    console.log(enabledScheduleConstraint);

    while (tempPlottedCommercial > 0) {
      tempPlottedCommercial = 0;

      var commercialLeftForAutoPlotting = bookingData;

      for (let index = 0; index < commercialLeftForAutoPlotting.length; index++) {

        // updating progress bar
        setProgressData({ message: bookingData.length + ' commercials left', progress: Math.ceil((100 * totalCommercialPlotted) / bookingDataLenght) });

        const bookedspot = commercialLeftForAutoPlotting[index];

        // finding best destination commercial break based on rule.
        var destination = await getValidCommercialBreakDestination(bookedspot, fillInsideBreaksOnly);

        const isValid = await SchedulingHelper.isValidDestination(destination, enabledScheduleConstraint, bookedspot, schedulerDataRef);
        if (isValid) {

          tempPlottedCommercial = tempPlottedCommercial + 1;
          totalCommercialPlotted = totalCommercialPlotted + 1;

          // removing booked spot from booking side
          deleteUsedBooking([bookedspot]);

          const dataToSave = SchedulingHelper.prepareData(
            ENTITYNAME.Booking,
            selectedChannel,
            [bookedspot],
            destination,
            schedulerDataRef.current
          );
          // adding in schedule
          await addScheduleItems(destination, dataToSave, fillInsideBreaksOnly);

          // removing from local booked spots
          bookingData = bookingData.filter(x => x._id != bookedspot._id);

        }
      }
    }

    toast.success(totalCommercialPlotted + "  Commercials Plotted.", {
      position: toast.POSITION.TOP_RIGHT,
    });

    if (!fillInsideBreaksOnly) await saveCompleteScheduleChildrenandRefresh();


    setProgressData({ message: bookingData.length + ' commercials left, Process Completed !!', progress: 100 });
    setShowProgressBar({ progressBarPopup: true, okButton: true })


  }

  const getEnabledScheduleConstraints = async () => {

    const scheduleConstraintsRes = await API.getData(ENTITYNAME.ScheduleConstraints, { query: [["Type.ID", "=", MEDIA_TYPE[1].ID], ["Archive", "=", false]] });
    if (scheduleConstraintsRes.success && scheduleConstraintsRes.data.length > 0) {
      const channelFiltered = scheduleConstraintsRes.data.filter(x => x.Channel.SID == payloadref.current.channelSID);

      return channelFiltered.length > 0 ? channelFiltered[0].EnabledConstraints : [];
    }

    return [];

  }

  const getNextItemInSchedule = (destination) => {

    var nextItem = {}

    const { level_0_index, level_1_index, level_2_index, isInsideBreak } = SchedulingHelper.getChildInfo(destination, schedulerDataRef.current);

    if (isInsideBreak) {
      const childLength = schedulerDataRef.current[level_0_index].children[level_1_index].children?.length ?? 0;
      nextItem = childLength > level_2_index + 1 ? schedulerDataRef.current[level_0_index].children[level_1_index].children[level_2_index + 1] : {}
    }
    else {
      const childLength = schedulerDataRef.current[level_0_index].children?.length ?? 0;
      nextItem = childLength > level_1_index + 1 ? schedulerDataRef.current[level_0_index].children[level_1_index + 1] : {};
    }

    return nextItem;

  }

  const getValidCommercialBreakDestination = async (bookedspot, fillInsideBreaksOnly) => {

    var destination = undefined;

    if (fillInsideBreaksOnly) {
      var validBreaks = [];

      // headers loop
      for (let index = 0; index < schedulerDataRef.current.length; index++) {
        const parent = schedulerDataRef.current[index];

        // break items commer type
        var commercialBreaks = parent.children.filter(x => x[IS_BREAK] && (x.mediaEpisode?.MediaCategoryType?.SID ?? 0) == MEDIACATEGORYTYPES.Commercial);

        if (commercialBreaks && commercialBreaks.length > 0) {

          const startTime = bookedspot.BookingDate + bookedspot.TimeRangeFrom;
          const endTime = bookedspot.BookingDate + bookedspot.TimeRangeTo;

          var updatedBookingStartTime = startTime;
          var updatedBookingEndTime = endTime;

          //CASE FOR 06:00-06:00 SCHEDULE DAY BOOKING  23:00 - 04:00
          if (bookedspot.TimeRangeFrom > bookedspot.TimeRangeTo) {
            var date = new Date(endTime);
            updatedBookingEndTime = date.setDate(date.getDate() + 1);

          }
          //CASE FOR 06:00-06:00 SCHEDULE DAY BOOKING SAME DAY  01:00 - 04:00
          else if (offsetTime > bookedspot.TimeRangeFrom) {
            var startDate = new Date(startTime);
            var endDate = new Date(endTime)
            updatedBookingStartTime = startDate.setDate(startDate.getDate() + 1);
            updatedBookingEndTime = endDate.setDate(endDate.getDate() + 1);

          }

          // filtering commercial breaks by start and end time
          commercialBreaks = commercialBreaks.filter(c => c.SlotDateTime >= updatedBookingStartTime && c.SlotDateTime < updatedBookingEndTime);

          // avoid break over run
          // (commercial breakchilren duration sum + booking spot duration) <= com break duration
          commercialBreaks = commercialBreaks.filter(c => ((c.children.filter(cf => cf.Type != SCHEDULETYPE.OverRun && cf.Type != SCHEDULETYPE.UnderRun)
            .map(bc => bc.mediaEpisode.Duration)
            .reduce((accumulator, current) => accumulator + current, 0)) + bookedspot.Duration) <= c.mediaEpisode.Duration);

          if (commercialBreaks.length > 0) validBreaks = [...validBreaks, ...commercialBreaks];

        }
      }

      if (validBreaks.length > 0) {
        destination = validBreaks.sort((a, b) => a.children.length - b.children.length)[0];
      }

    }
    else {

      var validBreaks = [];

      // headers loop
      for (let index = 0; index < schedulerDataRef.current.length; index++) {

        const parent = schedulerDataRef.current[index];

        // valid episode breaks
        var validDropDestinations = parent.children.filter(x => !x[IS_BREAK] && x.Type == SCHEDULETYPE.Segment || x.Type == SCHEDULETYPE.ShortForm);

        if (validDropDestinations && validDropDestinations.length > 0) {

          const startTime = bookedspot.BookingDate + bookedspot.TimeRangeFrom;
          const endTime = bookedspot.BookingDate + bookedspot.TimeRangeTo;

          var updatedBookingStartTime = startTime;
          var updatedBookingEndTime = endTime;

          //CASE FOR NEXT DAY BOOKING  23:00 - 04:00
          if (bookedspot.TimeRangeFrom > bookedspot.TimeRangeTo) {
            var date = new Date(endTime);
            updatedBookingEndTime = date.setDate(date.getDate() + 1);

          }
          //CASE FOR NEXT DAY BOOKING SAME DAY  01:00 - 04:00
          else if (offsetTime > bookedspot.TimeRangeFrom) {
            var startDate = new Date(startTime);
            var endDate = new Date(endTime)
            updatedBookingStartTime = startDate.setDate(startDate.getDate() + 1);
            updatedBookingEndTime = endDate.setDate(endDate.getDate() + 1);

          }

          // filtering commercial breaks by start and end time
          validDropDestinations = validDropDestinations.filter(c => (c.SlotDateTime + c.Duration) >= updatedBookingStartTime && (c.SlotDateTime + c.Duration) < updatedBookingEndTime);

          // TODO, Booking Position
          if (validDropDestinations.length > 0) validBreaks = [...validBreaks, ...validDropDestinations];

        }
      }

      if (validBreaks.length > 0) {
        // picking up random break out of valid
        destination = validBreaks[Math.floor(Math.random() * validBreaks.length)];
      }

    }


    return destination;
  }

  const ResetAutoFillPlaylistChildren = async (deleteallexceptsegment = true) => {

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }
    console.log(schedulerDataRef.current);

    var itemsToDelete = [];
    schedulerDataRef.current.map(parent => {
      parent.children.map(child => {
        const isAutoFilled = deleteallexceptsegment || (child?.isAutoFill ?? false);
        if (child?.Type != SCHEDULETYPE.UnderRun && child?.Type != SCHEDULETYPE.OverRun && child?.Type != SCHEDULETYPE.Segment && isAutoFilled) {

          itemsToDelete.push(child);
          if (child[IS_BREAK] && child.children && child.children.length > 0) {

            child.children.map(breakchild => {
              if (breakchild?.Type != SCHEDULETYPE.UnderRun && breakchild?.Type != SCHEDULETYPE.OverRun && (deleteallexceptsegment || (breakchild?.isAutoFill ?? false))) {
                itemsToDelete.push(breakchild);
              }
            })

          }
        }
      })
    });

    if (itemsToDelete.length > 0) {

      // By Vishal, Was Getting Request Entity Too Large On Prod While Resetting Complete Playlist In One GO

      var tempCounter = 0;
      const chunk = 50;
      while (tempCounter <= itemsToDelete.length) {

        const tempItemsToDelete = itemsToDelete.slice(tempCounter, tempCounter + chunk);
        if (tempItemsToDelete && tempItemsToDelete.length > 0) await API.saveSchedule({ schedules: [], deletedschedules: tempItemsToDelete, });

        tempCounter = tempCounter + chunk;
      }

      // refresh schedule
      await loaddata(true);

    }

  }



  const fillUnderOverRun = async (scheduleTimeInfo, isForMultipleDays = false, multipleDayPayload = undefined) => {

    let allfooters = [];
    if (isForMultipleDays && multipleDayPayload != undefined) {

      const startDate = new Date(multipleDayPayload.FromDate).getTime();
      const endDate = new Date(multipleDayPayload.ToDate).getTime();

      let tempDate = startDate;
      while (tempDate <= endDate) {

        props.setSelectedDateandChannel(tempDate, multipleDayPayload.Channel);

        await createPayload(tempDate, multipleDayPayload.Channel.SID);
        await loaddata();

        await ResetAutoFillPlaylistChildren(true);
        await onReturnSlotDateTimeToPlannedNominalTime();

        await fillUnderOverRunWithStiching();

        // capturing footers left
        for (let index = 0; index < schedulerDataRef.current.length; index++) {
          const parent = schedulerDataRef.current[index];
          const footer = parent.children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
          if (footer && footer.Duration > 0) allfooters.push(footer);
          props.setFastplaylistReportData(allfooters);
        }


        // aadding one day
        tempDate = tempDate + MILLISECONDSINADAY;

      }
    }

    else { // single current day processing only
      await fillUnderOverRunWithStiching();
    }

  }

  const fillUnderOverRunWithStiching = async () => {

    // We will stitch Episodes Under 15 Mins

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }
    if (schedulerDataRef.current == undefined || schedulerDataRef.current.length == 0) {
      toast.info(`${lang.please_load_schedule_first_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const { isvalid, headerstarttime } = await SchedulingHelper.IsAllHeadersSegmented(schedulerDataRef.current);
    console.log({ isvalid, headerstarttime });
    if (!isvalid) {
      toast.info('Process Aborted. Some headers are not segmented, starting ' + headerstarttime, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setShowProgressBar({ okButton: false, progressBarPopup: true });

    var autoDeleteOffFooterOffset = 0;
    var maxDamageOffset = 0;
    const appconfig = await API.getEntitiesWithSearch(ENTITYNAME.ApplicationConfiguration);
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const isKartoonChannel = (company?.Name ?? "") == "Kartoon Studios";

    if (appconfig.success && appconfig.data && appconfig.data.length > 0) {
      const offsetObj = appconfig.data.find(x => x.Key == "AutoDeleteFooterOffset");
      autoDeleteOffFooterOffset = parseInt(offsetObj?.Value ?? 0);

      const maxDamageOffsetObj = appconfig.data.find(x => x.Key == "MaxFooterDamageOffset");
      maxDamageOffset = parseInt(maxDamageOffsetObj?.Value ?? 60000); // 1 Min Max Damage if No Setting
    }

    // PULL VARUATIONS ENABLED FOR THIS CHANNEL
    setProgressData({ message: "Fetching Variations...", progress: 10 });
    setStatusMessage("Fetching Variations...");
    const variationRes = await SchedulingHelper.getVariationsData(payloadref.current);

    // pulling latest previous date end date time
    const previousEndTimeRes = await API.getPreviousDayEndTime(payloadref.current);
    const prevEndDateTimeString = previousEndTimeRes && previousEndTimeRes.success && previousEndTimeRes.data > 0 ? previousEndTimeRes.data : 0
    await onMatchPreviousDayEndTime({ PreviousScheduleEndDateTime: prevEndDateTimeString });

    setProgressData({ message: "Filling Schedule...", progress: 15 });
    setStatusMessage("Filling Schedule...");
    await FillFormatSequenceInSchedule(variationRes, isKartoonChannel);

    //adding last position items in schedule
    setProgressData({ message: "Placing Last Position Items...", progress: 20 });
    setStatusMessage("Placing Last Position Items...")
    await PlacingLastPositionItems(variationRes);

    //adding Fixed Slot item in schedule
    setProgressData({ message: "Placing Fixed Position Items...", progress: 25 });
    setStatusMessage("Placing Fixed Position Items...")
    await PlacingFixedPositionItems(variationRes);

    //ONLY FOR KARTOON ADDING IDENT ON TOP OF PLAYLIST
    if ((company?.Name ?? "") == "Kartoon Studios") {
      await PlacingFirstPositionItem(variationRes);
    }

    // Saving Schedule In Bulk
    setProgressData({ message: "Saving Schedule...", progress: 35 });
    setStatusMessage("Saving Schedule...");
    await saveCompleteScheduleChildrenandRefresh();


    setProgressData({ message: "Replacing Childrens...", progress: 45 });
    setStatusMessage("Replacing Childrens...");
    await ReplaceItemsToMatchUnderAndOverRunsByBlockExactMatchOnly(variationRes, autoDeleteOffFooterOffset, false);


    setProgressData({ message: "Replacing Childrens...", progress: 60 });
    setStatusMessage("Replacing Childrens...");
    await ReplaceItemsToMatchUnderAndOverRuns(variationRes, autoDeleteOffFooterOffset, false);

    setProgressData({ message: "Refilling Schedule...", progress: 75 });
    setStatusMessage("Refilling Schedule...");
    await RefillAndDeleteUnderAndOverRun(variationRes, autoDeleteOffFooterOffset);

    setProgressData({ message: "Refilling Schedule...", progress: 80 });
    setStatusMessage("Refilling Schedule...");
    await MaximumDamageControl(maxDamageOffset);

    setProgressData({ message: "Packing Playlist for 24 Hours...", progress: 85 });
    setStatusMessage("Packing Playlist for 24 Hours...");
    await PackPlaylistFor24Hours(variationRes);


    // this function is currently for kartoon channel only.
    setProgressData({ message: "Sequencing Idents and Creatives...", progress: 90 });
    setStatusMessage("Sequencing Idents and Creatives...");

    if (isKartoonChannel) {

      await SequenceVarianceInSchedule(variationRes, MEDIACATEGORYTYPES.Ident, false);
      await SequenceVarianceInSchedule(variationRes, MEDIACATEGORYTYPES.ChannelPromo, false);
    }

    // Saving Schedule In Bulk finally
    setProgressData({ message: "Saving Schedule...", progress: 95 });
    setStatusMessage("Saving Schedule...");
    await saveCompleteScheduleChildrenandRefresh();


    setProgressData({ message: lang.process_completed_success_message, progress: 100 });
    setShowProgressBar({ progressBarPopup: true, okButton: true })

    setStatusMessage(lang.process_completed_success_message);

  }

  const FillFormatSequenceInSchedule = async (variationRes, isKartoonChannel) => {

    if (variationRes.success && variationRes.data && variationRes.data.length > 0) {

      // pull default format for this channel
      let defaultFormat = await SchedulingHelper.getDefaultFormat(payloadref.current.channelSID);

      const formatSchedules = await SchedulingHelper.getFormatByDateAndChannel(payloadref.current.channelSID, payloadref.current.BookingDate);

      // we can pull media library here which can be used.
      let completemediaLibrary = [];
      const mediaLibraryRes = await API.loadSchedulingCompleteMediaLibrary(payloadref.current);
      if(mediaLibraryRes.success){
        completemediaLibrary = mediaLibraryRes.data;
      }

      var allHeaders = schedulerDataRef.current;
      var stitchCurrentEpisode = false;
      var stitchedEpisodeindex = -1;

      for (let index = 0; index < allHeaders.length; index++) {
        const parent = allHeaders[index];

        // checking if slot specific format is configured
        const slotFormat = await SchedulingHelper.getValidSlotSpecificFormat(formatSchedules, parent.SlotDateTime);
        const formatToApply = slotFormat && slotFormat.FormatDetail ? slotFormat : defaultFormat;


        // finding segments and other children in header.
        const totalSegmentInParent = parent.children.filter(x => x.Type == SCHEDULETYPE.Segment);
        const childrenOtherThanSegment = parent.children.filter(x => x.Type != SCHEDULETYPE.Segment && x.Type != SCHEDULETYPE.UnderRun && x.Type != SCHEDULETYPE.OverRun);

        // skipping if header is not segmented yet or children already exists.
        //if (totalSegmentInParent.length == 0 || childrenOtherThanSegment.length > 0) continue;
        if (childrenOtherThanSegment.length > 0) continue;


        if (!stitchCurrentEpisode
          && (stitchedEpisodeindex == -1 || index != stitchedEpisodeindex)
          && parent.Duration <= 900000 && (allHeaders.length >= index + 1)
          && allHeaders[index + 1]?.Duration <= 900000
          && parent.mediaEpisode?.Season_id == allHeaders[index + 1]?.mediaEpisode?.Season_id) {
          stitchCurrentEpisode = true;
        }

        if (formatToApply && formatToApply.FormatDetail && formatToApply.FormatDetail.length > 0) {

          var isSegmentFound = false;
          var formatElementBreakNo = 0;
          for (let fi = 0; fi < formatToApply.FormatDetail.length; fi++) {
            const formatElement = formatToApply.FormatDetail[fi];


            // skipping segments from format
            if (formatElement.Type == SCHEDULETYPE.Segment) {
              isSegmentFound = true;
              formatElementBreakNo = formatElementBreakNo + 1;
              if (stitchCurrentEpisode) {

                // delete footer of this parent 
                const footer = await SchedulingHelper.findFooter(parent, schedulerDataRef.current);
                if (footer) await deleteFooter(footer);

                //break
                stitchCurrentEpisode = false;
                stitchedEpisodeindex = index + 1;
                break;

              }
              else continue;
            }

            if (index == stitchedEpisodeindex && !isSegmentFound) {
              continue;
            }

            // if format is for more break than episode we will break it. 
            if (formatElementBreakNo > totalSegmentInParent.length) break;

            const isLastHeader = (index == (allHeaders.length - 1));

            // if final header we will calculate space left by header duration
            var spaceLeft = await SchedulingHelper.findSpaceLeftInHeaderToPlace(parent, schedulerDataRef.current);
            if (!isLastHeader && spaceLeft == 0) break;

            // if last header, we cannot go beyond 24 hours
            console.log("parent index:" + index);
            if (isLastHeader && SchedulingHelper.IsPlaylist24HourReached(payloadref.current, schedulerDataRef.current)) break;

            const hasFooter = schedulerDataRef.current[index].children.some(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
            const lastChildIndex = schedulerDataRef.current[index].children.length - (hasFooter ? 2 : 1);


            const dst = fi == 0
              ? parent
              : ((fi - 1) > lastChildIndex ? schedulerDataRef.current[index].children[lastChildIndex] : schedulerDataRef.current[index].children[fi - 1]);

            const finalMediaToPlace = isKartoonChannel 
            ? await SchedulingHelper.findBestElementToPlaceInSchedule(formatElement, variationRes.data, totalSegmentInParent.length, spaceLeft, formatElementBreakNo, parent, schedulerDataRef.current, isLastHeader, dst)
            : await SchedulingHelper.findBestElementToPlaceInScheduleNew(formatElement, variationRes.data, totalSegmentInParent.length, spaceLeft, formatElementBreakNo, parent, schedulerDataRef.current, isLastHeader, dst, completemediaLibrary);
            if (finalMediaToPlace == undefined) break;


            const varianceType = formatElement.VariationType ?? FORMATVARIATIONTYPE.Fixed;
            const varianceSID = (varianceType == FORMATVARIATIONTYPE.FixedFamily) ? formatElement.mediaEpisode.Variance?.SID ?? 0 : 0;


            const dataToSave = SchedulingHelper.prepareData(
              ENTITYNAME.MediaEpisode,
              selectedChannel,
              [finalMediaToPlace],
              dst, // destination
              schedulerDataRef.current,
              true, // isNew
              true, // isAutoFill
              varianceSID
            );
            await addScheduleItems(dst, dataToSave, false);

          }
        }
      }
    }

  }

  const ManageUnderAndOverRuns = async (header_ids, placeAllItemsInLast = false) => {

    const variationRes = await SchedulingHelper.getVariationsData(payloadref.current);

    if (!variationRes.success || variationRes.data.length == 0) {
      toast.error("No Variations Found");
      return;
    }

    var data = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.ANY);

    if (!data || !data.MediaEpisodes || data.MediaEpisodes.length == 0) {
      toast.error("No Variations Found For Managing Under/Over Runs");
      return;
    }

    var availableVariations = data.MediaEpisodes;

    for (let index = 0; index < header_ids.length; index++) {
      const header_id = header_ids[index];

      let parent = schedulerDataRef.current.find(x => x._id.toString() == header_id.toString());
      if (!parent) {
        continue;
      }

      if (!parent.children.some(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun)) {
        continue;
      }

      var footerChild = parent.children.find(x => x.Type == SCHEDULETYPE.UnderRun);
      var footerDuration = footerChild && footerChild.Duration > 0 ? footerChild.Duration : 0;
      if (footerDuration == 0) return;

      var segments = parent.children.filter(x => x.Type == SCHEDULETYPE.Segment);

      var lastChild = parent.children.length > 1 ? parent.children[parent.children.length - 2] : undefined;
      var groupedItems = await SchedulingHelper.getInterstitialsForSameDurationOfFooter(footerDuration, availableVariations, lastChild);

      let placeOnSegIndex = 0;
      for (let gi = 0; gi < groupedItems.length; gi++) {
        const item = groupedItems[gi];

        const destination = placeAllItemsInLast ? footerChild : (segments.length > 0 ? segments[placeOnSegIndex] : footerChild);

        const dataToSave = SchedulingHelper.prepareData(
          ENTITYNAME.MediaEpisode,
          selectedChannel,
          [item],
          destination,
          schedulerDataRef.current,
          true, // isNew
          true // isAutoFill
        );
        await addScheduleItems(destination, dataToSave, true);

        placeOnSegIndex = (placeOnSegIndex + 1);
        if (placeOnSegIndex >= segments.length) placeOnSegIndex = 0;
      }

    }

    toast.success("Process Completed Successfully");

  }

  const AutoDeleteFooterUnderOffsetValue = async () => {

    const appconfig = await API.getEntitiesWithSearch(ENTITYNAME.ApplicationConfiguration);
    console.log(appconfig);

    if (appconfig.success && appconfig.data && appconfig.data.length > 0) {

      const offsetObj = appconfig.data.find(x => x.Key == "AutoDeleteFooterOffset");
      if ((offsetObj?.Value ?? 0) == 0) return;

      console.log("has offeset:" + offsetObj?.Value);

      var hasFooter = true;
      let loopCount = 0;
      while (hasFooter && loopCount <= schedulerDataRef.current.length) {

        loopCount++;
        var parent = schedulerDataRef.current.find(x => x.children.some(s => (s.Type == SCHEDULETYPE.UnderRun || s.Type == SCHEDULETYPE.OverRun) && s.Duration <= parseInt(offsetObj.Value)));

        if (parent && parent._id) {

          var footerChild = parent.children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
          if (footerChild) {
            await deleteFooter(footerChild);
          }

        }
        else {
          hasFooter = false;
        }
      }
    }
  }

  const PackPlaylistFor24Hours = async (variationRes) => {

    if (!variationRes.success || variationRes.data.length == 0) return;

    var data = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.ANY);
    const expectedEndTime = (payloadref.current.ScheduleEndDate + 1);
    const lastheaderChildren = schedulerDataRef.current[schedulerDataRef.current.length - 1].children;

    if (data && data.MediaEpisodes && data.MediaEpisodes.length > 0) {

      var availableVariations = data.MediaEpisodes;


      const currentEndTime = lastheaderChildren && lastheaderChildren.length > 0
        ? lastheaderChildren[lastheaderChildren.length - 1].SlotDateTime
        : schedulerDataRef.current[schedulerDataRef.current.length - 1].SlotDateTime;

      var difference = expectedEndTime > currentEndTime ? expectedEndTime - currentEndTime : 0;
      var lastChild = lastheaderChildren.length > 1 ? lastheaderChildren[lastheaderChildren.length - 2] : undefined;
      var groupedItems = await SchedulingHelper.getInterstitialsForSameDurationOfFooter(difference + 4, availableVariations, lastChild);

      console.log("difference" + difference);

      var dst = lastheaderChildren && lastheaderChildren.length > 0
        ? lastheaderChildren[lastheaderChildren.length - 1]
        : schedulerDataRef.current[schedulerDataRef.current.length - 1];

      for (let gi = 0; gi < groupedItems.length; gi++) {
        const item = groupedItems[gi];

        const dataToSave = SchedulingHelper.prepareData(
          ENTITYNAME.MediaEpisode,
          selectedChannel,
          [item],
          dst,
          schedulerDataRef.current,
          true, // isNew
          true // isAutoFill
        );
        await addScheduleItems(dst, dataToSave, true);
      }
    }

    // checking if any item over day
    var extraItems = schedulerDataRef.current[schedulerDataRef.current.length - 1].children.filter(x => x.SlotDateTime >= expectedEndTime);
    if (extraItems.length > 0) await deleteScheduleItems(extraItems, true);
  }

  const saveCompleteScheduleChildrenandRefresh = async () => {

    var finaldatatosave = [];
    schedulerDataRef.current.map(parent => {
      parent.children.map(child => {
        if (child?.Type != SCHEDULETYPE.UnderRun && child?.Type != SCHEDULETYPE.OverRun) {
          finaldatatosave.push(child);

          if (child[IS_BREAK] && child.children && child.children.length > 0) {

            child.children.map(breakchild => {
              if (breakchild?.Type != SCHEDULETYPE.UnderRun && breakchild?.Type != SCHEDULETYPE.OverRun) {
                finaldatatosave.push(breakchild);
              }
            })

          }
        }
      })
    });

    const saveRes = await API.saveSchedule({
      schedules: SchedulingHelper.prepareDataforbackend(finaldatatosave),
      deletedschedules: [],
    });

    // refresh schedule
    await loaddata(true);

    if (saveRes.success == false) {

      toast.error(saveRes.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

    }

  }

  const RefillAndDeleteUnderAndOverRun = async (variationRes, autoDeleteOffFooterOffset) => {

    if (!variationRes.success || variationRes.data.length == 0) return;
    var data = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.ANY);

    if (data && data.MediaEpisodes && data.MediaEpisodes.length > 0) {

      // under and over run both
      var allParents = schedulerDataRef.current;
      for (let index = 0; index < allParents.length; index++) {

        const parent = schedulerDataRef.current[index];

        // if no footer found, we will skip this header
        var footerChild = parent.children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
        var footerDuration = footerChild && footerChild.Duration > 0 ? footerChild.Duration : 0;
        if (footerDuration == 0) continue;


        if (footerChild.Type == SCHEDULETYPE.UnderRun) {
          var groupedvariationstoPlot = await SchedulingHelper.getVariation(footerDuration, data.MediaEpisodes, autoDeleteOffFooterOffset);

          for (let childindex = 0; childindex < groupedvariationstoPlot.length; childindex++) {
            const element = groupedvariationstoPlot[childindex];

            var bestChildPosition = await SchedulingHelper.getBestChildPositionToDrop(schedulerDataRef.current[index].children, element);
            if (bestChildPosition == undefined) continue;

            // pushing now in schedule
            const dataToSave = SchedulingHelper.prepareData(
              ENTITYNAME.MediaEpisode,
              selectedChannel,
              [element],
              bestChildPosition,
              schedulerDataRef.current,
              true, // isNew
              true // isAutoFill
            );
            await addScheduleItems(bestChildPosition, dataToSave, true);

          }

        }
        else { // over run

          // if found over run we can see what all we can delete to remove these and make it under offset range
          var tempFooterDuration = footerDuration;
          const killDuration = tempFooterDuration + autoDeleteOffFooterOffset;
          var tempLoop = 0;
          while (tempFooterDuration > autoDeleteOffFooterOffset && tempLoop <= parent.children.length) {

            tempLoop = tempLoop + 1;

            var itemWeCanDelete = schedulerDataRef.current[index].children.filter(x =>
              x.Type != SCHEDULETYPE.Segment &&
              x.Type != SCHEDULETYPE.UnderRun &&
              x.Type != SCHEDULETYPE.OverRun &&
              x.Duration < killDuration);

            if (itemWeCanDelete && itemWeCanDelete.length > 0) {

              // maximum duraition we will delete
              var sorted = itemWeCanDelete.sort((a, b) => a.Duration - b.Duration);
              await deleteScheduleItems([sorted[sorted.length - 1]], true);

              var footr = schedulerDataRef.current[index].children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
              if (footr) {
                tempFooterDuration = footr.Duration;
              }
            }
          }
        }


        // checking if the footer comes under duration offset range we weill delete it right away
        var pendingFooter = schedulerDataRef.current[index].children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
        if (pendingFooter && pendingFooter.Duration <= autoDeleteOffFooterOffset) {

          await deleteFooter(pendingFooter);
        }
      }
    }

  }

  const MaximumDamageControl = async (maxDamageOffset) => {

    // under and over run both
    var allParents = schedulerDataRef.current;
    for (let index = 0; index < allParents.length; index++) {

      const parent = schedulerDataRef.current[index];

      // if no footer found, we will skip this header
      var footerChild = parent.children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
      var footerDuration = footerChild && footerChild.Duration > 0 ? footerChild.Duration : 0;
      if (footerDuration == 0) continue;

      if (footerDuration <= maxDamageOffset) {

        await deleteFooter(footerChild);
      }
    }
  }

  const PlacingLastPositionItems = async (variationRes) => {

    if (!variationRes.success || variationRes.data.length == 0) return;
    var data = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.LAST);

    const fixedElements = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.FIXED);
    const hasFixedElements = fixedElements && fixedElements.MediaEpisodes != undefined && fixedElements.MediaEpisodes.length > 0;

    if (data && data.MediaEpisodes && data.MediaEpisodes.length > 0) {

      // under and over run both
      var allParents = schedulerDataRef.current;
      for (let index = 0; index < allParents.length; index++) {

        const hasFooter = schedulerDataRef.current[index].children.some(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
        const lastChildIndex = schedulerDataRef.current[index].children.length - (hasFooter ? 2 : 1);

        const lastChild = schedulerDataRef.current[index].children.length > 0 ? schedulerDataRef.current[index].children[lastChildIndex] : undefined;
        if (lastChild == undefined) continue;

        // if only segments than also skip
        const childrenOtherThanSegment = schedulerDataRef.current[index].children.filter(x => x.Type != SCHEDULETYPE.Segment && x.Type != SCHEDULETYPE.UnderRun && x.Type != SCHEDULETYPE.OverRun);
        if (childrenOtherThanSegment.length == 0) continue;

        // if last child is already one of the LAST items, we do not need to do anything.
        if (data.MediaEpisodes.some(x => x._id.toString() == lastChild.mediaEpisode._id.toString())) continue;

        // if Fixed Position Items Are Scheduled In Last It Should Not Place Again
        if (hasFixedElements && fixedElements.MediaEpisodes.some(x => x._id.toString() == lastChild.mediaEpisode._id.toString())) continue;

        // filtering items because we do no want back to back.
        const options = data.MediaEpisodes.filter(x => childrenOtherThanSegment.filter(child => child.mediaEpisode._id.toString() == x._id.toString()).length == 0);

        // otherwise find a random last item and place
        var itemToPlace = options[Math.floor(Math.random() * options.length)];

        // pushing now in schedule
        const dataToSave = SchedulingHelper.prepareData(
          ENTITYNAME.MediaEpisode,
          selectedChannel,
          [itemToPlace],
          lastChild,
          schedulerDataRef.current,
          true, // isNew
          true // isAutoFill
        );
        await addScheduleItems(lastChild, dataToSave, false);
      }
    }

  }

  const PlacingFirstPositionItem = async (variationRes) => {

    if (!variationRes.success || variationRes.data.length == 0) return;
    var data = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.OPENING);

    if (data && data.MediaEpisodes && data.MediaEpisodes.length > 0) {

      const firstChild = schedulerDataRef.current[0]?.children?.length > 0 ? schedulerDataRef.current[0]?.children[0] : undefined;
      if (firstChild == undefined) return;

      // if last child is already one of the LAST items, we do not need to do anything.
      if (data.MediaEpisodes.some(x => x._id.toString() == firstChild.mediaEpisode._id.toString())) return;

      // otherwise find a random last item and place
      var itemToPlace = data.MediaEpisodes[Math.floor(Math.random() * data.MediaEpisodes.length)];

      // pushing now in schedule
      const dataToSave = SchedulingHelper.prepareData(
        ENTITYNAME.MediaEpisode,
        selectedChannel,
        [itemToPlace],
        schedulerDataRef.current[0],
        schedulerDataRef.current,
        true, // isNew
        true // isAutoFill
      );
      console.log(dataToSave);
      await addScheduleItems(schedulerDataRef.current[0], dataToSave, true);
    }

  }

  const PlacingFixedPositionItems = async (variationRes) => {

    if (!variationRes.success || variationRes.data.length == 0) return;
    var data = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.FIXED);

    if (data == undefined || data.SlotDetail == undefined || data.SlotDetail.length == 0 || data.MediaEpisodes == undefined || data.MediaEpisodes.length == 0) return;

    // under and over run both
    for (let index = 0; index < data.MediaEpisodes.length; index++) {

      var itemToPlace = data.MediaEpisodes[index];

      // find slot time from slot details
      const slotDetail = data.SlotDetail.find(x => x.MediaEpisode_id.toString() == itemToPlace._id.toString());
      // slot not found
      if ((slotDetail?.Slot ?? 0) == 0) continue;

      // creating range as per offset
      const headerOffsetStart = (payloadref.current.BookingDate + slotDetail?.Slot) - (1000 * 60 * 10); // 10 min offset -
      const headerOffsetEnd = (payloadref.current.BookingDate + slotDetail?.Slot) + (1000 * 60 * 10); // 10 min offset +

      // finding header
      var headerAsPerSlot = schedulerDataRef.current.find(h => h.SlotDateTime >= headerOffsetStart && h.SlotDateTime <= headerOffsetEnd);

      // if header not found
      if (headerAsPerSlot == undefined || headerAsPerSlot._id == undefined) continue;

      const headerIndex = schedulerDataRef.current.indexOf(headerAsPerSlot);

      // as we need to apply on last of previous header
      if (headerIndex < 1) continue;

      const hasFooter = schedulerDataRef.current[headerIndex - 1].children.some(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
      const lastChildIndex = schedulerDataRef.current[headerIndex - 1].children.length - (hasFooter ? 2 : 1);

      const lastChild = schedulerDataRef.current[headerIndex - 1].children.length > 0 ? schedulerDataRef.current[headerIndex - 1].children[lastChildIndex] : undefined;
      if (lastChild == undefined) continue;

      // if last child is already one of the FIXED items, we do not need to do anything.
      if (itemToPlace._id.toString() == lastChild.mediaEpisode._id.toString()) continue;

      // pushing now in schedule
      const dataToSave = SchedulingHelper.prepareData(
        ENTITYNAME.MediaEpisode,
        selectedChannel,
        [itemToPlace],
        lastChild,
        schedulerDataRef.current,
        true, // isNew
        true // isAutoFill
      );
      await addScheduleItems(lastChild, dataToSave, false);
    }

  }

  const ReplaceItemsToMatchUnderAndOverRuns = async (variationRes, autoDeleteOffFooterOffset, realTimeSave = true) => {

    //reserving items to delete
    var itemsToDelete = [];

    if (!variationRes.success || variationRes.data.length == 0) return;
    // later on this will be merged by opening and closing both...
    var data = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.CLOSING);

    if (data && data.MediaEpisodes && data.MediaEpisodes.length > 0) {

      // under and over run both
      var allParents = schedulerDataRef.current;
      for (let index = 0; index < allParents.length; index++) {

        const parent = schedulerDataRef.current[index];
        console.log("PARENT INDEX:" + index);

        // if no footer found, we will skip this header
        var footerChild = parent.children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
        var footerDuration = footerChild && footerChild.Duration > 0 ? footerChild.Duration : 0;
        if (footerDuration == 0) continue;

        var tempFooterDuration = footerDuration;

        if (footerChild.Type == SCHEDULETYPE.UnderRun) {
          console.log("REFILL PLACE..UNDER RUN");

          // we will check first if exact match found
          var bestFitForAnyChild = await SchedulingHelper.getReplaceOptionForAnyChildren(tempFooterDuration, data.MediaEpisodes, true, schedulerDataRef.current[index].children, variationRes);
          if (bestFitForAnyChild && bestFitForAnyChild.destination && bestFitForAnyChild.replacement) {

            const varianceSID = bestFitForAnyChild.destination.varianceSID ?? 0;

            // pushing now in schedule
            const dataToSave = SchedulingHelper.prepareData(
              ENTITYNAME.MediaEpisode,
              selectedChannel,
              [bestFitForAnyChild.replacement],
              bestFitForAnyChild.destination,
              schedulerDataRef.current,
              true, // isNew
              true, // isAutoFill
              varianceSID
            );
            await addScheduleItems(bestFitForAnyChild.destination, dataToSave, realTimeSave);
            itemsToDelete.push(bestFitForAnyChild.destination)
            await deleteScheduleItems([bestFitForAnyChild.destination], realTimeSave);

          }
          else {

            // here we are going child by child
            for (let childindex = 0; childindex < parent.children.length; childindex++) {

              if (tempFooterDuration <= 0) break;

              const child = schedulerDataRef.current[index].children[childindex];
              if (child.Type == SCHEDULETYPE.Segment || child.Type == SCHEDULETYPE.UnderRun || child.Type == SCHEDULETYPE.OverRun) continue;

              // if under run we need to find bigger item than current item
              var bestFit = await SchedulingHelper.getReplaceOption(child, tempFooterDuration, data.MediaEpisodes, true, autoDeleteOffFooterOffset, variationRes);
              if (bestFit == undefined) continue;

              console.log("REFILL PLACE UNDER RUN..FOUND POSITION");

              const varianceSID = child.varianceSID ?? 0;

              // pushing now in schedule
              const dataToSave = SchedulingHelper.prepareData(
                ENTITYNAME.MediaEpisode,
                selectedChannel,
                [bestFit],
                child,
                schedulerDataRef.current,
                true, // isNew
                true, // isAutoFill
                varianceSID
              );
              await addScheduleItems(child, dataToSave, realTimeSave);
              itemsToDelete.push(child);
              await deleteScheduleItems([child], realTimeSave);

              tempFooterDuration = tempFooterDuration - bestFit.Duration;

            }

          }

        }
        else { // over run

          console.log("REFILL PLACE..OVER RUN");

          // we will check first if exact match found
          var bestFitForAnyChild = await SchedulingHelper.getReplaceOptionForAnyChildren(tempFooterDuration, data.MediaEpisodes, false, schedulerDataRef.current[index].children, variationRes);
          if (bestFitForAnyChild && bestFitForAnyChild.destination && bestFitForAnyChild.replacement) {

            const varianceSID = bestFitForAnyChild.destination.varianceSID ?? 0;

            // pushing now in schedule
            const dataToSave = SchedulingHelper.prepareData(
              ENTITYNAME.MediaEpisode,
              selectedChannel,
              [bestFitForAnyChild.replacement],
              bestFitForAnyChild.destination,
              schedulerDataRef.current,
              true, // isNew
              true, // isAutoFill
              varianceSID
            );
            await addScheduleItems(bestFitForAnyChild.destination, dataToSave, realTimeSave);
            itemsToDelete.push(bestFitForAnyChild.destination)
            await deleteScheduleItems([bestFitForAnyChild.destination], realTimeSave);

          }
          else {

            // here we are going child by child
            for (let childindex = 0; childindex < parent.children.length; childindex++) {

              if (tempFooterDuration <= 0) break;

              const child = schedulerDataRef.current[index].children[childindex];
              if (child.Type == SCHEDULETYPE.Segment || child.Type == SCHEDULETYPE.UnderRun || child.Type == SCHEDULETYPE.OverRun) continue;

              // if under run we need to find bigger item than current item
              var bestFit = await SchedulingHelper.getReplaceOption(child, tempFooterDuration, data.MediaEpisodes, false, autoDeleteOffFooterOffset, variationRes);
              if (bestFit == undefined) continue;

              console.log("REFILL PLACE OVER RUN..FOUND POSITION");

              const varianceSID = child.varianceSID ?? 0;

              // pushing now in schedule
              const dataToSave = SchedulingHelper.prepareData(
                ENTITYNAME.MediaEpisode,
                selectedChannel,
                [bestFit],
                child,
                schedulerDataRef.current,
                true, // isNew
                true, // isAutoFill
                varianceSID
              );

              await addScheduleItems(child, dataToSave, realTimeSave);
              itemsToDelete.push(child)
              await deleteScheduleItems([child], realTimeSave);

              tempFooterDuration = tempFooterDuration - bestFit.Duration;

            }
          }
        }


        console.log("RE PLACE SCHEDULE..CHECKING PENDING FOOTER");
        //checking if the footer comes under duration offset range we weill delete it right away
        var pendingFooter = schedulerDataRef.current[index].children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
        if (pendingFooter && pendingFooter.Duration <= autoDeleteOffFooterOffset) {
          console.log({ "RE PLACE SCHEDULE..PENDING FOOTER": pendingFooter });
          await deleteFooter(pendingFooter);
        }

      }

      if (!realTimeSave && itemsToDelete.length > 0) {
        await saveSchedule({ deletedSchedules: itemsToDelete });
        await saveCompleteScheduleChildrenandRefresh();
      }
    }
  }

  const ReplaceItemsToMatchUnderAndOverRunsByBlockExactMatchOnly = async (variationRes, autoDeleteOffFooterOffset, realTimeSave = true) => {

    //reserving items to delete
    var itemsToDelete = [];

    if (!variationRes.success || variationRes.data.length == 0) return;
    // later on this will be merged by opening and closing both...
    var data = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.CLOSING);

    if (data && data.MediaEpisodes && data.MediaEpisodes.length > 0) {

      // under and over run both
      var allParents = schedulerDataRef.current;
      for (let index = 0; index < allParents.length; index++) {

        const parent = schedulerDataRef.current[index];
        console.log("PARENT INDEX:" + index);

        // if no footer found, we will skip this header
        var footerChild = parent.children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
        var footerDuration = footerChild && footerChild.Duration > 0 ? footerChild.Duration : 0;
        if (footerDuration == 0) continue;

        var tempFooterDuration = footerDuration;

        if (footerChild.Type == SCHEDULETYPE.UnderRun) {
          console.log("REFILL PLACE..UNDER RUN");


          // we will check first if exact match found
          var bestFitForAnyChild = await SchedulingHelper.getReplaceOptionForAnyChildren(tempFooterDuration, data.MediaEpisodes, true, schedulerDataRef.current[index].children, variationRes);
          if (bestFitForAnyChild && bestFitForAnyChild.destination && bestFitForAnyChild.replacement) {

            const varianceSID = bestFitForAnyChild.destination.varianceSID ?? 0;

            // pushing now in schedule
            const dataToSave = SchedulingHelper.prepareData(
              ENTITYNAME.MediaEpisode,
              selectedChannel,
              [bestFitForAnyChild.replacement],
              bestFitForAnyChild.destination,
              schedulerDataRef.current,
              true, // isNew
              true, // isAutoFill
              varianceSID
            );

            await addScheduleItems(bestFitForAnyChild.destination, dataToSave, realTimeSave);
            itemsToDelete.push(bestFitForAnyChild.destination);
            await deleteScheduleItems([bestFitForAnyChild.destination], realTimeSave);

          }

        }
        else { // over run

          console.log("REFILL PLACE..OVER RUN");

          // we will check first if exact match found
          var bestFitForAnyChild = await SchedulingHelper.getReplaceOptionForAnyChildren(tempFooterDuration, data.MediaEpisodes, false, schedulerDataRef.current[index].children, variationRes);
          if (bestFitForAnyChild && bestFitForAnyChild.destination && bestFitForAnyChild.replacement) {

            const varianceSID = bestFitForAnyChild.destination.varianceSID ?? 0;

            // pushing now in schedule
            const dataToSave = SchedulingHelper.prepareData(
              ENTITYNAME.MediaEpisode,
              selectedChannel,
              [bestFitForAnyChild.replacement],
              bestFitForAnyChild.destination,
              schedulerDataRef.current,
              true, // isNew
              true, // isAutoFill
              varianceSID
            );

            await addScheduleItems(bestFitForAnyChild.destination, dataToSave, realTimeSave);
            itemsToDelete.push(bestFitForAnyChild.destination);
            await deleteScheduleItems([bestFitForAnyChild.destination], realTimeSave);

          }
        }


        console.log("RE PLACE SCHEDULE..CHECKING PENDING FOOTER");
        //checking if the footer comes under duration offset range we weill delete it right away
        var pendingFooter = schedulerDataRef.current[index].children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
        if (pendingFooter && pendingFooter.Duration <= autoDeleteOffFooterOffset) {
          console.log({ "RE PLACE SCHEDULE..PENDING FOOTER": pendingFooter });
          await deleteFooter(pendingFooter);
        }

      }

      // if not realtime save and itemsto delete
      if (!realTimeSave && itemsToDelete.length > 0) {
        await saveSchedule({ deletedSchedules: itemsToDelete });
        await saveCompleteScheduleChildrenandRefresh();
      }
    }
  }

  const SequenceVarianceInSchedule = async (variationRes, mediaCategoryTypeToSequence, realTimeSave = true) => {

    //reserving items to delete
    var itemsToDelete = [];

    if (!variationRes.success || variationRes.data.length == 0) return;
    // later on this will be merged by opening and closing both...
    var data = variationRes.data.find(x => x.MediaType.SID == MEDIATYPEENUM.CLOSING);

    if (data == undefined || data.MediaEpisodes == undefined || data.MediaEpisodes.length == 0) return;

    var validVariance = data.MediaEpisodes.filter(x => (x.Variance?.SID ?? 0) > 0 && x.MediaCategoryType?.SID == mediaCategoryTypeToSequence);
    var uniQueVariance = [...new Set(validVariance.map(item => item.Variance.SID))];
    uniQueVariance.sort(function (a, b) {
      return a - b;
    });

    var nextPlacementVarianceIndex = 0;

    // under and over run both
    var allParents = schedulerDataRef.current;
    for (let index = 0; index < allParents.length; index++) {

      const parent = schedulerDataRef.current[index];

      for (let childindex = 0; childindex < parent.children.length; childindex++) {
        const child = parent.children[childindex];
        if (child.mediaEpisode?.MediaCategoryType?.SID != mediaCategoryTypeToSequence) continue;


        const bestFit = validVariance.find(x => x.Variance.SID == uniQueVariance[nextPlacementVarianceIndex] && x.Duration == child.mediaEpisode?.Duration);
        if (bestFit == undefined) continue;

        const varianceSID = uniQueVariance[nextPlacementVarianceIndex];

        // pushing now in schedule
        const dataToSave = SchedulingHelper.prepareData(
          ENTITYNAME.MediaEpisode,
          selectedChannel,
          [bestFit],
          child,
          schedulerDataRef.current,
          true, // isNew
          true, // isAutoFill
          varianceSID
        );

        await addScheduleItems(child, dataToSave, realTimeSave);
        itemsToDelete.push(child);
        await deleteScheduleItems([child], realTimeSave);

        // resetting index
        nextPlacementVarianceIndex = nextPlacementVarianceIndex + 1;
        if (nextPlacementVarianceIndex >= uniQueVariance.length) nextPlacementVarianceIndex = 0;

      }
    }

    // if not realtime save and itemsto delete
    if (!realTimeSave && itemsToDelete.length > 0) {
      await saveSchedule({ deletedSchedules: itemsToDelete });
      await saveCompleteScheduleChildrenandRefresh();
    }
  }

  const onSchedulerChildrenFilter = () => {
    var newSchedulerData = [];
    var breakItems = [];
    var dataItem = schedulerChildrenFilterRef.current.dataItem;
    var field = schedulerChildrenFilterRef.current.field;
    var field2 = schedulerChildrenFilterRef.current.field;
    var field3 = schedulerChildrenFilterRef.current.field;
    console.log(schedulerChildrenFilterRef.current);
    var dotIndex = field.indexOf(".");
    if (dotIndex != -1) {
      field = field.slice(null, dotIndex);
      field2 = field2.slice(dotIndex + 1);
      dotIndex = field2.indexOf(".");
      if (dotIndex != -1) {
        field2 = field2.slice(null, dotIndex);
        dotIndex = field3.lastIndexOf(".");
        field3 = field3.slice(dotIndex + 1);
        dataItem = dataItem?.[field]?.[field2]?.[field3];
        schedulerDataRef.current.map((parent) => {
          var newData = parent.children.filter(
            (child) => {
              if (child[IS_BREAK]) {
                breakItems = child.children.filter((child) => child?.[field]?.[field2]?.[field3] == dataItem);
              }
              return child?.[field]?.[field2]?.[field3] == dataItem;
            }
          );
          newSchedulerData.push(...newData);
        });
      } else {
        dataItem = dataItem?.[field]?.[field2];
        schedulerDataRef.current.map((parent) => {
          var newData = parent.children.filter(
            (child) => {
              if (child[IS_BREAK]) {
                breakItems = child.children.filter((child) => child?.[field]?.[field2] == dataItem);
              }
              return child?.[field]?.[field2] == dataItem;
            }
          );
          newSchedulerData.push(...newData);
        });
      }
    } else {
      dataItem = dataItem?.[field];
      schedulerDataRef.current.map((parent) => {
        var newData = parent.children.filter(
          (child) => {
            if (child[IS_BREAK]) {
              breakItems = child.children.filter((child) => child[field] == dataItem);
            }
            return child[field] == dataItem;
          }
        );
        newSchedulerData.push(...newData);
      });
    }
    setShowFilterGridData([...newSchedulerData, ...breakItems]);
    setShowFilterGrid(true);
  };

  const onStyleSheetSelection = () => {

    console.log(selectedHeadersDataItemsRef.current);
    if (selectedHeadersDataItemsRef.current.length != 0) {
      styleSheetSelectionOpen
        ? setStyleSheetSelectionOpen(false)
        : setStyleSheetSelectionOpen(true);
    } else {
      toast.error(lang.select_atleast_one_header_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setStyleSheetSelectionOpen(false)
    }
  };

  const onSearchLocal = (e) => {
    const searchWord = e.target.value.toLowerCase();
    filterSearchWordRef.current = searchWord;
    if (searchWord.length == 0) {
      setShowFilterGrid(false);
      setShowFilterGridData([]);
    } else {
      setShowFilterGrid(true);
      var newFilterGridData = [];
      schedulerDataRef.current.map(parent => {
        parent.children.map(child => {
          if (child?.mediaEpisode?.Title?.toLowerCase().includes(searchWord) ||
            child?.MediaId?.toLowerCase().includes(searchWord) ||
            child?.mediaEpisode?.AssetId?.toLowerCase().includes(searchWord) ||
            child?.mediaEpisode?.MediaCategory?.Description?.toLowerCase().includes(searchWord) ||
            child?.mediaEpisode?.MediaCategoryType?.Description?.toLowerCase().includes(searchWord) ||
            Object.keys(SCHEDULETYPE).some(key => key.toLowerCase().includes(searchWord) && SCHEDULETYPE[key] == child?.Type)) {

            newFilterGridData.push(child);

          }
        })
      });

      if (newFilterGridData.length > 0) {
        setShowFilterGridData(newFilterGridData);
      } else {
        setShowFilterGridData([]);
      }

      // sometimes search results were not visible and on scroll it works
      // so by default did scroll to top if user search something By Vishal 11 May 24
      if (gridRef.current) {
        gridRef.current.scrollIntoView({
          rowIndex: 0,
        });
      }

    }
  }

  const saveSecondaryChangedData = async (prototypeOverviewData, scheduleItem) => {

    const { level_0_index, level_1_index, level_2_index, isInsideBreak } = SchedulingHelper.getChildInfo(scheduleItem, schedulerDataRef.current);
    let schedulerDataLocal = schedulerDataRef.current;
    let saveData = {};

    if (isInsideBreak) {
      schedulerDataLocal[level_0_index].children[level_1_index].children[level_2_index].Prototypes = prototypeOverviewData
      saveData = schedulerDataLocal[level_0_index].children[level_1_index].children[level_2_index]
    }
    else {
      schedulerDataLocal[level_0_index].children[level_1_index].Prototypes = prototypeOverviewData
      saveData = schedulerDataLocal[level_0_index].children[level_1_index];
    }

    selectedStateRefSetter([saveData]);
    schedulerDataRefSetter(schedulerDataLocal);

    saveData = SchedulingHelper.prepareDataforbackend([saveData])[0];
    console.log(saveData);
    var res = await API.saveData(ENTITYNAME.ProgramSchedule, saveData);

    console.log(res);
    if (res.success) {
      setStatusMessage(lang.secondary_event_updated_msg_scheduling);
      toast.success(`${lang.secondary_event_updated_msg_scheduling}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setShowSecondaryEventOverviewDialog(false);
  }

  const showSecondaryEventOverviewDialogProp = () => {

    try {

      if (selectedStateRef.current.length > 0 && selectedStateRef.current[0].Type == SCHEDULETYPE.Header) {
        toast.error(`${lang.no_secondary_events_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

      if (selectedStateRef.current.length > 0 && selectedStateRef.current[0].Prototypes.length == 0) {
        toast.error(`${lang.no_secondary_events_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

      setShowSecondaryEventOverviewDialog(true)
    } catch (e) {
      console.log(e);
      toast.error(`${lang.no_secondary_events_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  // auto save toggle changes
  const onHeaderExpandChange = (e) => {
    // testing expand all and collapse all header
    var parentData = schedulerDataRef.current.filter(data => {
      if (!data.ParentProgramSchedule_id) {
        return data;
      }
    });

    for (var i = 0; i < parentData.length; i++) {
      //dataitem, vakue, dataIndex
      var event = { dataItem: parentData[i], dataIndex: i, value: e.target.value };
      expandChange(event);
    }

    props.setExpandToggle(e.target.value);

  }

  const handleSaveScheduleDraftPopup = () => {
    setShowSaveScheduleDraftPopup(!showSaveScheduleDraftPopup)
  }

  //
  const onAutoPlotPromoInSchedule = (selectedPromos, fillSelected) => {

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }
    if (fillSelected && selectedPromos.length == 0) {
      toast.error(`Please select atleast one interstitial`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if (fillSelected && selectedPromos.some((x) => x.MediaCategory.SID != MEDIACATEGORIES.Interstitial)) {
      toast.error(`Please select interstitials only`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    executeAutoPlotPromos(selectedPromos, fillSelected);

    
  }

  const executeAutoPlotPromos = async (selectedPromos, fillSelected) => {

    var finalPromos = selectedPromos;

    if (!fillSelected) {
      const res = await API.loadSchedulingCompleteMediaLibrary(payloadref.current);

      if(res.success && res.data && res.data.length > 0){
        finalPromos = res.data.filter((x) => x.MediaCategory.SID == MEDIACATEGORIES.Interstitial 
        && x.Targets && x.Targets.length > 0 
        && x.Targets.filter(xt => xt.FromDate <= payloadref.current?.ScheduleDate && xt.ToDate >= payloadref.current?.ScheduleDate
        && (xt.DayPart.IsAllChannel == true || xt.DayPart.Channels.filter(c => c._id == selectedChannel?._id?.toString()).length > 0)).length > 0
      );
      }
    }


    setShowAutoPlotPromo(true);
    var promoData = finalPromos.map((x) => {
      return getSelectedPromoWithTargetPreFilled(x);
    })
    setSelectedPromo(promoData);
    
  }

  const getSelectedPromoWithTargetPreFilled = (selectedPromo) => {

    console.log(selectedPromo.Targets);
    console.log(selectedChannel);
    console.log(payloadref.current?.ScheduleDate);

    const validTarget = selectedPromo.Targets.find(x => 
      x.FromDate <= payloadref.current?.ScheduleDate 
      && x.ToDate >= payloadref.current?.ScheduleDate
      && (x.DayPart.IsAllChannel == true || x.DayPart.Channels.filter(x => x._id == selectedChannel?._id?.toString()).length > 0)
    );

    console.log(validTarget);

    return {
      ...selectedPromo,
      Name: selectedPromo.Title,
      From: validTarget ? utility.convertMilisecondsToShortTimeString(validTarget.DayPart.FromTime) : '00:00',
      To: validTarget ? utility.convertMilisecondsToShortTimeString(validTarget.DayPart.ToTime) : '23:59',
      Weightage: validTarget ? validTarget.Weightage : 100,
      PlayCount: validTarget ? validTarget.PlayCount : 0,
      PlayViaPlayCount: validTarget ? validTarget.PlayViaPlayCount : false,
      DayPart: validTarget ? validTarget.DayPart : {}
    }
  }


  const autoPlotPromoSchedule = async () => {
    setShowAutoPlotPromo(false);
    var totalSegments = await SchedulingHelper.getValidAutoPlotPromoBreaks(schedulerDataRef.current);
    if (totalSegments == undefined || totalSegments.length == 0) {

      toast.info("No Breaks Found.. Process Aborted.", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    console.log(totalSegments.length);

    setShowProgressBar({ okButton: false, progressBarPopup: true });
    // Setting Schedule Constraints
    var enabledScheduleConstraint = [];
    const scheduleConstraintsRes = await API.getData(ENTITYNAME.ScheduleConstraints, {
      query: [["Channel._id", "", selectedChannel._id.toString()], ["Type.ID", "=", MEDIA_TYPE[0].ID], ["Archive", "=", false]]
    });
    if (scheduleConstraintsRes.success && scheduleConstraintsRes.data.length > 0) {
      enabledScheduleConstraint = scheduleConstraintsRes.data[0].EnabledConstraints;
    }
    console.log(enabledScheduleConstraint);
    var totalPromoPlotted = 0;
    for (let j = 0; j < selectedPromoRef.current.length; j++) {
      const promo = selectedPromoRef.current[j];

      const promoValidFrom = payloadref.current?.ScheduleDate + utility.convertShortTimeStringToMilliSeconds(promo.From);
      const promoValidTo = payloadref.current?.ScheduleDate + utility.convertShortTimeStringToMilliSeconds(promo.To);

      var segmentChildrens = totalSegments.filter(x => x.SlotDateTime >= promoValidFrom && x.SlotDateTime <= promoValidTo);

      if(segmentChildrens.length == 0){
        continue;
      }

      setProgressData({
        message: selectedPromoRef.current.length - totalPromoPlotted + ' interstitial left',
        progress: Math.ceil((100 * totalPromoPlotted) / selectedPromoRef.current.length)
      });
    

      const isPlayViaPlayCount = promo.PlayViaPlayCount;
      var count = promo.PlayCount;
      var tempCount = 0;
      var weightage = promo.Weightage;
      if (isPlayViaPlayCount && count == 0) continue;
      if (!isPlayViaPlayCount && weightage == 0) continue;
      var newWeightage = isPlayViaPlayCount ? (count / segmentChildrens.length) * 100 : weightage;
      totalPromoPlotted = totalPromoPlotted + 1;
      for (let i = 0; i < segmentChildrens.length; i++) {

        if (isPlayViaPlayCount && count == tempCount) {
          break;
        }

        let { level_0_index, level_1_index } = SchedulingHelper.getChildInfo(segmentChildrens[i], schedulerDataRef.current)

        // for non last headers
        const isLastHeader = (level_0_index == (schedulerDataRef.current.length - 1));

        var canFill = await SchedulingHelper.isSpaceLeftAsPerCompanyRulesInAutoFill(isLastHeader, schedulerDataRef.current, level_0_index, promo, payloadref.current);
        if (!canFill) continue;

        var destinationDataItem = await SchedulingHelper.getDestinationForAutoPlotPromo(schedulerDataRef.current, level_0_index, segmentChildrens[i]);
        if (destinationDataItem == undefined) continue;

        const isValid = await SchedulingHelper.isValidDestinationForInterstitial(destinationDataItem, enabledScheduleConstraint, promo, schedulerDataRef);

        if (!isValid && i == segmentChildrens.length - 1 && isPlayViaPlayCount && count != tempCount) {
          i = 0;
        }

        if (isValid) {
          if (SchedulingHelper.IsPromoBreakValidAccWeightage(i + 1, newWeightage)) {
            //IMPLEMENT WEIGHTAGE AND PLAY COUNT
            const dataToSave = SchedulingHelper.prepareData(
              ENTITYNAME.MediaEpisode,
              selectedChannel,
              [promo],
              destinationDataItem,
              schedulerDataRef.current
            );
            // adding in schedule            
            await addScheduleItems(destinationDataItem, dataToSave, false);
            tempCount = tempCount + 1;
          }
        }
      }
    }

    toast.success(totalPromoPlotted + " Interstitial Plotted.", {
      position: toast.POSITION.TOP_RIGHT,
    });

    await saveCompleteScheduleChildrenandRefresh();

    setProgressData({ message: 'Process Completed !!', progress: 100 });
    setShowProgressBar({ progressBarPopup: true, okButton: true })
  }

  const expandMinimizeAllBreaks = (expand = true) => {
    var newSchedulerData = schedulerDataRef.current;
    newSchedulerData.map(parent => {
      if (selectedHeader.current._id == parent._id) {
        return parent.children.map(child => {
          if (child[IS_BREAK]) {
            child.expanded = expand;
          }
        })
      }
      else {
        return parent;
      }
    });
    schedulerDataRefSetter(newSchedulerData);
  }

  const onChangeBreakDataItemPopup = async (e) => {
    if (e.target.name == 'MediaCategory') {
      var mediaCategoryTypeTemp = mediaCategoryType.filter((x) => x.MediaCategorySID == e.target.value.SID);
      setFilterMediaCategoryType(mediaCategoryTypeTemp);
      setBreakdataItemForPopup({ ...breakdataItemForPopup, [e.target.name]: e.target.value, MediaCategoryType: {} });
    } else if (e.target.name == 'MediaCategoryType') {
      setBreakdataItemForPopup({ ...breakdataItemForPopup, Content: {}, [e.target.name]: e.target.value });
    } else {
      setBreakdataItemForPopup({ ...breakdataItemForPopup, [e.target.name]: e.target.value });
    }
  };

  const onCancelVirtualSegmentationPopup = () => {
    setShowVirtualSegmentation(false);
    setVirtualSegmentationData({
      duration: "00:00:00:00",
      noOfSeg: 2,
      diffDur: "00:00:00:00",
      totalDur: "00:00:00:00",
      gridData: []
    });
  }

  const onUpdateTxStatus = async () => {

    // this validation is applied in planning and scheduling, please make sure you change at both places for any code change
    if (txStatusRef.current.SID == TX_STATUS.Live || txStatusRef.current.SID == TX_STATUS.SDD) {
      var response = await API.canChangeTxStatusToLiveOrDalayed(selectedState[0].SlotDateTime, selectedState[0].MediaEpisode_id);
      console.log(response);

      if (!response.success) {
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
        return;
      }
    }

    var updateRes = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: selectedState[0]._id,
      TXStatus: { SID: txStatusRef.current.SID, Description: txStatusRef.current.Description }
    });

    setUpdateTxStatusPopupOpen(false)


    if (updateRes.success) {

      //LOCAL DATA SET
      let dataLocal = [...schedulerDataRef.current];
      let index = dataLocal.findIndex((x) => x._id == selectedState[0]._id);
      dataLocal.splice(index, 1, { ...selectedState[0], TXStatus: { SID: txStatusRef.current.SID, Description: txStatusRef.current.Description } })
      schedulerDataRefSetter(dataLocal)

      var updatedData = { ...selectedState[0], OldTxStatus: selectedState[0].TXStatus, UpdatedTxStatus: { SID: txStatusRef.current.SID, Description: txStatusRef.current.Description } };
      let logData = { event: LOGEVENT.UPDATE_PLANNING_TX_STATUS, module: MODULE.SCHEDULING, data: updatedData, message: LOGEVENT.UPDATE_PLANNING_TX_STATUS };
      API.SaveProgramScheduleHeaderLogs(logData);
    }

  }

  const onUpdateProgramScheduleComment = async () => {

    var updateRes = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: selectedState[0]._id,
      Comment: commentRef.current
    });

    setUpdateCommentPopupOpen(false)

    if (updateRes.success) {

      //LOCAL DATA SET
      let dataLocal = [...schedulerDataRef.current];
      let { level_0_index, level_1_index } = SchedulingHelper.getChildInfo(selectedStateRef.current[0], schedulerDataRef.current);

      // header
      if (level_1_index == null) {
        dataLocal.splice(level_0_index, 1, { ...selectedState[0], Comment: comment });
      }
      else {
        dataLocal[level_0_index].children.splice(level_1_index, 1, { ...selectedState[0], Comment: comment });
      }

      schedulerDataRefSetter(dataLocal)


      var updatedData = { ...selectedState[0], OldComment: selectedState[0].Comment ?? "", UpdatedComment: commentRef.current };
      let logData = { event: LOGEVENT.UPDATE_PLANNING_TX_STATUS, module: MODULE.SCHEDULING, data: updatedData, message: LOGEVENT.UPDATE_PLANNING_TX_STATUS };
      API.SaveProgramScheduleHeaderLogs(logData);
    }

  }

  const loadSavedFastSegments = () => {
    console.log('here test');
    loaddata(true);
  }
  // new code for tree  START
  const onHeaderDrop = (e, props) => {

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }
    //HERE we need to prepare the data
    const dataTransfer = JSON.parse(e.dataTransfer.getData("text"));
    const draggedData = dataTransfer.propsData;
    const src = dataTransfer.src;
    const destinationDataItem = props.dataItem;
    const isInternal =
      src == ENTITYNAME.ProgramSchedule || src == ENTITYNAME.BREAK_CHILDREN;
    if (isInternal && draggedData.some(d => d._id == destinationDataItem._id)) {
      return;
    }

    if ((destinationDataItem.Type == SCHEDULETYPE.OverRun ||
      destinationDataItem.Type == SCHEDULETYPE.UnderRun) && isInternal) {
      toast.info(`Cannot drop on OverRun or UnderRun`);
      return;
    }
    if (src == ENTITYNAME.Booking) {

      if (!SchedulingHelper.IsValidBookingDrop(destinationDataItem, draggedData, offsetTime)) {
        toast.error(`Some Dragged Booked Spots Are Not In Valid Time Range `, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (!SchedulingHelper.IsValidBookingDropPosition(destinationDataItem, draggedData, schedulerDataRef.current)) {
        toast.error(`Some Dragged Booked Spots Are Not Valid For This Destination Position `, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      //CHECK CLIENT SPOT SEPERATION
      var invalidClientSpotSeparationData = SchedulingHelper.IsValidBookingDropClientSpot(destinationDataItem, draggedData, schedulerDataRef.current);
      if (!invalidClientSpotSeparationData.IsValid) {
        toast.error(`${invalidClientSpotSeparationData.Message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (destinationDataItem.Type == SCHEDULETYPE.OverRun ||
        destinationDataItem.Type == SCHEDULETYPE.UnderRun) {
        toast.info("Cannot drop on OverRun or UnderRun");
        return;
      }

      SalesHelper.saveBookingLogFromSchedule(draggedData, destinationDataItem);

      deleteUsedBooking(draggedData);
    }

    if (src == ENTITYNAME.Prototype) {
      toast.info(`Cannot drop Prototypes on Header`);
      return;
    }

    if (src == ENTITYNAME.MediaEpisode && draggedData.some(x => x[IS_BREAK])) {
      toast.error(`${lang.break_type_item_is_not_allowed_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!SchedulingHelper.IsValidDropDestinationSlot(destinationDataItem, draggedData, offsetTime, selectedDateRef.current, isInternal, selectedChannel)) {
      toast.error(`Some dragged items are not in valid Publishing Time Range `, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    //CHECK CLIENT SPOT SEPERATION
    // if (isInternal && !SchedulingHelper.IsValidBookingDropClientSpot(destinationDataItem, draggedData, schedulerDataRef.current,isInternal)) {
    //   toast.error(Some Dragged Booked Spots Are Not under Valid Client Spot Seperation, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }

    if (isInternal) deleteScheduleItems(draggedData, false, true); //then it need to be delete first
    const dataToSave = SchedulingHelper.prepareData(
      src,
      selectedChannel,
      draggedData,
      destinationDataItem,
      schedulerDataRef.current
    );
    addScheduleItems(destinationDataItem, dataToSave);
  }

  const onChildrenDrop = (e, props) => {

    if (isBreakOnly && !props.dataItem[IS_BREAK]) {
      toast.error("Can only be dropped on Break Item");
      return;
    }

    if (!lockStatus.canModify) {
      toast.error("Schedule Locked by " + lockStatus.lockedBy.name);
      return;
    }
    if (isDraftMode) {
      toast.error("Schedule is in draft mode");
      return;
    }
    //HERE we need to prepare the data
    const dataTransfer = JSON.parse(e.dataTransfer.getData("text"));
    const draggedData = dataTransfer.propsData;
    if (draggedData.some(item => item.Type == SCHEDULETYPE.Segment)) {
      toast.error(`Cannot move Segments`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const src = dataTransfer.src;
    const isFooter =
      props.dataItem.Type == SCHEDULETYPE.UnderRun ||
      props.dataItem.Type == SCHEDULETYPE.OverRun;
    const destinationDataItem = props.dataItem;

    const isInternal = src == ENTITYNAME.ProgramSchedule || src == ENTITYNAME.BREAK_CHILDREN;

    const itemInfo = SchedulingHelper.getChildInfo(destinationDataItem, schedulerDataRef.current);

    if (isInternal && (itemInfo.isBreak || itemInfo.isInsideBreak)) {
      if (!draggedData.some(item => item.mediaEpisode.MediaCategory.SID == destinationDataItem.mediaEpisode.MediaCategory.SID)) {
        toast.error(`${lang.please_choose_right_media_categor_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        if (draggedData.some(item => item.Source == "Booking")) {
          props.loadBookingCommercialdata();
        }
        return;
      }
    }
    //not allowed for segments
    if (src == ENTITYNAME.Prototype && isFooter) {
      toast.error(`${lang.prototype_drop_not_allowed_for_headers_and_footers_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (src == ENTITYNAME.MediaEpisode && draggedData.some(x => x[IS_BREAK])) {
      toast.error(`${lang.break_type_item_is_not_allowed_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (isInternal && draggedData.some(d => d._id == destinationDataItem._id)) {
      return;
    }

    if (src == ENTITYNAME.Prototype) {
      handleDropPrototype(destinationDataItem, draggedData[0]);
      return;
    }

    if (src == ENTITYNAME.Booking) {

      if (!SchedulingHelper.IsValidBookingDrop(destinationDataItem, draggedData, offsetTime)) {
        toast.error(`Some Dragged Booked Spots Are Not In Valid Time Range `, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (!SchedulingHelper.IsValidBookingDropPosition(destinationDataItem, draggedData, schedulerDataRef.current)) {
        toast.error(`Some Dragged Booked Spots Are Not Valid For This Destination Position `, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      //IF ANNNOUNCEMENT, WE JUST NEED TO DROP AND RETURN FROM HERE...
      if (draggedData[0].mediaEpisode.MediaCategoryType.SID == MEDIACATEGORYTYPES.Announcement) {
        console.log(destinationDataItem);
        console.log(draggedData[0]);
        handleDropAnnouncement(destinationDataItem, draggedData);
        deleteUsedBooking(draggedData);
        return;
      }

      //CHECK CLIENT SPOT SEPERATION
      var invalidClientSpotSeparationData = SchedulingHelper.IsValidBookingDropClientSpot(destinationDataItem, draggedData, schedulerDataRef.current);
      if (!invalidClientSpotSeparationData.IsValid) {
        toast.error(`${invalidClientSpotSeparationData.Message} `, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      //Added Booking Log 


      SalesHelper.saveBookingLogFromSchedule(draggedData, destinationDataItem);



      deleteUsedBooking(draggedData);
    }

    if (isInternal && draggedData.some((item) => item.Type == SCHEDULETYPE.Segment)) {
      console.log(destinationDataItem)
      if (!draggedData.some((item) => item.ParentProgramSchedule_id == destinationDataItem.ParentProgramSchedule_id)) {
        toast.error(`${lang.cannot_move_segment_to_different_program_schedule_error_message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }

    // CHECK CLIENT SPOT SEPERATION
    var invalidClientSpotSeparationData = SchedulingHelper.IsValidBookingDropClientSpot(destinationDataItem, draggedData, schedulerDataRef.current);
    if (isInternal && !invalidClientSpotSeparationData.IsValid) {
      toast.error(`${invalidClientSpotSeparationData.Message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!SchedulingHelper.IsValidDropDestinationSlot(destinationDataItem, draggedData, offsetTime, selectedDateRef.current, isInternal, selectedChannel)) {
      toast.error(`Some dragged items are not in valid Publishing Time Range `, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (isInternal) deleteScheduleItems(draggedData, false, true); //then it need to be delete first
    const dataToSave = SchedulingHelper.prepareData(
      src,
      selectedChannel,
      draggedData,
      destinationDataItem,
      schedulerDataRef.current,
    );
    addScheduleItems(destinationDataItem, dataToSave);
  }

  const onRowDrop = (e, destinationDataItem) => {
    console.log(e);
    if (!SchedulingHelper.canModify(destinationDataItem, schedulerDataRef.current)) {
      toast.error("Cannot drop here because header is locked", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const dataTransfer = JSON.parse(e.dataTransfer.getData("text"));
    const src = dataTransfer.src;
    const draggedData = dataTransfer.propsData;
    const isInternal = src == ENTITYNAME.ProgramSchedule;
    const isSrcIsParent = isInternal && draggedData.length > 0 && (draggedData[0].ParentProgramSchedule_id == null || draggedData[0].ParentProgramSchedule_id == undefined)
    // TODO: make varialbe for datItem isInternal or EXTERNAL

    // FOR DESTINATION
    const isParent = destinationDataItem.ParentProgramSchedule_id == null || destinationDataItem.ParentProgramSchedule_id == undefined;
    console.log(isParent);

    console.log(src);
    console.log(draggedData);
    console.log(destinationDataItem);

    if (isSrcIsParent) {
      toast.error("Cannot move header");
      return;
    }

    if (isParent) {
      onHeaderDrop(e, { dataItem: destinationDataItem });
    }
    else { //CALL CHILDREN ON DROP
      onChildrenDrop(e, { dataItem: destinationDataItem })
    }
  };

  const onTreeExpandChange = (event) => {
    const expanded = !event.value;
    const tree = [...schedulerDataRef.current];
    const itemId = event.dataItem._id;

    const updatedTree = tree.map((item) => {
      if (item._id === itemId) {
        return {
          ...item,
          [EXPAND_FIELD]: expanded,
        };
      } else {
        if (item.children) {
          item.children = item.children.map((child) => {
            if (child._id === itemId) {
              return {
                ...child,
                [EXPAND_FIELD]: expanded,
              };
            }
            return child;
          });
        }
      }
      return item;
    });

    schedulerDataRefSetter(updatedTree);
  };

  const gridPreferenceTreeColumn = (col, isHyperLink = false) => {

    let column = {
      key: col._id + Math.random(),
      width: col.width,
      field: col.name,
      title: col.label,
      cell: col.label == "Subtitle"
        ? MySubtitleCell :
        col.label == "TX Mode"
          ? TXModeCell :
          col.label == "TX Status"
            ? TXStatusCell
            : col.label == "Info"
              ? MyInfoCell
              : col.label == "Comment"
                ? (props) => <MyInfoCell {...props} cellBackgroundColor="yellow" cellTextColor="black" />
                : null
    }
    switch (col.type) {
      case COLUMNSTYPE.time:
        if (col.label == "Time" && isHyperLink) {
          return {
            ...column,
            cell: (props) => TimeHyperLinkCell({ ...props, onClick: onGoToSchedule })
          };
        } else {
          return {
            ...column,
            cell: TimeCell
          };
        }
      case COLUMNSTYPE.date:
        return {
          ...column,
          cell: DateOnlyCell
        };
      case COLUMNSTYPE.MyScheduleTypeCell:
        return {
          ...column,
          cell: ScheduleTypeCell
        };
      case COLUMNSTYPE.mySecondaryEventCountCell:
        return {
          ...column,
          cell: MySecondaryEventCountCell
        };
      case COLUMNSTYPE.myTXReadyCell:
        return {
          ...column,
          cell: MyTXReadyImageCell
        };
      case COLUMNSTYPE.myAnnouncementCountCell:
        return {
          ...column,
          cell: MyAnnouncementCountCell
        };
      case COLUMNSTYPE.archive:
        return {
          ...column,
          cell: ArchiveIconCell
        }
      case COLUMNSTYPE.segmentCountCell:
        return {
          ...column,
          cell: SegmentCountCell
        }
      default:
        return column;
    }
  }

  const GridRowRenderTreeList = (props) => {
    // for row styling
    let newProps = {
      ...props, children: props.children.map((d, i) => {
        return React.cloneElement(d, {
          ...d.props,
          style: !props.dataItem[SELECTED_FIELD] ? { ...d.props.dataItem.Style, ...d.props.style } : {},
        }, d.props.children)
      })
    }
    return <TreeListDraggableRow  {...newProps} />
  }

  const onColumnResize = (event) => {
    if (treeListRef) {
      treeListRef.current.element.style.width = `100%`;
    }
  };

  const showInsertBreakPopup = (e) => {
    hideContextMenus(e);
    if (selectedStateRef.current.length == 0 || selectedStateRef.current.length > 1) {
      toast.error(`${lang.select_target_item_from_schedule_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else {
      setShowInsertPlaceHolderFilter(true);
    }
  }

  const pageChange = (event) => {
    setSkip(event.page.skip);
  };

  const onRevertVirtualSegmentation = () => {
    SchedulingHelper.revertVirtualSegment(selectedStateRef.current, schedulerDataRef.current, loaddata);
  }
  // new code for tree  END

  const onMatchPreviousDayEndTime = async (scheduleTimeInfo) => {

    if (schedulerDataRef.current && schedulerDataRef.current.length < 1) {
      toast.error(`No Scheduling found on current date`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (schedulerDataRef.current[0].isLocked) {
      toast.error('Locked header at top. Please unlock and try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (scheduleTimeInfo?.PreviousScheduleEndDateTime == undefined || scheduleTimeInfo.PreviousScheduleEndDateTime == 0 || scheduleTimeInfo.PreviousScheduleEndDateTime == "NA") {
      toast.error(`Invalid previous day end time.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const firstHeader = schedulerDataRef.current[0];
    var newSlotDateTime = scheduleTimeInfo.PreviousScheduleEndDateTime;

    if (newSlotDateTime < payloadref.current.ScheduleDate || newSlotDateTime > payloadref.current.ScheduleEndDate) {
      toast.info(`Cannot match previous day end time as it is not within schedule range`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let updatedData = {
      SID: firstHeader.SID,
      _id: firstHeader._id,
      ScheduleDate: firstHeader.ScheduleDate,
      SlotDateTime: newSlotDateTime,
      EndSlotDateTime: newSlotDateTime + firstHeader.Duration,
      Segment_id: firstHeader.Segment_id
    }

    //to edit in ProgramScheduleTable
    var res = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: updatedData._id,
      SlotDateTime: newSlotDateTime
    });
    if (res.success) {

      onUpdateFromPlanningEditForm(updatedData);
      props.setScheduleTimeInfo({
        StartDateTime: newSlotDateTime,
        EndDateTime: scheduleTimeInfo.EndDateTime,
        PreviousScheduleEndDateTime: scheduleTimeInfo.PreviousScheduleEndDateTime,
      })

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  };


  const onEditCustomDuration = async () => {

    const updatedDuration = utility.convertStringWithFramesToMilliseconds(customSlotDurationRef.current);

    if (updatedDuration <= 0) {
      toast.error(`Invalid Custom Duration `, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var updateRes = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: selectedStateRef.current[0]._id,
      customDuration: updatedDuration,
      TcOut: (selectedStateRef.current[0]?.TcIn ?? 0) + updatedDuration
    });

    setEditDurationPopupOpen(false);

    if (updateRes.success) {

      let schedulerDataCopy = [...schedulerDataRef.current];
      let { level_0_index, level_1_index } = SchedulingHelper.getChildInfo(selectedStateRef.current[0], schedulerDataRef.current);

      //LOCAL DATA SET
      const updatedObject = {
        ...schedulerDataCopy[level_0_index].children[level_1_index],
        Duration: updatedDuration,
        TcOut: (schedulerDataCopy[level_0_index].children[level_1_index]?.TcIn ?? 0) + updatedDuration,
      };
      schedulerDataCopy[level_0_index].children.splice(level_1_index, 1, updatedObject);

      await childrenSave(level_0_index);
      schedulerDataRefSetter(schedulerDataCopy);

      // logs
      let logData = { event: LOGEVENT.UPDATE_SCHEDULE_ITEM_DURATION, module: MODULE.SCHEDULING, data: { ...updatedObject, customDuration: updatedDuration }, message: LOGEVENT.UPDATE_SCHEDULE_ITEM_DURATION };
      API.SaveProgramScheduleHeaderLogs(logData);

      toast.success(`Updated successfully `, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else {
      toast.error(`error:` + updateRes.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }


  }

  let childrenContextMenuModel = [
    //filter
    showFilterGrid
      ? {
        label: `${lang.remove_filter_actionbutton_menutext}`,
        icon: "fa fa-filter fa-lg",
        title: lang.remove_filter_actionbutton_menutext_tooltip,
        command: (e) => {
          hideContextMenus(e);
          setShowFilterGrid(false);
        },
        template: contextMenuItem
      }
      : {
        label: `${lang.filter_actionbutton_menutext}`,
        icon: "fa fa-filter fa-lg",
        title: lang.filter_actionbutton_menutext_tooltip,
        command: (e) => {
          hideContextMenus(e);
          onSchedulerChildrenFilter();
        },
        template: contextMenuItem
      },

    //Virtual Segmentation 
    {
      label: lang.virtual_segmentation_actionbutton_menutext,
      disabled: isDraftMode || showFilterGrid || !lockStatus.canModify || isBreakOnly,
      title: lang.virtual_segmentation_actionbutton_menutext_tooltip,
      icon: "pi pi-fw pi-box",
      command: (e) => {
        hideContextMenus(e);
        if (selectedStateRef.current.length > 1) {
          toast.error("Select only one Item")
        } else if (selectedStateRef.current[0][IS_BREAK]) {
          toast.error("Virtual Segmentation not allowed on breaks")
        } else if (selectedStateRef.current[0].VirtualSegmentKey != null) {
          toast.error("This is already a part of Virtual Segmentation")
        } else if (selectedStateRef.current[0].Type != SCHEDULETYPE.Segment) {
          toast.error("Selected item is not a segment");
        } else {
          setVirtualSegmentationData({
            ...virtualSegmentationData,
            duration: utility.convertMilisecondsToStringWithFrames(selectedStateRef.current[0].Duration),
            totalDur: utility.convertMilisecondsToStringWithFrames(selectedStateRef.current[0].Duration)
          });
          setShowVirtualSegmentation(true);
        }
      },
      template: contextMenuItem
    },
    //Revert Virtual Segmentation 
    {
      label: lang.revert_virtual_segmentation_actionbutton_menutext,
      disabled: isDraftMode || showFilterGrid || !lockStatus.canModify || isBreakOnly,
      icon: "pi pi-fw pi-box",
      title: lang.revert_virtual_segmentation_actionbutton_menutext_tooltip,
      command: (e) => {
        hideContextMenus(e);
        onRevertVirtualSegmentation();
      },
      template: contextMenuItem
    },
    //Insert Place Holder
    {
      label: `${lang.insert_break_actionbutton_menutext}`,
      disabled: isDraftMode || showFilterGrid || !lockStatus.canModify || isBreakOnly,
      icon: "pi pi-fw pi-envelope",
      title: lang.insert_break_actionbutton_menutext_tooltip,
      command: (e) => {
        showInsertBreakPopup(e);
      },
      template: contextMenuItem
    },

    //copy
    {
      label: `${lang.copy_actionbutton_menutext}`,
      disabled: isDraftMode || showFilterGrid || !lockStatus.canModify,
      icon: "fa fa-copy fa-lg",
      shortcut: "Ctrl + C",
      title: lang.copy_actionbutton_menutext_tooltip,
      command: (e) => {
        hideContextMenus(e);
        onCopy();
      },
      template: contextMenuItem
    },

    //cut
    {
      label: `${lang.cut_actionbutton_menutext}`,
      disabled: isDraftMode || showFilterGrid || !lockStatus.canModify || isBreakOnly,
      icon: "fa fa-cut fa-lg",
      shortcut: "Ctrl + X",
      title: lang.cut_actionbutton_menutext_tooltip,
      command: (e) => {
        hideContextMenus(e);
        onDelete(true);
      },
      template: contextMenuItem
    },


    //paste
    {
      label: `${lang.paste_actionbutton_menutext}`,
      disabled: isDraftMode || showFilterGrid || !lockStatus.canModify || isBreakOnly,
      icon: "fa fa-paste fa-lg",
      shortcut: "Ctrl + V",
      title: lang.paste_actionbutton_menutext_tooltip,
      command: (e) => {
        hideContextMenus(e);
        onPaste();
      },
      template: contextMenuItem
    },

    //delete
    {
      label: `${lang.delete_actionbutton_menutext}`,
      disabled: isDraftMode || !lockStatus.canModify || isBreakOnly,
      icon: "pi pi-trash",
      shortcut: "del",
      title: lang.delete_actionbutton_menutext_tooltip,
      command: (e) => {
        hideContextMenus(e);
        onDelete();
      },
      template: contextMenuItem
    },

    //edit duration
    {
      label: `${lang.editduration_actionbutton_menutext}`,
      disabled: isDraftMode || !lockStatus.canModify || isBreakOnly,
      icon: "pi pi-pencil",
      title: lang.editduration_actionbutton_menutext,
      command: (e) => {
        hideContextMenus(e);

        if (!selectedStateRef.current || !selectedStateRef.current[0]) {
          toast.error("Select item to edit duration")
          return;
        }
        if (selectedStateRef.current.length > 1) {
          toast.error("Select only one Item")
          return;
        }

        setCustomSlotDuration(utility.convertMilisecondsToStringWithFrames((selectedStateRef.current[0]?.Duration ?? 0)))
        setEditDurationPopupOpen(true)
      },
      template: contextMenuItem
    },

    //Update Comments
    {
      label: `${lang.add_comment_actionbutton_menutext}`,
      icon: "pi pi-fw pi-comment",
      command: (e) => {
        hideContextMenus(e);
        setComment(selectedStateRef.current[0]?.Comment ?? '');
        setUpdateCommentPopupOpen(true)
      },
      template: contextMenuItem
    },

    // //se overview
    {
      label: `${lang.se_overview_actionbutton_menutext}`, disabled: showFilterGrid || !lockStatus.canModify, icon: 'pi pi-book',
      title: `${lang.se_overview_actionbutton_menutext_tooltip}`,
      command: (e) => {
        hideContextMenus(e); showSecondaryEventOverviewDialogProp()
      }, template: contextMenuItem
    },
    {
      disabled: isDraftMode || !lockStatus.canModify || isBreakOnly,
      label: `${lang.global_replacement_actionbutton_menutext}`,
      icon: "pi pi-globe",
      title: lang.global_replacement_actionbutton_menutext_tooltip,
      command: (e) => {
        hideContextMenus(e);
        showGlobalReplacement();
      },
      template: contextMenuItem
    },
    {
      disabled: isDraftMode || !lockStatus.canModify || isBreakOnly,
      label: lang.delete_secondary_events_actionbutton_menutext,
      icon: "pi pi-trash",
      title: lang.delete_secondary_events_actionbutton_menutext_tooltip,
      command: (e) => {
        hideContextMenus(e);
        deleteSelectedSecondaryEvent();
      },
      template: contextMenuItem
    },
  ]

  if (enableScheduleInsertLiveEvent) {
    childrenContextMenuModel.splice(1, 0, {
      label: lang.insert_live_event_actionbutton_menutext,
      disabled: isDraftMode || showFilterGrid || !lockStatus.canModify || isBreakOnly,
      icon: "pi pi-fw pi-eye",
      title: lang.insert_live_event_actionbutton_menutext_tooltip,
      command: (e) => {
        hideContextMenus(e);
        handleLiveEventPopup();
      },
      template: contextMenuItem
    })
  }

  const onLockUnlockItem = async (planningItem) => {
    let isPlanningItemLocked = planningItem?.isLocked ?? false;
    let res = await utility.lockUnlockItem(planningItem._id, !isPlanningItemLocked);
    if (res.success) {
      toast.success(`Scheduling item ${isPlanningItemLocked ? 'unlocked' : 'locked'} successfully`, { position: toast.POSITION.TOP_RIGHT });
      schedulerDataRefSetter(schedulerDataRef.current.map(data => data._id == planningItem._id ? { ...data, isLocked: !isPlanningItemLocked } : data));
    } else {
      toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
    }
  }

  return (
    <div style={{ height: "100%" }}>
      {
        isSchduleLoading ? <div className="ml-2 mt-2">
          <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
        </div> : <div id="content-scroll" ref={scheduleScrollRef} className="content-scroll" style={{ height: "100%", overflow: "hidden" }} >
          {showFilterGrid ? (
            <Grid
              ref={gridRef}
              // style={{ userSelect: "none", height: "100%" }}
              style={{
                height: treeListHeight,
                overflow: "auto",
                width: "100%",
                userSelect: "none"
              }}
              data={orderBy(
                showFilterGridData.map((item) => {
                  item[SELECTED_FIELD] = selectedState.some(
                    (dataItem) => dataItem._id == item._id
                  );
                  return item;
                }),
                [
                  {
                    field: "SlotDateTime",
                    dir: "asc",
                  },
                ]
              )}

              // to remove virtual scrolling also remove e.skip from on selectionChange just go to to onselection change function i will guid you there
              //virtual scrolling implementation start
              rowHeight={20}
              pageSize={pageSize}
              total={showFilterGridData.length}
              skip={skip}
              scrollable="virtual"
              onPageChange={pageChange}
              //virtual scrolling implementation end
              rowRender={GridRowRenderFilterGrid}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              resizable={true}
              selectable={{
                enabled: true,
                drag: true,
                cell: false,
                mode: {
                  value: "multiple",
                  label: "Multiple selection mode",
                },
              }}
              onSelectionChange={(e) => onSelectionChange(e, true)}
            >
              <Column width="1px" />
              {dataColumns.map((col) => <Column {...gridPreferenceTreeColumn(col, true)} />)}
            </Grid>
          ) : (

            <TreeList
              ref={treeListRef}
              style={{
                height: treeListHeight,
                overflow: "auto",
                width: "100%",
                userSelect: "none"
              }}
              resizable={true}
              rowHeight={ROW_HEIGHT}
              dataItemKey={DATA_ITEM_KEY}
              tableProps={{
                style: {
                  tableLayout: "fixed",
                },
                onDragOver: (e) => {
                  e.preventDefault();
                },
                onDrop: (e) => {
                  console.log(e);
                  let dataItem = SchedulingHelper.getDataItemFromEvent(e, schedulerDataRef.current);
                  if (!dataItem) {
                    return;
                  } else {
                    onRowDrop(e, dataItem);
                  }
                },
                onClick: (e) => {
                  let dataItem = SchedulingHelper.getDataItemFromEvent(e, schedulerDataRef.current);
                  if (!dataItem) {
                    return;
                  } else {
                    if (dataItem.ParentProgramSchedule_id == null) {
                      setSelectedHeader(dataItem);
                      selectedStateRefSetter([dataItem]);
                    }

                    onSelectionChange(e, false, dataItem);
                    // onTreeSelectionChange(e, dataItem);
                    // selectedStateRefSetter([dataItem]);
                  }
                },
                onContextMenu: (e) => {
                  e.preventDefault();
                  console.log(e);
                  let dataItem = SchedulingHelper.getDataItemFromEvent(e, schedulerDataRef.current);
                  if (!dataItem) {
                    return;
                  }
                  else {
                    console.log(dataItem);
                    hideContextMenus(e);
                    //Parrent
                    if (dataItem.Type == SCHEDULETYPE.Header) {
                      hcm.current?.show(e);
                      setSelectedHeader(dataItem);
                      selectedStateRefSetter([dataItem]);
                    }
                    else {
                      if (dataItem[IS_BREAK_CHILDREN]) {
                        bc.current?.show(e);
                      } else {
                        cm.current?.show(e);
                      }

                      //for filter
                      let field = Object.keys(dataItem)[e.target.cellIndex];
                      schedulerChildrenFilterRef.current = { dataItem: dataItem, field: field }


                      if (selectedStateRef.current.length == 1 && selectedStateRef.current[0]._id == dataItem._id) {
                        selectedStateRefSetter([dataItem]);
                      } else if (selectedStateRef.current.length > 0 && dataItem.Type != SCHEDULETYPE.UnderRun && dataItem.Type != SCHEDULETYPE.OverRun) {

                        if (!selectedStateRef.current.some((item) => item._id == dataItem._id)) {
                          selectedStateRefSetter([dataItem]);
                        } else {
                          selectedStateRefSetter([...selectedStateRef.current, dataItem]);

                        }
                      } else {
                        selectedStateRefSetter([dataItem]);
                      }
                    }
                  }


                },
              }}
              scrollable="virtual"
              data={orderBy(schedulerData.map(parent => {
                parent[SELECTED_FIELD] = selectedState.some(
                  (dataItem) => dataItem._id == parent._id
                );
                if (parent?.children?.length > 0) {
                  parent.children = orderBy(parent.children.map((item) => {
                    item[SELECTED_FIELD] = selectedState.some(
                      (dataItem) => dataItem._id == item._id
                    );
                    if (item?.children?.length > 0) {
                      item.children = orderBy(item.children.map((bc) => {
                        bc[SELECTED_FIELD] = selectedState.some(
                          (dataItem) => dataItem._id == bc._id
                        );
                        return bc
                      }), [
                        {
                          field: "SlotDateTime",
                          dir: "asc",
                        }
                      ])
                    }
                    return item
                  },), [
                    {
                      field: "SlotDateTime",
                      dir: "asc",
                    }
                  ])
                }
                return parent;
              }), [
                {
                  field: "SlotDateTime",
                  dir: "asc",
                },
              ])}
              expandField={EXPAND_FIELD}
              selectedField={SELECTED_FIELD}
              subItemsField={SUB_ITEM_FIELD}
              onExpandChange={onTreeExpandChange}
              columns={[{
                key: Math.random(),
                width: '30px',
                field: '',
                title: '',
                resizable: false,
                cell: (props) => {
                  if (props.dataItem?.Type == SCHEDULETYPE.Header || props.dataItem[IS_BREAK]) {
                    return <div id={props?.dataItem?._id} onClick={(e) => props.onExpandChange(e, props.dataItem)} style={{ width: '100%', height: "40px", cursor: "pointer", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "black", color: "white", ...props.dataItem?.Style }}>
                      <i className={props.dataItem.expanded ? "fa fa-minus" : "fa fa-plus"} />
                      {props?.dataItem?.isLocked && <i style={{ position: "absolute", bottom: "2px", right: "2px", fontSize: "10px" }} className="fa fa-lock pt-1" />}
                    </div>
                  } else if (props.dataItem[IS_BREAK_CHILDREN]) {
                    return <div id={props?.dataItem?._id} onClick={(e) => props.onExpandChange(e, props.dataItem)} style={{ width: '100%', height: "40px", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "black", color: "white", ...props.dataItem?.Style }}><i className={"fa fa-b fa-sm"} /></div>
                  }
                  else {
                    return <div id={props?.dataItem?._id} onClick={(e) => props.onExpandChange(e, props.dataItem)} style={{ width: '100%', height: "40px", backgroundColor: "white", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}></div>
                  }

                }
              }, ...dataColumns.map((col) => gridPreferenceTreeColumn(col))]}
              row={GridRowRenderTreeList}
              onRowDrop={(e) => {
                var destinationDataItem;
                // draggedOver = [0], [0,1],[0,2,4]
                if (e.draggedOver.length == 1) {
                  destinationDataItem = schedulerDataRef.current[e.draggedOver[0]];
                }
                else if (e.draggedOver.length == 2) {
                  destinationDataItem = schedulerDataRef.current[e.draggedOver[0]].children[e.draggedOver[1]];
                }
                else {
                  destinationDataItem = schedulerDataRef.current[e.draggedOver[0]].children[e.draggedOver[1]].children[e.draggedOver[2]];
                }
                let draggedItems = [];
                if (selectedStateRef.current.some(x => x._id == e.draggedItem._id)) {
                  draggedItems = selectedStateRef.current;
                } else {
                  draggedItems = [e.draggedItem];
                }

                let myEvent = {
                  ...e,
                  dataTransfer: {
                    getData: () => { return JSON.stringify({ src: ENTITYNAME.ProgramSchedule, propsData: draggedItems }) }
                  }
                }
                onRowDrop(myEvent, destinationDataItem);
              }}
              selectable={{
                enabled: true,
                // drag: false,
                // cell: false,
                mode: 'single',
              }}
              onColumnResize={onColumnResize}
            />
          )}
        </div>}


      {/* HEADER CONTEXT MENU */}
      {!showFilterGrid && <ContextMenu
        ref={hcm}
        model={[
          {
            disabled: selectedHeader.current.isLocked || isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader || isBreakOnly,
            label: `${lang.edit_actionbutton_menutext}`,
            icon: "pi pi-fw pi-pencil",
            title: lang.edit_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e);
              setShowPlanningEditForm((old) => !old);
            },
            template: contextMenuItem
          },
          {
            disabled: isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader || isBreakOnly,
            label: `${lang.select_all_Header_menutext}`,
            title: lang.select_all_Header_actionbutton_menutext_tooltip,
            icon: "pi pi-fw pi-list",
            command: (e) => {
              hideContextMenus(e);
              selectAllHeaders();
            },
            template: contextMenuItem
          },
          {
            disabled: selectedHeader.current.isLocked || isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader || isBreakOnly,
            label: `${lang.delete_header_actionbutton_menutext}`,
            title: lang.delete_header_actionbutton_menutext_tooltip,
            icon: "pi pi-fw pi-trash",
            command: (e) => {
              hideContextMenus(e);
              scheduleHeaderDelete();
            },
            template: contextMenuItem
          },
          //Insert Place Holder
          {
            label: `${lang.insert_break_actionbutton_menutext}`,
            disabled: selectedHeader.current.isLocked || isDraftMode || showFilterGrid || !lockStatus.canModify || !lockStatus.canModifyHeader || isBreakOnly,
            icon: "pi pi-fw pi-envelope",
            title: lang.insert_break_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e);
              console.log(selectedStateRef.current);
              if (selectedStateRef.current.length == 0 || selectedStateRef.current.length > 1) {
                toast.error(`${lang.select_target_item_from_schedule_error_message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                setShowInsertPlaceHolderFilter((old) => !old);
              }
            },
            template: contextMenuItem
          },
          {
            label: lang.lock_unlock_label + ' Item',
            disabled: isDraftMode || showFilterGrid || !lockStatus.canModify || !lockStatus.canModifyHeader || isBreakOnly,
            icon: "pi pi-fw pi-lock",
            title: lang.lock_unlock_label + ' Item',
            command: (e) => {
              hideContextMenus(e);
              console.log(selectedStateRef.current);
              if (selectedStateRef.current.length == 0 || selectedStateRef.current.length > 1) {
                toast.error(`${lang.select_target_item_from_schedule_error_message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                onLockUnlockItem(selectedStateRef.current[0]);
              }
            },
            template: contextMenuItem
          },
          // {
          //     label: `${"Create Breaks"}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-envelope',command: (e) => {
          // hideContextMenus(e);
          //         if (selectedState.length == 0 || selectedState.length > 1) {
          //             toast.error('Select target item from schedule', {
          //                 position: toast.POSITION.TOP_RIGHT
          //             });
          //         } else {
          //             setLoopBreak(true);
          //             setShowInsertPlaceHolderFilter(old => !old);
          //         }
          //     }
          // },
          {
            disabled: selectedHeader.current.isLocked || isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader || isBreakOnly,
            label: `${lang.program_replacement_actionbutton_menutext}`,
            icon: "pi pi-fw pi-sync",
            title: lang.program_replacement_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e);
              setShowProgramReplacementDialog(true);
            },
            template: contextMenuItem
          },
          {
            label: lang.expand_all_breaks_label,
            icon: "pi pi-arrows-v",
            title: lang.expand_all_breaks_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e);
              expandMinimizeAllBreaks(true);
            },
            template: contextMenuItem
          },
          {
            label: lang.mnimize_all_breaks_label,
            icon: "pi pi-fw pi-minus",
            title: lang.mnimize_all_breaks_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e);
              expandMinimizeAllBreaks(false);
            },
            template: contextMenuItem
          },
          {
            disabled: selectedHeader.current.isLocked || isDraftMode || !lockStatus.canModify || !lockStatus.canModifyHeader || isBreakOnly,
            label: `${lang.attach_format_reload_actionbutton_menutext}`,
            icon: "pi pi-fw pi-microsoft",
            title: lang.attach_format_reload_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e);
              setShowFormatSelection(true);
            },
            template: contextMenuItem
          },
          //{ label: `${lang.history_actionbutton_menutext}`, icon: 'pi pi-fw pi-history',command: (e) => {
          // hideContextMenus(e); setShowHistory(true) } },
          //EDIT SEGMENTATION
          {
            label: `${lang.show_segmentation_actionbutton_menutext}`, icon: 'pi pi-fw pi-server',
            title: lang.show_segmentation_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e); props.handleTabChange(e, 5);
            },
            template: contextMenuItem
          },
          {
            label: `${lang.fast_segmentation_actionbutton_menutext}`, icon: 'pi pi-fw pi-bolt',
            title: lang.fast_segmentation_actionbutton_menutext_tooltip,
            disabled: selectedHeader.current.isLocked,
            command: (e) => {
              hideContextMenus(e); setFastSegmentationPopupOpen(true);
            },
            template: contextMenuItem
          },

          //Update Tx Status
          {
            label: `${lang.update_txstatus_actionbutton_menutext}`,
            icon: "fa fa-solid fa-satellite-dish",
            disabled: selectedHeader.current.isLocked,
            command: (e) => {
              hideContextMenus(e);
              setTxStatus(selectedState[0]?.TXStatus);
              setUpdateTxStatusPopupOpen(true)
            },
            template: contextMenuItem
          },

          //Update Comments
          {
            label: `${lang.add_comment_actionbutton_menutext}`,
            icon: "pi pi-fw pi-comment",
            command: (e) => {
              hideContextMenus(e);
              setComment(selectedState[0]?.Comment ?? '');
              setUpdateCommentPopupOpen(true)
            },
            template: contextMenuItem
          },

          //Manage under Over run
          {
            label: `${lang.manage_underoverrun_schedule_menu_text}`,
            icon: "fa fa-list",
            disabled: selectedHeader.current.isLocked,
            command: (e) => {
              hideContextMenus(e);
              ManageUnderAndOverRuns([selectedState[0]?._id], false);
            },
            template: contextMenuItem
          },
        ]}
      />}

      {!showFilterGrid && <ContextMenu
        ref={cm}
        model={childrenContextMenuModel}
      />}

      <ContextMenu
        ref={fg}
        model={[
          //filter
          showFilterGrid
            ? {
              label: `${lang.remove_filter_actionbutton_menutext}`,
              icon: "pi pi-fw pi-filter",
              title: lang.remove_filter_actionbutton_menutext_tooltip,
              command: (e) => {
                hideContextMenus(e);
                setShowFilterGrid(false);
              },
              template: contextMenuItem
            }
            : {
              label: `${lang.filter_actionbutton_menutext}`,
              icon: "pi pi-fw pi-filter",
              title: lang.filter_actionbutton_menutext_tooltip,
              command: (e) => {
                onSchedulerChildrenFilter();
                hideContextMenus(e);
              },
              template: contextMenuItem
            },

          //delete
          {
            label: `${lang.delete_actionbutton_menutext}`,
            disabled: isDraftMode || !lockStatus.canModify || isBreakOnly,
            icon: "pi pi-fw pi-trash",
            title: lang.delete_actionbutton_menutext_tooltip,
            command: (e) => {
              onDelete();
              hideContextMenus(e);
            },
            template: contextMenuItem
          },
          {
            disabled: isDraftMode || !lockStatus.canModify || isBreakOnly,
            label: `${lang.global_replacement_actionbutton_menutext}`,
            icon: "pi pi-fw pi-sync",
            title: lang.global_replacement_actionbutton_menutext_tooltip,
            command: (e) => {
              showGlobalReplacement();
              hideContextMenus(e);
            },
            template: contextMenuItem
          },
          {
            disabled: isDraftMode || !lockStatus.canModify || isBreakOnly,
            label: lang.delete_secondary_events_actionbutton_menutext,
            icon: "pi pi-fw pi-trash",
            title: lang.delete_secondary_events_actionbutton_menutext_tooltip,
            command: (e) => {
              deleteSelectedSecondaryEvent();
              hideContextMenus(e);
            },
            template: contextMenuItem
          },
        ]}
      />
      {!showFilterGrid && <ContextMenu
        ref={bc}
        model={[
          //filter
          showFilterGrid
            ? {
              label: `${lang.remove_filter_actionbutton_menutext}`,
              icon: "fa fa-filter fa-lg",
              title: lang.remove_filter_actionbutton_menutext_tooltip,
              command: (e) => {
                hideContextMenus(e);
                setShowFilterGrid(false);
              },
              template: contextMenuItem
            }
            : {
              label: `${lang.filter_actionbutton_menutext}`,
              icon: "fa fa-filter fa-lg",
              title: lang.filter_actionbutton_menutext_tooltip,
              command: (e) => {
                hideContextMenus(e);
                onSchedulerChildrenFilter();
              },
              template: contextMenuItem
            },
          //copy
          {
            label: `${lang.copy_actionbutton_menutext}`,
            disabled: isDraftMode || showFilterGrid || !lockStatus.canModify,
            icon: "fa fa-copy fa-lg",
            shortcut: 'Ctrl + C',
            title: lang.copy_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e);
              onCopy();
            },
            template: contextMenuItem
          },
          //paste
          {
            label: `${lang.paste_actionbutton_menutext}`,
            disabled: isDraftMode || showFilterGrid || !lockStatus.canModify,
            icon: "fa fa-paste fa-lg",
            shortcut: 'Ctrl + V',
            title: lang.paste_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e);
              onPaste();
            },
            template: contextMenuItem
          },

          //delete
          {
            label: `${lang.delete_actionbutton_menutext}`,
            disabled: isDraftMode || !lockStatus.canModify,
            icon: "pi pi-trash",
            shortcut: 'del',
            title: lang.delete_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e);
              onDelete();
            },
            template: contextMenuItem
          },

          // //se overview
          {
            label: `${lang.se_overview_actionbutton_menutext}`, disabled: showFilterGrid || !lockStatus.canModify, icon: 'pi pi-book',
            title: lang.se_overview_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e); showSecondaryEventOverviewDialogProp()
            }, template: contextMenuItem
          },
          {
            label: `${lang.global_replacement_actionbutton_menutext}`, disabled: !lockStatus.canModify || isBreakOnly, icon: 'pi pi-globe',
            title: lang.global_replacement_actionbutton_menutext_tooltip,
            command: (e) => {
              hideContextMenus(e); showGlobalReplacement();
            }, template: contextMenuItem
          },
        ]}
      />
      }

      {showPlanningEditForm && (
        <PlanningEditForm
          isOpenFromSchedule={true}
          onClose={() => setShowPlanningEditForm((old) => !old)}
          dataItem={{
            ...selectedHeader.current,
            Title: selectedHeader.current.mediaEpisode.Title,
            ChannelSID: selectedHeader.current.Channel.SID,
            // ExhibitionNumber: selectedHeader.current.ExhibitionNumber ?? 0,
            // RepeatNumber: selectedHeader.current.RepeatNumber ?? 0,
            //ExhibitionNumber missing
            //RepeatNumber missing
            //ActualDuration missing
            //Nominalduration missing
          }}
          onUpdateFromPlanningEditForm={onUpdateFromPlanningEditForm}
          closeEditForm={() => setShowPlanningEditForm((old) => !old)}
          module={MODULE.SCHEDULING}
        />
      )}
      {showInsertPlaceHolderFilter && (
        <Dialog
          title={lang.insert_break_label}
          onClose={() => setShowInsertPlaceHolderFilter(false)}
          width={"400px"}
        >
          <div className="row">
            <div className="col-md-12 mt-2">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="">{lang.media_category_label}*</label>
                    <DropDownList
                      data={mediaCategory}
                      name="MediaCategory"
                      textField="Description"
                      dataItemKey="_id"
                      value={breakdataItemForPopup.MediaCategory}
                      onChange={onChangeBreakDataItemPopup}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="TabView">{lang.media_category_type_label}*</label>
                    <DropDownList
                      data={filterMediaCategoryType}
                      name="MediaCategoryType"
                      textField="Description"
                      dataItemKey="_id"
                      value={breakdataItemForPopup.MediaCategoryType ?? mediaCategoryType[0]}
                      onChange={onChangeBreakDataItemPopup}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="">{lang.duration_label} *</label>
                    <TimePickerWithFormat
                      name="Duration"
                      value={breakdataItemForPopup.Duration}
                      onChange={(e) => onChangeBreakDataItemPopup(e)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-danger text-white"
              onClick={() => setShowInsertPlaceHolderFilter(false)}
            >
              {lang.cancel_button_text}
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary text-white"
              onClick={setSelectedPlaceHolderMedia}
            >
              {lang.add_button_text}
            </button>
          </DialogActionsBar>
        </Dialog>

        // <CollectionSelection
        //   entityname={ENTITYNAME.MediaEpisode}
        //   wherestatement={["IsBreak", "=", true]}
        //   setDataList={setSelectedPlaceHolderMedia}
        //   mode={"single"}
        //   closeForm={() => {
        //     setShowInsertPlaceHolderFilter((old) => !old);
        //   }}
        //   title={lang.add_insert_break_label}
        //   width="50vw"
        //   height="64vh"
        // />
      )}

      {showProgramReplacementDialog && (<>
        <ContractCollectionSelection
          title={`${lang.select_program_to_replace_with_collection_title}`}
          entityname={ENTITYNAME.MediaEpisode}
          wherestatement={["MediaCategory.isLong", "=", true]}
          setDataList={programReplacement}
          hideSeriesSeason={true}
          mediaCategory={selectedHeader?.current?.mediaEpisode?.MediaCategory}
          mode={"single"}
          closeForm={() => {
            setShowProgramReplacementDialog(false);
          }}
          width={"50vw"} />
      </>
      )}

      {showAutoPlotPromo && <AutoPlotPromo
        data={selectedPromoRef.current}
        onSave={autoPlotPromoSchedule}
        setData={setSelectedPromo}
        cancelEdit={() => setShowAutoPlotPromo(false)}
      />}

      {showGlobalReplacementDialog && (
        <GlobalReplacement
          gloabalFindAndReplace={gloabalFindAndReplace}
          selectedState={selectedStateRef.current}
          scheduleData={schedulerDataRef.current}
          closeForm={() => {
            setShowGlobalReplacementDialog(false);
          }}
        />
      )}

      {showCheckList && (
        <CheckLists closeForm={onCheckList} onGoToSchedule={onGoToSchedule} data={schedulerData} scheduleTimeInfo={props.scheduleTimeInfo} bookedCommercialData={props.bookedCommercialData} onExport={(checkList) => exportSchedule(props.selectedChannel.Automation.SID, props.selectedChannel, checkList)} />
      )}

      {showMediaDealRightsPopup && (

        <div className="row" style={{ marginLeft: "-02px", width: "570px" }}>
          <div className="col-12">
            <CollectionSelection
              title={
                lang.please_choose_one_publishing_rights_collection_header
              }
              entityname={ENTITYNAME.MediaDealRights}
              wherestatement={[[
                "media_id",
                "=",
                programToReplaceRef.current._id,
              ], ["PlatformType.SID", '=', PLATFORMTYPE[0].SID]]} // 0 indes is Linear
              setDataList={replaceProgramWithSelectedDealRight}
              mode={"single"}
              closeForm={onCancelMediaDealRightPopup}
            />
          </div>
        </div>

      )}

      {showFormatSelection && (
        <CollectionSelection
          addButtonTitle={"Apply"}
          entityname={ENTITYNAME.Format}
          wherestatement={["Channel.SID", "=", selectedChannel.SID]}
          setDataList={onApplyFormat}
          closeForm={() => setShowFormatSelection(false)}
          existingItems={[]}
          checkDuplicate={false}
          mode={"single"}
          width="50vw"
          height="64vh"
        />
      )}

      {styleSheetSelectionOpen && (
        <CollectionSelection
          title={lang.style_sheet_dialog_header}
          addButtonTitle={"Apply"}
          entityname={ENTITYNAME.SecondaryEventStyleSheet}
          wherestatement={["Channel.SID", "=", selectedChannel.SID]}
          setDataList={onStyleSheetSelectionClose}
          closeForm={onStyleSheetSelection}
          existingItems={[]}
          checkDuplicate={false}
          width={"50vw"}
        />
      )}

      {/* {interstitialGroupSelectionOpen && (
        <CollectionSelection
          addButtonTitle={"Apply"}
          title={'Select Interstitial Group'}
          entityname={ENTITYNAME.MediaEpisodeGroup}
          wherestatement={['MediaCategory.isLong', '=', false]}
          setDataList={fillUnderOverRun}
          closeForm={() => setInterstitialGroupSelectionOpen(false)}
          existingItems={[]}
          checkDuplicate={false}
          width={"45vw"}
          height={"64vh"}
          mode={'single'}
        />
      )} */}

      {showSecondaryEventOverviewDialog &&
        <SchedulePrototypeOverview
          DATA_ITEM_KEY={DATA_ITEM_KEY}
          SELECTED_FIELD={SELECTED_FIELD}
          selectedItem={selectedState}
          MyTimeCell={TimeCell}
          setShowSecondaryEventOverviewDialog={setShowSecondaryEventOverviewDialog}
          saveSecondaryChangedData={saveSecondaryChangedData}
          data={schedulerDataRef.current}
        />}

      {fastSegmentationPopupOpen && <MediaFastSegmentationPopup mediaEpisodeEntity={selectedState[0].mediaEpisode} programScheduleEntity={selectedState[0]} refreshSegments={loadSavedFastSegments} onClose={() => setFastSegmentationPopupOpen(false)} source={'scheduling'} />}

      {updateTxStatusPopupOpen && (
        <Dialog
          title={lang.update_txstatus_actionbutton_menutext}
          width={"300px"}
          onClose={() => setUpdateTxStatusPopupOpen(false)}
        >
          <>
            <div className="row">
              <div className="col-12 form-group">
                <label htmlFor="TabView">{lang.tx_status_label}</label>
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={txStatusData}
                  name="txStatus"
                  textField="Description"
                  dataItemKey="SID"
                  value={txStatus}
                  onChange={(e) => { setTxStatus(e.target.value) }}
                />
              </div>
            </div>
            <EditPageHeader title={""} onSubmit={onUpdateTxStatus} onCancel={() => setUpdateTxStatusPopupOpen(false)} showTitle={false} />
          </>
        </Dialog>
      )}

      {updateCommentPopupOpen && (
        <Dialog
          title={comment.length > 0 ? lang.update_comment_actionbutton_menutext : lang.add_comment_actionbutton_menutext}
          width={"300px"}
          onClose={() => setUpdateCommentPopupOpen(false)}
        >
          <>
            <div className="row">
              <div className="col-12 form-group" style={{ marginBottom: 0 }}>
                <label htmlFor="TabView">{lang.comment_label}</label>
              </div>
              <div className="col-12 form-group">
                <textarea className="pl-2"
                  type="text"
                  value={comment}
                  style={{ border: 'solid lightgrey 1px', height: '50px', width: '270px' }}
                  onChange={(e) => { setComment(e.target.value) }}
                />
              </div>
            </div>
            <EditPageHeader title={""} onSubmit={onUpdateProgramScheduleComment} onCancel={() => setUpdateCommentPopupOpen(false)} showTitle={false} />
          </>
        </Dialog>
      )}

      {showVirtualSegmentation &&
        <VirtualSegmentationPopup onCancelVirtualSegmentationPopup={onCancelVirtualSegmentationPopup} onVirtualSegmentationSplitClick={() => {
          SchedulingHelper.onVirtualSegmentationSplitClick(virtualSegmentationData, setVirtualSegmentationData, selectedStateRef.current[0])
        }} onSaveVirtualSegmentation={() => SchedulingHelper.onSaveVirtualSegmentation(virtualSegmentationData, selectedStateRef.current[0], onCancelVirtualSegmentationPopup, loaddata)} inlineEdit={(event) => SchedulingHelper.inlineEdit(event, virtualSegmentationData, setVirtualSegmentationData, selectedStateRef.current[0])} virtualSegmentationData={virtualSegmentationData} setVirtualSegmentationData={setVirtualSegmentationData} selectedSegment={selectedStateRef.current[0]} />
      }

      {showGoToSchedulePopup && <GoToSchedulePopup close={() => setShowGoToSchedulePopup(false)} onGoToSchedule={onGoToSchedule} schedulerData={schedulerDataRef.current} />}

      {showProgressBar.progressBarPopup && <div id='myProgressBar'>
        <div className='myProgressBarBox'>
          <ProgressBar
            label={(props) => {
              return <strong>{props.value}%</strong>;
            }}
            labelPlacement='center'
            progressStyle={{ backgroundColor: "green" }}
            value={progressData.progress}
            style={{ width: "360px" }}
          />
          <div className='message'>
            <p>{progressData.message}</p>
          </div>
          {showProgressBar.okButton && <button onClick={() => { setShowProgressBar({ ...showProgressBar, progressBarPopup: false }); }} style={{ width: "50px" }} className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-success text-white' >OK</button>}
        </div>
      </div>}
      {showSaveScheduleDraftPopup && <ScheduleDraftEditForm scheduleDate={payloadref.current.ScheduleDate} scheduleEndDate={payloadref.current.ScheduleEndDate} channel={selectedChannel} close={handleSaveScheduleDraftPopup} />}

      {editDurationPopupOpen && (
        <Dialog
          title={'Set Custom Duration'}
          width={"300px"}
          onClose={() => setEditDurationPopupOpen(false)}
        >
          <>
            <EditPageHeader title={""} onSubmit={onEditCustomDuration} onCancel={() => setEditDurationPopupOpen(false)} showTitle={false} />

            <div className="form-group">
              <label htmlFor="">{lang.duration_label} *</label>
              <TimePickerWithFormat
                name="Duration"
                value={customSlotDuration}
                onChange={(e) => {
                  setCustomSlotDuration(e.target.value);
                }}
                required
              />

            </div>
          </>
        </Dialog>
      )}
    </div>
  );
};

export default memo(forwardRef(SchedulingMainTree));
