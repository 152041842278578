/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Dialog } from '@progress/kendo-react-dialogs'
import { EditPageHeader } from '../../components/EditPageHeader';
import * as API from "../../framework/API/api"
import { DAYS, ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { TimeRangePicker } from '../../framework/forms/helpercomponents/timepicker/TimeRangePicker';
import MyMultiSelect from '../../components/MyMultiSelect';

const DayPartEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        FromTime: props.item.FromTime ? utility.convertMilisecondsToShortTimeString(props.item.FromTime) : "00:00",
        ToTime: props.item.ToTime ? utility.convertMilisecondsToShortTimeString(props.item.ToTime) : "00:00",
        Priority: props.item.Priority ?? 0,
        Channels: props.item.Channels ?? [],
        ValidDays: props.item.ValidDays ? DAYS.filter(x=> props.item.ValidDays.includes(x.ID)) : [],
        Archive: props.item.Archive ?? false,
        IsAllChannel: props.item?.SID == 0 ? true : props.item?.IsAllChannel ? props.item?.IsAllChannel : false,
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [channels, setChannels] = useState([]);
    const [isSelectAllDays, setIsSelectAllDays] = useState(props?.item?.ValidDays ? (props?.item?.ValidDays?.length == 7 ? true : false) : true);

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel);
        setChannels(channelRes.data);
        if(props?.item?.SID == 0 && isSelectAllDays) setDataItem({ ...dataItem, ValidDays: DAYS });
    }

    const onChange = (e) => {
        if(e.target.name == "Archive"){
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        }else if (e.target.name == "ValidDays") {
            if (e.target.value.length == DAYS.length) {
              setDataItem({ ...dataItem, ValidDays: DAYS });
              setIsSelectAllDays(true);
            }
            else {
              setDataItem({ ...dataItem, ValidDays: e.target.value });
              setIsSelectAllDays(false);
            }
        }else if(e.target.name == "IsAllChannel"){
            setDataItem({ ...dataItem, IsAllChannel: !dataItem.IsAllChannel, Channels: [] });
        }
        else{
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if(dataItem.Description == undefined || dataItem.Description == ""){
            toast.error(lang.please_enter_description_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.FromTime == undefined || dataItem.FromTime == ""){
            toast.error(lang.please_enter_from_time_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.ToTime == undefined || dataItem.ToTime == "" || dataItem.ToTime == "00:00"){
            toast.error(lang.please_enter_to_time_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.FromTime > dataItem.ToTime){
            toast.error(lang.from_time_should_be_less_than_To_time_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.Priority == undefined){
            toast.error(lang.please_enter_priority_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(!dataItem?.IsAllChannel && (dataItem.Channels == undefined || dataItem.Channels.length == 0)){
            toast.error(lang.please_select_channel_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.ValidDays == undefined || dataItem.ValidDays.length == 0){
            toast.error(lang.please_select_valid_days_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        if (!isValid()) return;
        const saveData = {
            _id: dataItem._id,
            SID: dataItem.SID,
            Description: dataItem?.Description,
            FromTime: utility.convertShortTimeStringToMilliSeconds(dataItem?.FromTime),
            ToTime: utility.convertShortTimeStringToMilliSeconds(dataItem?.ToTime),
            Priority: parseInt(dataItem?.Priority ?? 0),
            Channels: dataItem.IsAllChannel ? [] : dataItem?.Channels?.length > 0 ? dataItem?.Channels?.map(x => { return { _id: x._id, SID: x.SID, FullChannelName: x.FullChannelName } }) : [],
            ValidDays: dataItem?.ValidDays?.length > 0 ? dataItem?.ValidDays?.map(x=>x?.ID) : [],
            Archive: dataItem.Archive ?? false,
            IsAllChannel: dataItem.IsAllChannel ?? true,
        }
        console.log(saveData);
        let checkDuplicate = await API.checkDuplicateInterSectTimeBand(saveData);
        if(!checkDuplicate?.success){
            toast.error(checkDuplicate?.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        let res = await API.saveData(ENTITYNAME.DayPart, saveData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.DAYPART, data: res.data, message: res.message };
            API.SaveLogs(logData);
            utility.deleteLocalStorageItem(ENTITYNAME.DayPart);
            props.refresh();
            props.cancelEdit();
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const allDaySelect = (e) => {
        if (e.target.checked) {
            setDataItem({ ...dataItem, ValidDays: DAYS });
            setIsSelectAllDays(true);
        }
        else {
            setDataItem({ ...dataItem, ValidDays: [] });
            setIsSelectAllDays(false);
        }
    }

    return (
        <Dialog
            title={props?.item?.Description ?? lang.create_day_part_dialog_header}
            onClose={props.cancelEdit}
            width={"650px"}
        >
            <div className='row mt-2'>
                <div className='col-12'>
                    <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                    <div className='row mt-2'>
                        <div className='col-12'>
                            <label>{lang.description_label} *</label>
                            <input
                                name="Description"
                                type="text"
                                className="form-control form-control-sm"
                                onChange={onChange}
                                value={dataItem.Description}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-3'>
                            <label>{lang.from_time_label} *</label>
                            <TimeRangePicker
                                name="FromTime"
                                value={dataItem.FromTime}
                                onChange={onChange}
                            />
                        </div>
                        <div className='col-3'>
                            <label htmlFor="TabView">{lang.to_time_label} *</label>
                            <TimeRangePicker
                                name="ToTime"
                                value={dataItem.ToTime}
                                onChange={onChange}
                            />
                        </div>
                        <div className='col-6'>
                            <label>{lang.priority_label} *</label>
                            <input
                                name="Priority"
                                type="number"
                                className="form-control form-control-sm"
                                onChange={onChange}
                                value={dataItem.Priority}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-6'>
                            <label>{lang.channel_label} *</label>
                            <span className="float-right">{lang.isAllChannel_label}
                                <input
                                    type="checkbox"
                                    name="IsAllChannel"
                                    className="ml-1"
                                    value={dataItem.IsAllChannel}
                                    checked={dataItem.IsAllChannel}
                                    onChange={onChange}
                                />
                            </span>
                            <MyMultiSelect
                                name="Channels"
                                data={channels}
                                textField="FullChannelName"
                                dataItemKey="_id"
                                value={dataItem.Channels}
                                onChange={onChange}
                                style={{ backgroundColor: dataItem.IsAllChannel ? "#E1E1E1" : "white" }}
                                disabled={dataItem.IsAllChannel}
                            />
                        </div>
                        <div className='col-6'>
                            <label>{lang.valid_days_label} *</label>
                            <span className="float-right">{lang.select_all_label}
                                <input
                                    type={"checkbox"}
                                    name={"SelectAll"}
                                    className="ml-1"
                                    onChange={allDaySelect}
                                    checked={isSelectAllDays}
                                    value={isSelectAllDays}
                                />
                            </span>
                            <MyMultiSelect
                                style={{ backgroundColor: "white" }}
                                data={DAYS}
                                name="ValidDays"
                                textField="Name"
                                dataItemKey="ID"
                                value={dataItem.ValidDays}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <input
                                type="checkbox"
                                name="Archive"
                                value={dataItem.Archive}
                                checked={dataItem.Archive}
                                onChange={onChange}
                            />
                            <label htmlFor="TabView" className="pl-2">{lang.archive}</label>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default DayPartEditForm;