import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useEffect, useState } from "react";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { utility } from "../../framework/utility/utilityProvider";
import PlanningFilter from "../Planning/PlanningFilter";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import InfoRoundButton from "../../components/InfoRoundButton";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";

const PlanningSlotEditForm = (props) => {
  const lang = useTranslation();
  const navigate = useNavigate();

  const blankDataItem = {
    ...props.item,
    SID: props.item.SID ?? 0,
    Description: props.item.copy ? "copy of " + props.item.Description : props.item.Description ?? "",
    FromDate: moment(new Date(props.item.FromDate)).format('YYYY-MM-DD'),
    ToDate: moment(new Date(props.item.ToDate)).format('YYYY-MM-DD'),
    Channel: props.item.Channel ?? {},
    // Days : props.item.Days ?? [],
    Archive: props.item.Archive ?? false,
    RepeatPattern: props.item.RepeatPattern ?? {},
    EnableFilter: props.item.EnableFilter ?? false,
    DayPart: props.item.DayPart ?? {},
  };

  const [dataItem, setDataItem] = useState(blankDataItem);
  // const [enableFilter, setEnableFilter] =useState(props.item.EnableFilter ?? false);
  const [enableFilter, setEnableFilter] = useState(false);
  const [channel, setChannel] = useState([]);
  const [dayParts, setDayParts] = useState([]);

  const [filterData, setFilterData] = useState(props?.item?.Filter ?? undefined);
  const [openRepeatPatternSelection, setOpenRepeatPatternSelection] = useState(false);
  const [showMediaPreview, setShowMediaPreview] = useState(false);
  const [mediaLibraryData, setMediaLibraryData] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  useEffect(() => {
    loadcombo();
  }, [])

  const loadcombo = async () => {
    let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    setChannel(channelRes.data);
    let dayPartRes = await API.getDataLookup(ENTITYNAME.DayPart, { sort: { Description: 1 } });
    setDayParts(dayPartRes.data);
  }

  const isValid = () => {
    if (dataItem.Description == undefined || dataItem.Description == "") {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.FromDate == undefined || !utility.isValidDate(dataItem.FromDate)) {
      toast.error(`${lang.please_select_from_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ToDate == undefined || !utility.isValidDate(dataItem.ToDate)) {
      toast.error(`${lang.please_select_to_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.FromDate > dataItem.ToDate) {
      toast.error(`${lang.from_date_should_be_less_then_to_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Channel == undefined || Object.keys(dataItem.Channel).length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    // if (dataItem.RepeatPattern == undefined || Object.keys(dataItem.RepeatPattern).length == 0) {
    //   toast.error(`${lang.please_select_repeat_pattern_error_message}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }

    if (dataItem.DayPart == undefined || Object.keys(dataItem.DayPart).length == 0) {
      toast.error(`${lang.please_select_day_part_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (isValid()) {
      const saveData = {
        _id: dataItem._id,
        SID: dataItem.SID,
        Description: dataItem.Description,
        FromDate: new Date(dataItem.FromDate).getTime(),
        ToDate: new Date(dataItem.ToDate).getTime(),
        Channel: dataItem.Channel ? {
          _id: dataItem?.Channel?._id,
          SID: dataItem?.Channel?.SID,
          FullChannelName: dataItem?.Channel?.FullChannelName
        } : {},
        RepeatPattern: dataItem.RepeatPattern,
        Filter: filterData,
        Archive: dataItem.Archive,
        DayPart: dataItem.DayPart ? {
          _id: dataItem?.DayPart?._id,
          Description: dataItem?.DayPart?.Description,
          FromTime: dataItem?.DayPart?.FromTime,
          ToTime: dataItem?.DayPart?.ToTime,
          Priority: dataItem?.DayPart?.Priority,
          ValidDays: dataItem?.DayPart?.ValidDays,
          IsAllChannel: dataItem?.DayPart?.IsAllChannel,
          Channels: dataItem?.DayPart?.Channels,
        } : {},
      };
      console.log(saveData);
      let res = await API.saveData(ENTITYNAME.PlanningSlot, saveData);
      if (res.success) {
        utility.deleteLocalStorageItem(ENTITYNAME.PlanningSlot);
        props.cancelEdit();
        props.refresh();
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  // const handleChange = (e) => {
  //   if (e.target.name == "EnableFilter"){
  //    setEnableFilter(e.target.checked)
  //   }
  //    if (e.target.name == "EnableFilter") {
  //     setDataItem({ ...dataItem, EnableFilter: !dataItem.EnableFilter });
  //   }
  // }

  const toGetFilteredData = (data) => {
    console.log(data)
    let finalData = {
      ...data,
      Template: data?.Template,
      MediaCategory: data?.MediaCategory?.length > 0 ? data.MediaCategory.map((x) => {
        return { _id: x._id, SID: x.SID, Description: x.Description };
      }) : [],
      Channel: data?.Channel?.length > 0 ? data.Channel.map((x) => {
        return { _id: x._id, SID: x.SID, FullChannelName: x.FullChannelName };
      }) : [],
      Genres: data?.Genres?.length > 0 ? data.Genres.map((x) => {
        return { _id: x._id, SID: x.SID, Description: x.Description };
      }) : [],
      ReleaseYear: data?.ReleaseYear,
      Language: data?.Language?.length > 0 ? data.Language.map((x) => {
        return { _id: x._id, SID: x.SID, Description: x.Description };
      }) : [],
      PGRating: data.PGRating.length > 0 ? data.PGRating.map((x) => {
        return { _id: x._id, SID: x.SID, Description: x.Description };
      }) : [],
      Exhibitions: data?.Exhibitions,
      // ExhibitionsCondition : data.Exhibitions.Condition,
      isLive: data?.isLive,
    };
    console.log(finalData);
    // setFilterDataItem(finalData);
    setEnableFilter(false);
    setFilterData(finalData);
  };

  const onSelectRepeatPattern = () => {
    setOpenRepeatPatternSelection(true);
  };

  const onSetSelectedRepeatPattern = (dataList) => {
    setDataItem({ ...dataItem, RepeatPattern: dataList[0] });
  };

  const showRepeatPattern = () => {
    if (dataItem.RepeatPattern && Object.keys(dataItem.RepeatPattern).length > 1) {
      navigate(`/home/RepeatPattern/` + dataItem.RepeatPattern.SID, { state: { disable: true } })
    }
    else {
      toast.error(`${lang.please_select_repeat_pattern_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onPreview = async() => {

    if(filterData && Object.keys(filterData).length > 0){

        setShowMediaPreview(true);

        setIsLoading(true);
  
        const response = await API.getMediaLibraryByPlanningSlot(props.item._id);
        console.log(response);
        if (response.success) {
            setMediaLibraryData(response.data ?? []);
            // setTotal(response.pagination.total);
        } else {
            toast.error(response.message);
            setMediaLibraryData([]);
        }
        setIsLoading(false);

    } else {
      toast.error(`Select filters first`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  };

  return (
    <>
      <Dialog
        title={
          props.item.SID > 0 ? props.item.Description : "Create Planning Slot"
        }
        onClose={props.cancelEdit}
        width={"770px"}
      >
        <EditPageHeader
          title={""}
          onSubmit={handleSubmit}
          onCancel={props.cancelEdit}
          onPreview={onPreview}
          showPreview={props.item.SID > 0}
          showTitle={false}
        />
        <div className="row">
          <div className="col-12">
            <div className="row mt-2">
              <div className="col">
                <label>{lang.description_label} *</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="Description"
                  value={dataItem.Description}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <label>{lang.from_date_label} *</label>
                <input
                  type={"date"}
                  name={"FromDate"}
                  placeholder={""}
                  label={lang.from_date_label}
                  onChange={onChange}
                  value={dataItem.FromDate}
                  className="form-control form-control-sm"
                />
              </div>

              <div className="col-6">
                <label>{lang.to_date_label} *</label>
                <input
                  type={"date"}
                  name={"ToDate"}
                  placeholder={""}
                  label={lang.from_date_label}
                  onChange={onChange}
                  value={dataItem.ToDate}
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <label>{lang.channel_label} *</label>
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={channel}
                  name="Channel"
                  textField="FullChannelName"
                  dataItemKey="_id"
                  value={dataItem.Channel}
                  onChange={onChange}
                />
              </div>
              <div className="col-6">
                <label>{lang.day_part_label} *</label>
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={dayParts}
                  name="DayPart"
                  textField="Description"
                  dataItemKey="_id"
                  value={dataItem.DayPart}
                  onChange={onChange}
                />
              </div>
            </div>
            {/* <div className="row mt-2">
              <div className="col-6">
                <label>{lang.days_label} *</label>
                <MultiSelect
                  style={{
                    backgroundColor: "white",
                  }}
                  data={DAYS}
                  name="Days"
                  textField="Name"
                  dataItemKey="ID"
                  value={dataItem.Days}
                  onChange={onChange}
                  validator={(value) =>
                    value ? "" : "Please select the value"
                  }
                />
              </div>
              <div className="col-3">
                <input
                  type={"checkbox"}
                  name={"EnableFilter"}
                  style={{ marginTop: "14px",marginLeft: "5px" }}
                  onChange={() => { }}
                  value={dataItem.EnableFilter}
                  checked={dataItem.EnableFilter}
                  onClick={handleChange}
                />
                <label className="ml-1">{lang.enable_filter_label}</label>
              </div>
              <div className="col-2 mt-2 ml-3">
                {enableFilter && 
                <PlanningFilter toGetFilterDataItem={toGetFilteredData} onClear = {() => {}} hideChannel = {true}  saveButtonName={'Save'} showTemplate={false} filterDataItem={dataItem.Filter}/>
                }
              </div>
            </div> */}
            {
              <div className="row mt-3">
                <div className="col-10" style={{ padding: "0px 0px 0px 16px" }}>
                  <div className="form-group">
                    <label htmlFor="TabView">{lang.select_rule_label}</label>
                    <input
                      name="EnableFilter"
                      type="text"
                      className="form-control form-control-sm"
                      value={filterData?.Template?.Name ?? ''}
                      onChange={onChange}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-2" style={{ padding: "0px 16px 0px 0px" }}>
                  <ActionButton
                    style={{ marginTop: "18px", height: "30px" }}
                    btnColor={"success"}
                    title={lang.select_button_tooltip}
                    name={"..."}
                    onClick={() => setEnableFilter(true)}
                  />
                  <ActionButton
                    style={{ marginTop: "18px", height: "30px" }}
                    btnColor={"danger"}
                    title={lang.select_button_tooltip}
                    name={""}
                    icon={"xmark"}
                    onClick={() => setFilterData(undefined)}
                  />
                </div>
              </div>
            }

            {
              <div className="row mt-1">
                <div className="col-10" style={{ padding: "0px 0px 0px 16px" }}>
                  <div className="form-group">
                    <label htmlFor="TabView">{lang.repeat_pattern_label} <InfoRoundButton message={lang.show_repeat_pattern_detail_tooltip} icon={'eye'} onClick={showRepeatPattern} />
                    </label>
                    <input
                      name="Title"
                      type="text"
                      className="form-control form-control-sm"
                      value={dataItem.RepeatPattern.Name}
                      onChange={() => ""}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-2" style={{ padding: "0px 16px 0px 0px" }}>
                  <ActionButton
                    style={{ marginTop: "18px", height: "30px" }}
                    btnColor={"success"}
                    title={lang.select_button_tooltip}
                    name={"..."}
                    onClick={onSelectRepeatPattern}
                  />
                  <ActionButton
                    style={{ marginTop: "18px", height: "30px" }}
                    btnColor={"danger"}
                    title={lang.select_button_tooltip}
                    name={""}
                    icon={"xmark"}
                    onClick={() =>
                      setDataItem({ ...dataItem, RepeatPattern: { Name: "" } })
                    }
                  />
                </div>
              </div>
            }

            <div className="row mt-3">
              <div className="col-6">
                <input
                  type={"checkbox"}
                  name={"Archive"}
                  style={{ marginTop: "10px" }}
                  onChange={(e) => onChange(e)}
                  value={dataItem.Archive}
                  checked={dataItem.Archive}
                />
                <label className="ml-1">{lang.archive}</label>
              </div>
            </div>
          </div>
        </div>
        {openRepeatPatternSelection && (
          <CollectionSelection
            addButtonTitle={lang.select_button_text}
            title={lang.select_repeat_pattern_collection_header}
            entityname={ENTITYNAME.RepeatPattern}
            closeForm={() =>
              setOpenRepeatPatternSelection(!openRepeatPatternSelection)
            }
            setDataList={(dataList) => onSetSelectedRepeatPattern(dataList)}
            mode={"single"}
            width={"50vw"}
          />
        )}
        {enableFilter && (
          <PlanningFilter
            toGetFilterDataItem={toGetFilteredData}
            hideChannel={true}
            saveButtonName={"Save"}
            showDefaultButton={false}
            filterDataItem={filterData}
            setTemplate={setDataItem}
            onClose={setEnableFilter}
          />
        )}
        {showMediaPreview && <Dialog title={"Available Media Library"} onClose={()=> { setShowMediaPreview(false); setIsLoading(true);}} width={"50vw"}>
        {isLoading && <Loader height="40vh" />}
        {!isLoading && <Grid data={mediaLibraryData} style={{ height: "400px" }} rowHeight={50} resizable={true}>
          <GridColumn field={'Title'} title={'Title'} />
          <GridColumn field={'AssetId'} title={'Asset Id'} />
          <GridColumn field={'MediaCategory.Description'} title={'Media Category'} />
          {/* <GridColumn field={'Genres'} title={'Genres'} cell={ArrayCell} format="Description" /> */}
          <GridColumn field={'Duration'} title={'Duration'} cell={TimeCell} sortable={true} />
        </Grid>}
      </Dialog>}
      </Dialog>


    </>
  );
};

export default PlanningSlotEditForm;
