import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useEffect, useState } from 'react'
import { EditPageHeader } from '../../../components/EditPageHeader';
import { ENTITYNAME, MILLISECONDSINADAY, PLANNINGTYPE, PLATFORMTYPE } from '../../../framework/constant/constant';
import { useTranslation } from '../../../locale/useTranslation';
import ActionButton from '../../../framework/forms/helpercomponents/buttons/ActionButton';
import { TimePickerWithFormat } from '../../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import * as API from '../../../framework/API/api';
import { toast } from 'react-toastify';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ContractCollectionSelection from '../../Contract/ContractCollectionSelection';
import { utility } from '../../../framework/utility/utilityProvider';
import moment from 'moment';
import { PlanningHelper } from '../../Planning/helper/PlanningHelper';
import { MediaDealRights_columns } from '../../../framework/utility/customColumns';
import CollectionSelection from '../../../framework/forms/CollectionSelection';

const AddProgramSchedulePopup = (props) => {

    const lang = useTranslation();

    const [dataItem, setDataItem] = useState({});

    const [segments, setSegments] = useState([]);

    const [ showPopups, setShowPopups] = useState({
        mediaCollectionSelection : false,
        mediaDealRightPopup : false,
    })

    const [scheduleDateRange, setSchedhuleDateRange] = useState({});

    const [note,setNote] = useState('');

    useEffect(() => {

        setDataItem({
            MediaEpisode: undefined,
            Date : moment(props.date).format("YYYY-MM-DD"),
            Time: utility.convertMilisecondsToStringWithFrames(props.offsetTime) ?? '00:00:00:00',
            Segment: {}
        })

        console.log(props.offsetTime)

        let msg = `Note : Please select Date & Time b/w ${moment(props.date).format("YYYY-MM-DD")} 00:00:00:00 - ${moment(props.date).format("YYYY-MM-DD")} 23:59:59:59`

        if(props.offsetTime && props.offsetTime > 0){
            setNote(`Note : Please select Date & Time b/w ${moment(props.date).format("YYYY-MM-DD")} ${utility.convertMilisecondsToStringWithFrames(props.offsetTime)}  - ${moment(props.date).add(1, "days").format("YYYY-MM-DD")} ${utility.convertMilisecondsToStringWithFrames(props.offsetTime)}`)

            setSchedhuleDateRange({
                StartDateTime : utility.convertStringDatetoMilliseconds(moment(props.date).format("YYYY-MM-DD")) + props.offsetTime,
                EndDateTime : utility.convertStringDatetoMilliseconds(moment(props.date).add(1, "days").format("YYYY-MM-DD")) + props.offsetTime,
            })
        } else {
            setNote(msg)
            setSchedhuleDateRange({
                StartDateTime : utility.convertStringDatetoMilliseconds(moment(props.date).format("YYYY-MM-DD")) + props.offsetTime,
                EndDateTime : utility.convertStringDatetoMilliseconds(moment(props.date).format("YYYY-MM-DD")) + props.offsetTime + MILLISECONDSINADAY,
            })
        }

        
    },[])

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const onMediaEpisodeSelection = async (dataList) => {
        setDataItem({ ...dataItem, MediaEpisode: dataList[0] })

        //FETCH SEGMENTS
        let segmentRes = await API.getData(ENTITYNAME.MediaEpisodeSegment, { query: [["MediaEpisodeSID", "=", dataList[0].SID]] });
        if (segmentRes.success) {
            console.log(segmentRes)
            setSegments(segmentRes.data.map((x) => { return { ...x,Description : x.SegmentType?.Description } }));
        } else {
            toast.error(segmentRes.message);
        }
    }

    const isValid = () => {

        if(!dataItem.MediaEpisode || Object.keys(dataItem.MediaEpisode).length == 0){
            toast.error("Please select Media Episode");
            return;
        }

        if(!dataItem.Time || dataItem.Time.length == 0){
            toast.error("Please enter time");
            return;
        }

        //CHECKING THE GIVEN INPUTS SHOULD BE IN OPENED SCHEDULE RANGE
        let slotDateTime = utility.convertStringDatetoMilliseconds(dataItem.Date) + utility.convertStringWithFramesToMilliseconds(dataItem.Time);

        if(slotDateTime < scheduleDateRange.StartDateTime || slotDateTime > scheduleDateRange.EndDateTime){
            toast.error(note);
            return;
        }
        
        //CHECKING IF SOMETHING IS ALREADY PLANNED
        let alreadyExisting = props.schedulerData.find((x) => x.SlotDateTime == slotDateTime && x.Channel.SID == props.channel.SID);
        if(alreadyExisting){
            toast.error(`${alreadyExisting?.mediaEpisode?.Title} is already planned on this time`);
            return;
        }
        
        //NEED TO ASK DEAL RIGHTS
        setShowPopups({ ...showPopups, mediaDealRightPopup: true });

    }

    const selectMediaDealRight = (data) => {

        if(data.length > 0){
            onSave(data[0]);
        } else { 
            onSave();
        }        
        
    }

    const onSave = async (mediaRights = null) => {

        let slotDateTime = utility.convertStringDatetoMilliseconds(dataItem.Date) + utility.convertStringWithFramesToMilliseconds(dataItem.Time);

        let planningItem = {
            SID: 0,
            Channel: props.channel,
            MediaEpisode_id: dataItem.MediaEpisode._id,
            ScheduleDate: slotDateTime,
            SlotDateTime: slotDateTime,
            Segment_id: dataItem?.Segment?._id ?? null,
            ParentProgramSchedule_id: null,
            ActualDuration: dataItem.MediaEpisode.Duration,
            NominalDuration: dataItem.MediaEpisode.Duration,
            IsValid: PlanningHelper.isMediaEpisodeValidForThisChannel(dataItem.MediaEpisode, props.channel),
            MediaDealRight_id: mediaRights ? mediaRights?._id : null,
            ExhibitionNumber: 0,
            RepeatNumber: 0,
            PlanningType: PLANNINGTYPE.Primary,
            InvalidReason: "",
            Description: dataItem.MediaEpisode.Description,
            ChannelSID: props.channel.SID,
            mediaEpisode: dataItem.MediaEpisode,
            Title: dataItem.MediaEpisode.Title,
        }

        console.log(planningItem); 

        var res = await API.savePlanning(
            PlanningHelper.prepareDataforbackend({ ...planningItem }),
            segments, false, false, dataItem.MediaEpisode.SID
        );

        if (!res.success) {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            
            //REFRESH
            props.onScheduleRefresh();
            props.onClose();

        }


    }


    return (
        <Dialog title={"Add Program"} onClose={props.onClose} width={"770px"} >
        <EditPageHeader title={"Add"} onSubmit={isValid} onCancel={props.onClose} showTitle={false} />
            <div className="row mt-1">
                <div className="col-10" style={{ padding: "0px 0px 0px 16px" }}>
                    <div className="form-group">
                        <label htmlFor="TabView">Media Episode *</label>
                        <input
                            name="Title"
                            type="text"
                            className="form-control form-control-sm"
                            value={dataItem?.MediaEpisode?.Title ?? ''}
                            onChange={onChange}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="col-2" style={{ padding: "0px 16px 0px 0px" }}>
                    <ActionButton
                        style={{ marginTop: "18px", height: "30px" }}
                        btnColor={"success"}
                        title={lang.select_button_tooltip}
                        name={"..."}
                        onClick={() => setShowPopups({
                            ...showPopups,
                            mediaCollectionSelection: true
                        })}
                    />
                    <ActionButton
                        style={{ marginTop: "18px", height: "30px" }}
                        btnColor={"danger"}
                        title={lang.select_button_tooltip}
                        name={""}
                        icon={"xmark"}
                        onClick={() => setDataItem({ ...dataItem, MediaEpisode: undefined })}
                    />
                </div>
            </div>

            <div className="row">
            <div className="col-4">
                <label>{lang.date_label} *</label>
                <input
                  type={"date"}
                  name={"Date"}
                  placeholder={""}
                  label={lang.date_label}
                  onChange={onChange}
                  value={dataItem.Date}
                  className="form-control form-control-sm"
                />
              </div>
                <div className="col-4">
                    <label htmlFor="">{lang.time_column} *</label>
                    <TimePickerWithFormat
                        className="form-control form-control-sm"
                        name="Time"
                        value={dataItem.Time}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="col-4">
                    <label>{lang.segment_label}</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={segments}
                        name="Segment"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.Segment}
                        onChange={onChange}
                        disabled={segments.length <= 0}
                    />
                </div>
            </div>
            <p className="text-muted mt-3" style={{ fontSize: "10px",margin : '0px' }}>{note}</p>

            {showPopups.mediaCollectionSelection && <ContractCollectionSelection addButtonTitle="Select" title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong', '=', true]} setDataList={onMediaEpisodeSelection} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setShowPopups({ ...showPopups, mediaCollectionSelection: false })}  mode = {"single"} width={"50vw"} height={"66vh"} hideSeriesSeason={true} />}

            {showPopups.mediaDealRightPopup && (

                <div className="row" style={{ marginLeft: "-02px", width: "570px" }}>
                <div className="col-12">
                    <CollectionSelection
                    title={lang.please_choose_one_publishing_rights_collection_header}
                    entityname={ENTITYNAME.MediaDealRights}
                    customColumns = {MediaDealRights_columns}
                    wherestatement={[
                        [ "media_id", "=", dataItem.MediaEpisode._id], 
                        ["PlatformType.SID", '=', PLATFORMTYPE[0].SID]
                    ]}
                    setDataList={selectMediaDealRight}
                    mode={"single"}
                    closeForm={() => setShowPopups({ ...showPopups, mediaDealRightPopup: false })}
                    />
                </div>
                </div>

                )}
        </Dialog>
    )
}

export default AddProgramSchedulePopup