/* eslint-disable */
import React, { useEffect, useState } from "react";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "../../locale/useTranslation";
import * as API from '../../framework/API/api';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EditPageHeader } from "../../components/EditPageHeader";
import { ENTITYNAME, LOCALSTORAGE_KEY, PRODUCTS } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { OttPosterCollectionSelectionColumns } from "../../framework/utility/customColumns";
import { ImageBrowser } from "../../framework/forms/helpercomponents/selectFile/ImageBrowser";

export const CompanyForm = (props) => {

    const navigate = useNavigate();
    const lang = useTranslation();
    const { SID } = useParams();

    let initialValue = {
        SID: 0,
        Name: "",
        LogoUrl: 'https://media.comicbook.com/files/img/default-movie.png',
        Language: {},
        CampaignPrefix: "",
        NumberLength: 0,
        HeaderAddress: "",
        FooterAddress: "",
        width: 50,
        Products: props?.item?.Products ? props.item.Products.map((x) => { return { _id: x, Description: Object.keys(PRODUCTS).find((y) => PRODUCTS[y] == x) } }) : [],
        TimeZone: {},
        Archive: false,
        SessionTimeOut: '00:00:00:00',
        segByRc: false,
        disableLiveEventSection : false,
        invoiceHeaderAddressFontSize: 0.6,
        invoiceSpotsDetailsFontSize: 8,
    }

    const [dataItem, setDataItem] = useState(initialValue);
    const [languages, setLanguages] = useState([]);
    const [openImageBank, setOpenImageBank] = useState(false);
    const [timezone, setTimeZone] = useState([]);
    const [imageFiles, setImageFiles] = useState(new Set());
    const [instances, setInstances] = useState([]);

    useEffect(() => {
        pageRender();
    }, []);

    const pageRender = async () => {
        await loadcombo()
        if(SID > 0) await loadEditData();
    }

    const onChange = (e) => {
        if (e.target.name == 'LogoUrl' && e.target?.files) {
            if (e.target.files != undefined) {
                setImageFiles(e.target.files[0]);
            } else {
                setImageFiles(new Set());
            }
        }
        if (e.target.name == "segByRc") {
            setDataItem({ ...dataItem, segByRc: !dataItem.segByRc });
        } else if (e.target.name == "disableLiveEventSection") {
            setDataItem({ ...dataItem, disableLiveEventSection: !dataItem.disableLiveEventSection });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const loadcombo = async () => {
        const languages = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        setLanguages(languages.data);
        const timeZone = await API.getDataLookup(ENTITYNAME.TimeZone, { sort: { Description: 1 } });
        setTimeZone(timeZone.data);
        const instanceRes = await API.getData(ENTITYNAME.Instance);
        setInstances(instanceRes.data);
    }

    const loadEditData = async () => {
        let res = await API.getEntity(ENTITYNAME.Company, parseInt(SID));
        if (res.success) {
            setDataItem({
                ...res.data,
                Products: res.data.Products ? res.data.Products.map((x) => { return { _id: x, Description: Object.keys(PRODUCTS).find((y) => PRODUCTS[y] == x) } }) : [],
                SessionTimeOut: res.data.SessionTimeOut ? utility.convertMilisecondsToStringWithFrames(res.data.SessionTimeOut) : '00:00:00:00'
            });
        }else{
            toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const isValid = () => {
        if (dataItem.Name == "" || dataItem.Name == undefined) {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Language == undefined || Object.keys(dataItem.Language).length == 0) {
            toast.error(`${lang.please_select_language_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.SessionTimeOut.includes("_") || utility.convertStringWithFramesToMilliseconds(dataItem.SessionTimeOut) == 0) {
            toast.error(`${lang.please_enter_session_time_out_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (utility.convertStringWithFramesToMilliseconds(dataItem.SessionTimeOut) < 3600000) {
            toast.error(`${lang.session_time_out_should_be_greater_than_one_hour_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Products == undefined || dataItem.Products.length == 0) {
            toast.error(`${lang.please_select_at_least_one_product_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.LogoUrl == undefined || Object.keys(dataItem.LogoUrl).length == 0) {
            toast.error(`${lang.please_select_logo_url_first_error_mesage}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.CampaignPrefix == undefined || dataItem.CampaignPrefix == "") {
            toast.error(`${lang.please_enter_campaign_prefix_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.NumberLength == undefined || dataItem.NumberLength == "") {
            toast.error(`${lang.please_enter_number_length_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        if (!isValid()) return;

        let logoUrl = dataItem.LogoUrl;
        if (imageFiles.size > 0) {
            let resUrl = await API.upladImage(imageFiles);
            if (!resUrl.success) {
                toast.error(resUrl.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
            logoUrl = resUrl.data;
            dataItem.LogoUrl = logoUrl;
        }

        let localData = {
            SID: dataItem.SID ?? 0,
            Name: dataItem.Name,
            Language: { SID: dataItem.Language.SID, Description: dataItem.Language.Description, ISOCode: dataItem.Language.ISOCode },
            LogoUrl: logoUrl,
            CampaignPrefix: dataItem.CampaignPrefix,
            NumberLength: parseInt(dataItem.NumberLength),
            HeaderAddress: dataItem.HeaderAddress ?? "",
            FooterAddress: dataItem.FooterAddress ?? "",
            width: parseInt(dataItem.width) ?? 50,
            SessionTimeOut: utility.convertStringWithFramesToMilliseconds(dataItem.SessionTimeOut) ?? 0,
            Products: dataItem.Products ? dataItem.Products.map((x) => x?._id) : [],
            TimeZone: dataItem.TimeZone ? { _id: dataItem.TimeZone._id, SID: dataItem.TimeZone.SID, Description: dataItem.TimeZone.Description, Offset: dataItem.TimeZone.Offset, IsNegativeOffset: dataItem.TimeZone.IsNegativeOffset } : {},
            Archive: false,
            segByRc: dataItem.segByRc ?? false,
            disableLiveEventSection : dataItem.disableLiveEventSection ?? false,
            Instance: dataItem?.Instance ? { _id: dataItem?.Instance?._id, Name: dataItem?.Instance?.Name } : {},
            invoiceHeaderAddressFontSize: parseFloat(dataItem?.invoiceHeaderAddressFontSize) ?? 0.6,
            invoiceSpotsDetailsFontSize: parseInt(dataItem?.invoiceSpotsDetailsFontSize) ?? 8
        }
        console.log(localData);
        const res = await API.saveData(ENTITYNAME.Company, localData);
        if (res.success) {
            utility.deleteLocalStorageItem(LOCALSTORAGE_KEY.COMPANY);
            utility.setValue(LOCALSTORAGE_KEY.COMPANY,localData)
            toast.success(lang.successfully_saved_message, { position: toast.POSITION.TOP_RIGHT });
            navigate(-1);
        } else {
            toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const setSelectedImage = (dataList) => {
        setDataItem({ ...dataItem, LogoUrl: dataList[0].ImageUrl });
    }

    const borderStyle = {
        border: "1px solid #ced4da", /* Your existing border styles */
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", /* Add a subtle box shadow for a 3D effect */
        backgroundColor: "#FAFAFA"
    }

    const handleLogoImageSelect = (dataList) => {
        setDataItem({ ...dataItem, LogoUrl: dataList[0].ImageUrl });
    }

    return (
        <>
            <div className="row mb-4">
                <div className="col-12">
                    <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={() => navigate(-1)} showTitle={false} />
                    <span>{lang.company_setting_label}</span>
                    <div className="border p-2 form-group with-3d-effect" style={borderStyle}>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="">{lang.name_label} *</label>
                                <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name} onChange={(e) => onChange(e)} />
                            </div>
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.language_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={languages}
                                    name="Language"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Language}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-6">
                                <label htmlFor="">{lang.session_time_out_label} *</label>
                                <TimePickerWithFormat
                                    className="form-control form-control-sm"
                                    name="SessionTimeOut"
                                    value={dataItem.SessionTimeOut}
                                    onChange={(e) => onChange(e)}
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="">{lang.products_label} *</label>
                                <MultiSelect
                                    data={Object.keys(PRODUCTS).map((obj) => { return { _id: PRODUCTS[obj], Description: obj } })}
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Products}
                                    onChange={onChange}
                                    name={"Products"}
                                />
                            </div>
                        </div>
                        <div className="row mt-1 mb-2">
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.time_zone_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={timezone}
                                    name="TimeZone"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.TimeZone}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.instance_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={instances}
                                    name="Instance"
                                    textField="Name"
                                    dataItemKey="_id"
                                    value={dataItem.Instance}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                        <   div className="col">
                                <input type="checkbox" name="segByRc" value={dataItem.segByRc} checked={dataItem.segByRc} onChange={(e) => onChange(e)} />
                                <label className="pl-1 pr-3" htmlFor="">{lang.segment_by_rc_label}</label>
                                <input type="checkbox" name="disableLiveEventSection" value={dataItem.disableLiveEventSection} checked={dataItem.disableLiveEventSection} onChange={(e) => onChange(e)} />
                                <label className="pl-1" htmlFor="">{lang.disable_live_event_section_label}</label>
                            </div>
                        </div>
                    </div>
                    <span>{lang.invoice_label}</span>
                    <div className="border form-group p-2 with-3d-effect" style={borderStyle}>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="">{lang.logo_url_label} *</label>
                                <div className="row">
                                    <div className="col-10" style={{ padding: '0px 5px 0px 15px' }}><ImageBrowser wherestatement={['OttPosterTypeSID', '=', 4]} name={'LogoUrl'} value={dataItem.LogoUrl} onChange={onChange} setSelectedImage={handleLogoImageSelect} /></div>
                                    <div className="col-2" style={{ padding: 0 }}><img src={dataItem.LogoUrl} alt="Logo Url" width="100" /></div>
                                </div>

                            </div>
                            <div className="col-6">
                                <label htmlFor="">{lang.logo_width_label}</label>
                                <span className="text-muted float-right">%</span>
                                <input type="text" className="form-control form-control-sm" name="width" value={dataItem.width} onChange={(e) => onChange(e)} />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-6">
                                <label htmlFor="">{lang.header_address_label}</label>
                                <input type="text" className="form-control form-control-sm" name="HeaderAddress" value={dataItem.HeaderAddress} onChange={(e) => onChange(e)} />
                            </div>
                            <div className="col-6">
                                <label htmlFor="">{lang.header_address_font_size_label}</label>
                                <span className="text-muted float-right">rem</span>
                                <input type="text" className="form-control form-control-sm" name="invoiceHeaderAddressFontSize" value={dataItem.invoiceHeaderAddressFontSize} onChange={(e) => onChange(e)} />
                            </div>
                        </div>
                        <div className="row mt-1 mb-2">
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.footer_address_label}</label>
                                <input type="text" className="form-control form-control-sm" name="FooterAddress" value={dataItem.FooterAddress} onChange={(e) => onChange(e)} />
                            </div>
                            <div className="col-6">
                                <label htmlFor="">{lang.spots_details_font_size_label}</label>
                                <span className="text-muted float-right">px</span>
                                <input type="text" className="form-control form-control-sm" name="invoiceSpotsDetailsFontSize" value={dataItem.invoiceSpotsDetailsFontSize} onChange={(e) => onChange(e)} />
                            </div>
                        </div>
                    </div>
                    <span>{lang.sales_label}</span>
                    <div className="border form-group p-2 with-3d-effect" style={borderStyle}>
                        <div className="row mb-2">
                            <div className="col-6">
                                <label htmlFor="">{lang.campaign_prefix_label} *</label>
                                <input type="text" className="form-control form-control-sm" name="CampaignPrefix" value={dataItem.CampaignPrefix} onChange={(e) => onChange(e)} />
                            </div>
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.number_length_label} *</label>
                                <input type="number" className="form-control form-control-sm" name="NumberLength" value={dataItem.NumberLength} onChange={(e) => onChange(e)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openImageBank && <CollectionSelection entityname={ENTITYNAME.OttPosters} customColumns={OttPosterCollectionSelectionColumns} title={lang.select_url_tooltip} closeForm={() => setOpenImageBank(!openImageBank)} setDataList={setSelectedImage} mode={"single"} width={"50vw"} height={"64vh"} />}

        </>
    )
}