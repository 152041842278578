/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { ENTITYNAME, PLAYLIST_SCHEDULE_TYPE, PLAYLIST_STATUS } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";
import { Menubar } from 'primereact/menubar';
import contextMenuItem from "../../../components/contextMenuItem";
import { useNavigate } from "react-router-dom";
import { PlaylistHelper } from "./PaylistHelper";
import { utility } from "../../../framework/utility/utilityProvider";
import BackRoundButton from "../../../framework/forms/helpercomponents/buttons/BackRoundButton";
import { LabelGroup } from "../labelGroup";
import { createDuplicatePlaylist, getData, saveData } from "../../../framework/API/api_digitalSign";
import { toast } from "react-toastify";
export const PlaylistHeader = (props) => {

    const lang = useTranslation();
    const {
        onCut,
        onCopy,
        onPaste,
        onDelete,
        onPlaylistRefresh,
        playlistData,
        onPlaylistNameChange,
        setShowScreenGroupPopup,
        onPlaylistAction,
        onSchedulePlaylistClick
    } = props
    const navigate = useNavigate();

    const createPlaylistCopy = async () => {
        let playlistRes = await createDuplicatePlaylist(playlistData._id);
        if (!playlistRes.success) {
            toast.error('Error creating Duplicate Playlist. Try again');
            return;
        }
        navigate(`/home/PlaylistItem/${playlistRes.data}`);
    }

    const createNewPlaylist = async () => {
        let playlistId = await createDummyPlaylist();
        if (playlistId) {
            navigate(`/home/PlaylistItem/${playlistId}`);
        }
    }

    const createDummyPlaylist = async () => {
        let screenGroup = [];
        let playlistName = 'Untitled Playlist';

        let screenGroupRes = await getData(ENTITYNAME.DigitalSignScreenGroup);
        if (!screenGroupRes.success) {
            toast.error('Error creating new Playlist. Try again');
            return null;
        }
        if (screenGroupRes.data.length > 0) {
            screenGroup.push(screenGroupRes.data[0]);
        } else {
            let screenGroupSaveRes = await saveData(ENTITYNAME.DigitalSignScreenGroup, { SID: 0, Name: 'Default' });
            if (!screenGroupSaveRes.success) {
                toast.error('Error creating new Playlist. Try again');
                return null;
            } else {
                screenGroup.push(screenGroupSaveRes.data);
            }
        }



        //PLAYLIST DATA SCHEMA
        let dataToSave = {
            SID: 0,
            Name: playlistName,
            Status: PLAYLIST_STATUS.Standby,
            screenGroup: screenGroup.map(s => s._id),
            Schedule: {
                ScheduleType: PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover,
                StartDate: null,
                EndDate: null,
                isNoEndDate: true,
            }
        }

        let res = await saveData(ENTITYNAME.DigitalSignPlaylist, dataToSave);
        if (res.success) {

            let dummyChildren = [{
                SID: 0,
                _id: "dummy_id",
                content: {
                    fileName: 'Empty Playlist'
                },
                content_id: "",
                playlist_id: res.data?._id,
                sequenceIndex: 0,
                isDummy: true,
                overlay: [],
            }]
            toast.success('New playlist created');
            return res.data?._id;
        } else {
            toast.error('Error creating new Playlist. Try again');
            return null;
        }
    }

    const nameRef = useRef();
    const [name, setName] = useState(playlistData?.Name ?? '');
    const [status, setStatus] = useState(playlistData?.Status ?? PLAYLIST_STATUS.Standby);

    useEffect(() => {
        setName(playlistData?.Name ?? '');
        setStatus(playlistData?.Status ?? PLAYLIST_STATUS.Standby);
        const input = document.getElementById('dynamicWidthInput');
        const tester = document.getElementById('textWidthTester');
        setTimeout(() => {
            adjustWidth(input, tester);
        }, 500);
        input.addEventListener('input', () => adjustWidth(input, tester));
        return () => input.removeEventListener('input', () => adjustWidth(input, tester));
    }, [playlistData])

    const handleManageScreenGroup = async () => {
        setShowScreenGroupPopup(true);
    }

    const handlePlaylistRefresh = async (item) => {
        await PlaylistHelper.onRefreshPlaylist(item);
    }

    const fileMenu = PlaylistHelper.getPlaylistMenu({
        dataItem: playlistData,
        enterEdit: createPlaylistCopy,
        onDelete: () => { }, //handleDelete,
        onSchedulePlaylist: onSchedulePlaylistClick,
        onManageScreenGroup: handleManageScreenGroup,
        onPlaylistAction: onPlaylistAction,
        onRefreshPlaylist: handlePlaylistRefresh

    }).filter(Boolean);

    const { publish, showPlayingOnce, showPlayingLoop, showPublishUnPublish, showStop } = PlaylistHelper.getValidPlayButtons(playlistData);

    const items = [
        {
            label: 'File',
            items: [
                {
                    icon: 'pi pi-plus',
                    label: 'New Playlist',
                    command: () => {
                        createNewPlaylist();
                    }
                },
                ...fileMenu.slice(1, fileMenu.length)
            ]
        },
        {
            label: 'Edit',
            items: [
                {
                    label: 'Cut',
                    icon: 'fa fa-cut',
                    shortcut: 'Ctrl + X',
                    command: () => {
                        onCut();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Copy',
                    icon: 'pi pi-copy',
                    shortcut: 'Ctrl + C',
                    command: () => {
                        onCopy();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Paste',
                    icon: 'fa fa-paste',
                    shortcut: 'Ctrl + V',
                    command: () => {
                        onPaste();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    shortcut: 'del',
                    command: () => {
                        onDelete();
                    },
                    template: contextMenuItem,
                },
            ].filter(Boolean)
        },
        {
            label: 'View',
            items: [
                {
                    icon: 'pi pi-refresh',
                    label: 'Refresh Playlist',
                    shortcut: 'F5',
                    command: () => {
                        onPlaylistRefresh();
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Screens",
                    icon: 'pi pi-stop',
                    command: () => {
                        handleManageScreenGroup();
                    },
                    template: contextMenuItem,
                },
            ]
        },
    ];

    function adjustWidth(input, tester) {
        // Copy the input's styles to the tester div
        const styles = window.getComputedStyle(input);
        tester.style.fontSize = styles.fontSize;
        tester.style.fontFamily = styles.fontFamily;
        tester.style.fontWeight = styles.fontWeight;
        tester.style.letterSpacing = styles.letterSpacing;
        tester.style.padding = styles.padding;
        tester.style.border = styles.border;

        // Set the tester div's text to the input's value
        tester.textContent = input.value || input.placeholder;

        // Adjust the input's width to match the tester div's width
        input.style.width = tester.offsetWidth + 'px';
    }

    return <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 className="page-edit-title txt-color-blueDark" style={{ margin: '0px 0px 0px 0px', fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BackRoundButton onClick={() => navigate("/home/Playlist")}></BackRoundButton>
                    <i className="fa-regular fa-calendar-days ml-1" style={{ margin: '0px 5px 0px 0px' }}></i>
                    <input id="dynamicWidthInput" ref={nameRef} value={name} onChange={(e) => setName(e.target.value)} onKeyUp={(e) => {
                        if (e.key == 'Enter') {
                            nameRef.current.blur();
                        }
                    }} onBlur={() => { onPlaylistNameChange(name); }} style={{ flex: '1 1 auto' }} />

                    <div id="textWidthTester" style={{ position: "absolute", visibility: "hidden", height: 0, overflow: "hidden", whiteSpace: "nowrap" }}></div>
                </div>
                <div className="badge text-success mr-3 pt-2 pb-2 pl-0" style={{ fontSize: '13px', fontWeight: "bold", whiteSpace: 'nowrap' }}>
                    - {(utility.convertFirstLetterToUpperCase(utility.getKeyByValue(PLAYLIST_STATUS, status)))}
                </div>
            </h3>
            <div style={{ position: "relative", top: "10px" }}>
                <LabelGroup value={playlistData?.screenGroup?.map(s => s.Name) ?? []} onClick={handleManageScreenGroup} />
            </div>
        </div>

        <div className="pl-2" style={{ display: "flex", }}>
            <div style={{ zIndex: 999 }}>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                    <Menubar style={{ height: "40px", zIndex: 999, background: "transparent", border: "none", marginLeft: "-8px", padding: '8px 8px 8px 0px' }} model={items} />
                    <i className="fa fa-repeat cursor-pointer" style={{ marginTop: '10px' }} title="Refresh" aria-hidden="true" onClick={onPlaylistRefresh}></i>
                    <i className={`ml-3 cursor-pointer fa fa-${(showPlayingOnce || showPlayingLoop) ? 'play' : showStop ? 'stop' : (showPublishUnPublish && publish) ? 'download' : 'upload'}`} style={{ marginTop: '10px' }} aria-hidden="true" onClick={
                        () => {
                            if (showPlayingOnce) {
                                onPlaylistAction(playlistData, PLAYLIST_STATUS.PlayingOnce);
                            } else if (showPlayingLoop) {
                                onPlaylistAction(playlistData, PLAYLIST_STATUS.Playing);
                            } else if (showStop) {
                                onPlaylistAction(playlistData, PLAYLIST_STATUS.Standby);
                            } else if (showPublishUnPublish) {
                                onPlaylistAction(playlistData, publish ? PLAYLIST_STATUS.UnPublished : PLAYLIST_STATUS.Published);
                            }
                        }
                    }
                        title={showPlayingOnce ? 'Play Once' : showPlayingLoop ? 'Play' : showStop ? 'Stop' : publish ? 'Un-Publish' : 'Publish'}
                    />
                </div>
            </div>
        </div>
    </div>
}