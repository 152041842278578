/* eslint-disable */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import * as API from "../../framework/API/api_digitalSign";
import { DateOnlyCell, LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { COLUMNSTYPE, LOCALSTORAGE_KEY } from "../../framework/constant/constant";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { toast } from 'react-toastify';
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import SaveButton from "../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { EnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const DigitalSignCollectionSelection = memo((props) => {

  const lang = useTranslation();

  let wherestatement = props?.wherestatement;
  let andWhereStatement = props?.andWhereStatement;

  const [gridData, setGridData] = useState([]);
  const [originalGridData, setoriginalGridData] = useState([]);
  const [dataColumns, setDataColumns] = useState(props.customColumns ?? []);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedState, setSelectedState] = useState(
    props.seletedState ? utility.getSelectedStateCollectionObject(props.seletedState, DATA_ITEM_KEY) : {}
  );
  const [count, setCount] = useState(0);
  const gridRef = useRef();
  const per_page = props.perPage ?? 50;
  var page_take = props.page_take ?? 50;
  const [currentPage, setCurrentPage] = useState(1);

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={props.entityname}
      size={"small"}
    />
  );

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  const onSearch = (e) => {
    e.preventDefault();
    let text = e.target.value?.trim();
    setSearchText(text);
  };

  // Search 
  useEffect(() => {
    if (searchText != "") {
      const timeOut = setTimeout(() => {
        loaddata({ per_page: page.take, current_page: 1 }, wherestatement, andWhereStatement, searchText);
      }, 700);
      return () => clearTimeout(timeOut);
    } else {
      setPage({ skip: 0, take: page_take });
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText);
    }
  }, [searchText]);

  useEffect(() => {
    setIsLoading(true)
    setPage({ skip: 0, take: page_take });
    loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText);
  }, []);

  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText) => {
    try {

      let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
      let columns = await API.getFormData(props.entityname, currentUserId);
      if (props?.data?.length > 0) {
        setoriginalGridData(props?.data);
        setGridData(props?.data);
        setCount(props?.data?.length);
        setDataColumns(props?.customColumns ?? columns);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      let json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: 1, query: wherestatement }, columns, searchText);
      setoriginalGridData(json.data);
      setGridData(json.data);
      setCount(json.pagination.total);
      setDataColumns(columns);

    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);

  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);

    }, [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {

    if (props.mode == 'single') {
      toast.info(`${lang.select_any_one_collection_selection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);

  }, []);

  const checkIfItemExist = (item) => {

    let isFound = false;
    let existingEntries = props.existingItems ?? [];
    // console.log("checking duplicate")
    // console.log(item.SID);
    // console.log(props);
    if (props.checkDuplicate ?? false) {
      isFound = existingEntries.some(element => {
        console.log("props");
        console.log(props);
        if (element.SID === item.SID) {
          return true;
        }

        return false;
      });
    }
    // console.log("isFound");
    // console.log(isFound);
    return isFound;

  };

  //to sendDataList to RailsEditForm and to close the collection
  const onSelection = () => {
    let msg = `${lang.please_select_first_collection_error_message}`

    let selectedDataList = [];
    //loop for setting the selected obj in array
    gridData.map((obj) => {
      let key = obj._id;
      //if true means checked
      if (selectedState[key]) {
        // checking duplicate as well
        if (!checkIfItemExist(obj)) {
          selectedDataList.push(obj);
        } else {
          msg = `${lang.already_attached_collection_error_message}`
        }
      }
    })

    if (selectedDataList.length != 0) {
      props.setDataList(selectedDataList);
      props.closeForm();
    } else {
      // alert(msg)
      toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  //load for infinit scroll
  const loadInfiniteData = async () => {
    setCurrentPage(currentPage + 1);
    if (count > originalGridData.length) {
      let json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: currentPage + 1, query: wherestatement }, dataColumns, searchText);
      setoriginalGridData((old) => [...old, ...json.data]);
      setGridData((old) => [...old, ...json.data]);
      setSelectedState({});
    }

  }

  const refreshCollection = () => {
    loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText);
  }

  //for infinite scroll
  const scrollHandler = async (event) => {
    const e = event.nativeEvent;
    if (e.target.className.indexOf('k-grid-content') === -1) {
      return;
    }

    if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
      await loadInfiniteData()
    }
  };

  const onRowDoubleClick = (event) => {
    const item = event.dataItem;

    if (!checkIfItemExist(item)) {
      props.setDataList([item], selectedState);
      props.closeForm();
    } else {
      toast.info(`${lang.duplicate_item_selected_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  return (
    <Dialog
      title={props.title ?? `${lang.add_dialog_header}`}
      onClose={props.closeForm}
      width={props.width ?? "75vw"}
      height={props.height ?? "auto"}
    >
      <div className="flex-container mb-3">
        <div style={{ marginLeft: '-10px' }}>
          <SaveButton title={props.addButtonTitle ?? `${lang.add_button_text}`} style={{ backgroundColor: 'green', borderRadius: "4px" }} onClick={onSelection}></SaveButton>
          <CancelButton onClick={props.closeForm}></CancelButton>
        </div>
        <div className="flex-container-reverse flex-item-auto">
          <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
            <div className="input-group-append">
              <span className="input-group-text">
                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
              </span>
            </div>
          </button>
          <input
            type="text"
            className="form-control shadow-inset-2 pt-0 pb-0"
            id="searchInput"
            placeholder={lang.search_button_text}
            onChange={onSearch}
            style={{ width: '200px' }}
            onFocus={(e) => {
              if (props.setOutSideFocus) {
                props.setOutSideFocus(true);
              }
            }}
            onBlur={(e) => {
              if (props.setOutSideFocus) {
                props.setOutSideFocus(false);
              }
            }}
          />
          <RefreshButton onClick={refreshCollection} style={{ marginRight: "5px", marginTop: "3px" }} />
        </div>
      </div>

      <div>
        {isLoading && <Loader height={props?.Height ?? "40vh"} />}
        {!isLoading &&
          <Grid
            data={gridData.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}

            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: props.mode ?? "multiple",
            }}
            ref={gridRef}
            resizable={true}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            onRowDoubleClick={onRowDoubleClick}
            // onHeaderSelectionChange={props.mode == 'single' ? () => {} : onHeaderSelectionChange}
            style={{ height: props?.height ?? "45vh" }}
            onScroll={scrollHandler}
          >
            <GridColumn
              field={SELECTED_FIELD}
              width="50px"
              headerSelectionValue={
                gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1
              }
              locked={true}
            />
            {dataColumns.map((column, index) => {
              if (column.type === COLUMNSTYPE.date) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={DateOnlyCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
                // }else if (column.name === COLUMNSTYPE.time) {
              } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={TimeCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              } else if (column.name === "SID") {
                return;
                //we are not returning any value for SID column
              } else if (column.type === COLUMNSTYPE.image) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={ImageCell}
                    title={column.label}
                    width={column.width ?? 100}
                  />
                );
              } else if (column.type === COLUMNSTYPE.select) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyDatalookupCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.toggle) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyCustomToggleButton}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.checkbox) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={CheckboxCell}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.array) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={ArrayCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format ?? "Description"} //display item in object
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.href) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={HrefCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.localdatetime) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={LocalDateTimeColumn}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.enum) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={EnumCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format ?? "Description"} //display item in object
                    sortable={true}
                  />
                );
              }
              else {
                return (
                  column.type !== COLUMNSTYPE.hidden && (
                    <GridColumn
                      key={index}
                      field={column.name}
                      title={column.label}
                      width={200}
                    />
                  )
                );
              }
            })}
          </Grid>}
      </div>
      <div className="text-right mt-1">{`${lang.total_label} : ${count}`}</div>
    </Dialog>
  );
});

export default DigitalSignCollectionSelection;
