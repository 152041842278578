/* eslint-disable */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  Grid,
  GridColumn,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useTranslation } from "../../../locale/useTranslation";
import { DateCell, DateOnlyCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { TimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { INGESTION_MODULETYPES, PARTNER_INGESTION_STATUS } from "../../../framework/constant/constant";
import { ExcelExport } from "@progress/kendo-react-excel-export";

const DATA_ITEM_KEY = "Index";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const DataMigrationStatusCell = (props) => {

  const field = props.field || "";

  let value = props.dataItem[field];
  var iconClassName = '';
  var style = {}
  if (value == PARTNER_INGESTION_STATUS.Completed) {
    iconClassName = 'fa-solid fa-circle-check fa-lg';
    style = { color: 'green' }
  } else if (value == PARTNER_INGESTION_STATUS.Failed) {
    iconClassName = 'fa-solid fa-circle-xmark fa-lg';
    style = { color: 'red' }
  } else if (value == PARTNER_INGESTION_STATUS.Pending) {
    // iconClassName = "fas fa-sync fa-spin"
    iconClassName = "fa-solid fa-arrow-up-from-bracket fa-lg"
    style = {}
  } else {
    iconClassName = "fa-solid fa-arrow-up-from-bracket fa-lg"
    style = {}
  }

  return <td style={{ textAlign: 'center' }}>
    <i style={style} className={iconClassName}></i>
  </td>;
};



const PartnerIntegrationDetailCollection = (props, ref) => {

  const lang = useTranslation();
  const [gridData, setGridData] = useState(props.data);
  const [showResponse, setShowResponse] = useState({});
  const [showResponseOpen, setShowResponseOpen] = useState(false);
  const [template, setTemplate] = useState([])
  const [selectedState, setSelectedState] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [succesCount, setSuccessCount] = useState(0);
  const [failureCount, setFailureCount] = useState(0);
  const MyImageCell = (props) => <ImageCell {...props} />;

  const RoundedButton = (props) => <RoundButton icon={"eye"} title={lang.eye_tooltip} onClick={() => handleShowResponse(props.dataItem)} />;
  // const MyDatalookupCell = (props) => (
  //   <LookupCell {...props} dataColumns={dataColumns} />
  //   );

  useEffect(() => {
    if (props.template) {
      let tempTemplate = props.template.ModuleType.SID == INGESTION_MODULETYPES.PlanningGrid ?
        {
          ...props.template, Mapping: [
            {
              "Key": "Time",
              "PartnerKey": "",
              "Format": "hh:mm:ss"
            },
            {
              "Key": "AssetId",
              "PartnerKey": "",
            },
            {
              "Key": "Date",
              "PartnerKey": "",
              "Format": "dd-mm-yyyy"
            },
          ]
        } : props.template
      setTemplate(tempTemplate)
    }
    loaddata();
    setSelectedState({});
  }, [props.data]);


  useImperativeHandle(ref, () => {
    return {
      onDownloadExportReport() {
        excelExport()
      }
    };
  }, []);

  const _export = useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const loaddata = async () => {

    try {
      setGridData(props.data);
      setTotalCount(props.data.length);
      setSuccessCount(props.data.filter((x) => x.success && x.success == PARTNER_INGESTION_STATUS.Completed).length)
      setFailureCount(props.data.filter((x) => x.success && x.success == PARTNER_INGESTION_STATUS.Failed).length)
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);
      onSelection(newSelectedState);

    }
  );

  const handleShowResponse = (item) => {
    setShowResponseOpen(!showResponseOpen);
    setShowResponse(item.SearchResponse);
  }

  const onHeaderSelectionChange = useCallback((event) => {

    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;

    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      // console.log(item);
      newSelectedState[idGetter(item)] = checked;

    });
    setSelectedState(newSelectedState);

    onSelection(newSelectedState);

  });


  //to sendDataList to PartnerIngestion Page through setDataList prop
  const onSelection = (selectedState) => {
    var selectedDataList = [];
    //loop for setting the selected obj in array
    gridData.map((obj) => {
      var key = obj.Index;
      //if true means checked
      if (selectedState[key]) {
        selectedDataList.push(obj);
      }
    })

    props.setDataList(selectedDataList);
  }

  return (
    <div className="col-12">
      <ExcelExport data={gridData} ref={_export}>
        <Grid
          data={gridData.map((item) => {
            return ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            })
          })}

          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: props.mode ?? "multiple",
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          style={{ height: "56vh" }}
          resizable={true}
        >
          <GridColumn
            field={SELECTED_FIELD}
            width="50px"
            headerSelectionValue={
              gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1
            }

          />
          {template?.Mapping?.map((item, index) => {
            if (item.Key.includes("Date")) {
              return (
                <GridColumn
                  key={index}
                  field={item.Key}
                  cell={DateOnlyCell}
                  title={item.Key}
                  format={(item?.Format.includes("HH:mm:ss") ? item?.Format : item?.Format?.toUpperCase()) ?? 'YYYY-MM-DD'}
                  width={200}
                />
              );
            } else if (item.Key.includes("Time")) {
              return (
                <GridColumn
                  key={index}
                  field={item.Key}
                  cell={TimeCell}
                  title={item.Key}
                  format={item?.Format ?? 'hh:mm:ss:ff'}
                  width={130}
                />
              );
            } else if (item.Key.includes("Duration")) {
              return (
                <GridColumn
                  key={index}
                  field={item.Key}
                  cell={TimeCell}
                  title={item.Key}
                  format={item?.Format ?? 'hh:mm:ss:ff'}
                  width={130}
                />
              );
            } else if (item.Key.includes("TcIn")) {
              return (
                <GridColumn
                  key={index}
                  field={item.Key}
                  cell={TimeCell}
                  title={item.Key}
                  format={item?.Format ?? 'hh:mm:ss:ff'}
                  width={130}
                />
              );
            }
            else if (item.Key.includes("TcOut")) {
              return (
                <GridColumn
                  key={index}
                  field={item.Key}
                  cell={TimeCell}
                  title={item.Key}
                  format={item?.Format ?? 'hh:mm:ss:ff'}
                  width={130}
                />
              );
            } else {
              return (
                <GridColumn
                  field={item.Key}
                  title={item.key}
                  width={130}
                />
              )
            }
          })}

          {gridData.length > 0 && (
            <GridColumn
              key={1111}
              field={"success"}
              title={"Status"}
              width={70}
              cell={DataMigrationStatusCell}
            />
          )}
          {gridData.length > 0 && (
            <GridColumn
              key={1212}
              field={"message"}
              title={"Message"}
              width={300}
            />
          )}

          {/* <GridColumn
            field={"Poster"}
            cell={MyImageCell}
            title={"Poster"}
            width={"100px"}
          />

          <GridColumn
            field={"TournamentName"}
            title={"Tournament"}
            width={"130px"}

          />

          <GridColumn
            field={"Tournament_id"}
            title={"Tournament ID"}
            width={"200px"}

          />

          <GridColumn

            field={"Title"}
            title={lang.title_column}
            width={"200px"}

          />

          <GridColumn
            field={"Date"}
            title={"Date"}
            width={"130px"}
          />

          <GridColumn
            field={"Time"}
            title={"Scheduled Time"}
            width={"130px"}
          />

          <GridColumn
            field={"Duration"}
            title={lang.duration_column}
            width={"130px"}
          />

          <GridColumn
            field={"Round"}
            title={"Round"}
            width={"130px"}
          />

          <GridColumn
            field={"Type"}
            title={"Type"}
            width={"130px"}
          />

          <GridColumn
            field={"TeamA"}
            title={"Team A"}
            width={"150px"}
          />


          <GridColumn
            field={"TeamB"}
            title={"Team B"}
            width={"150px"}
          />

          <GridColumn
            field={"Venue"}
            title={"Venue"}
            width={"250px"}
          />

          <GridColumn
            width={"100px"}
            cell={RoundedButton}
            title={lang.show_response_label}
          /> */}



        </Grid>
      </ExcelExport>
      <div className="text-right mt-1">{`Total : ${totalCount} | Success : ${succesCount} | Failed : ${failureCount}`}</div>

      {showResponseOpen &&
        <Dialog title={lang.response_dialog_header} onClose={handleShowResponse} width={"50vw"} height={"30vh"}>
          <div>
            <p>{showResponse}</p>
          </div>
        </Dialog>}

    </div>
  );
};

export default forwardRef(PartnerIntegrationDetailCollection);
