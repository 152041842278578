import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { PLAYLIST_SCHEDULE_TYPE, PLAYLIST_STATUS } from '../../../constant/constant';
export const ButtonCommandCell = (props) => {

    const playlist = props.dataItem;

    let publish = playlist != undefined && playlist.Status == PLAYLIST_STATUS.Published ? true : false

    let showPlayingOnce = false;
    let showPlayingLoop = false;
    let showPublishUnPublish = false;
    let showStop = false;


    if (playlist != undefined && playlist.Schedule && playlist.Schedule.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover && playlist.Status == PLAYLIST_STATUS.Standby) {
        showPlayingOnce = false;
        showPlayingLoop = true;
    } else if (playlist != undefined && playlist.Schedule && playlist.Schedule.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover && playlist.Status != PLAYLIST_STATUS.Standby) {
        showStop = true;
    } else if (playlist != undefined && playlist.Schedule && playlist.Schedule.ScheduleType == PLAYLIST_SCHEDULE_TYPE.SetDateRange) {
        showPublishUnPublish = true;
    }

    return <td className="k-command-cell">
        {showPlayingOnce && <Button themeColor={'tertiary'} style={{ width: '95px' }} icon='play-sm' onClick={() => props.onClick(props.dataItem, PLAYLIST_STATUS.PlayingOnce)}>
            {'Play Once'}
        </Button>}
        {showPlayingLoop && <Button themeColor={'primary'} style={{ width: '95px' }} icon='play-sm' onClick={() => props.onClick(props.dataItem, PLAYLIST_STATUS.Playing)}>
            {'Play'}
        </Button>}
        {showStop && <Button themeColor={'error'} style={{ width: '95px' }} icon='stop-sm' onClick={() => props.onClick(props.dataItem, PLAYLIST_STATUS.Standby)}>
            {'Stop'}
        </Button>}
        {showPublishUnPublish && <Button icon={publish ? 'download' : 'upload'} themeColor={publish ? 'inverse' : 'warning'} style={{ width: '95px' }} onClick={() => props.onClick(props.dataItem, publish ? PLAYLIST_STATUS.UnPublished : PLAYLIST_STATUS.Published)}>
            {publish ? 'UnPublish' : 'Publish'}
        </Button>}
    </td>;
};