import { SchedulerItem, SchedulerItemContent } from '@progress/kendo-react-scheduler';
import { useCallback } from 'react';
import { COLUMNSTYPE, LOCALSTORAGE_KEY, MEDIACATEGORIES, MODULE, TX_MODE, TX_STATUS } from '../../framework/constant/constant';
import { utility } from '../../framework/utility/utilityProvider';
import { PlanningHelper } from './helper/PlanningHelper';
import moment from 'moment';

const CustomPlanningItem = (props) => {

  const { onFocus } = props;

  const columnPreference = PlanningHelper.getColumnPreference();
  const planningPreference = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
  const showFormat = planningPreference?.data[0]?.value?.ShowFormat && props?.dataItem?.mediaEpisode?.Formats?.length > 0;
  const showNoSegment = planningPreference?.data[0]?.value?.ShowNoSeg && !props?.dataItem?.Segment_id;
  const showContract = planningPreference?.data[0]?.value?.ShowContract && props?.dataItem?.MediaDealRight_id;
  let showTxMode = false;
  let txModeValue = '';
  let txStatus = props.dataItem?.TXStatus ? props.dataItem?.TXStatus : { SID: TX_STATUS.Transmission, Description: 'Transmission' };
  const showRecursive = (props?.dataItem?.RecursionParentProgramSchedule_id != undefined && props?.dataItem?.RecursionParentProgramSchedule_id != "") ? true : false;
  const isChanged = props?.dataItem?.isChanged ?? false;
  const scheduleDate = new Date(props.dataItem?.ScheduleDate).setUTCHours(0, 0, 0, 0);
  const channel = props?.dataItem?.Channel;
  const userData = utility.getValue(LOCALSTORAGE_KEY.userData);
  const modify = utility.canModify(props.lockedPlaylist, MODULE.PLANNING, channel._id, scheduleDate);
  var canModify = modify.canModify;
  const lockedBy = modify.lockedBy;
  const isPlanningItemLocked = props.dataItem?.isLocked ?? false;
  var style = {
    ...props.style, letterSpacing: "1px", fontWeight: "bold", ...props.dataItem.Style, border: props.selected ? '2px solid black' : '0px', pointerEvents: canModify ? 'auto' : 'none'
  }


  let uiData = columnPreference.map((item, index) => {
    var value = item.key ? props.dataItem[item.key][item.name] : props.dataItem[item.name];
    if (item.name == 'EpisodeNumber') {
      value = value ? ((props.dataItem?.mediaEpisode?.MediaCategory?.SID ?? 0) == MEDIACATEGORIES.Movie ? 'NA' : 'EP ' + value) : '';
    }
    else if (item.name == 'ExhibitionNumber') {
      value = 'Exhibition: ' + value?.toString();
    }
    else if (item.name == 'RepeatNumber') {
      value = 'Repeat: ' + value?.toString();
    }
    else if (item.name == 'SeasonNumber') {
      var temp = props.dataItem?.mediaEpisode?.SeasonNumber
      value = temp ? ((props.dataItem?.mediaEpisode?.MediaCategory?.SID ?? 0) == MEDIACATEGORIES.Movie ? 'NA' : 'S-' + temp) : '';
    } else if (item.name == 'NominalDuration') {
      let finalNominalDuration = props.dataItem.customDuration ? props.dataItem.customDuration : props.dataItem.NominalDuration;
      value = utility.convertMilisecondsToStringWithFrames(finalNominalDuration);
    } else if (item.name == 'TX Mode') {

      if (props.dataItem?.Segment_id != null && (props.dataItem?.segments?.length > 0 || props.dataItem?.mediaEpisode?.segments?.length > 0)) {

        let currentSegment = props.dataItem?.segment ? props.dataItem?.segment : props.dataItem?.mediaEpisode?.segments.find((x) => x._id == props.dataItem?.Segment_id)

        let temp = currentSegment?.SegmentType?.TXMode ?
          currentSegment?.SegmentType?.TXMode?.Name : utility.getKeyByValue(TX_MODE, currentSegment?.SegmentType?.TXModeSID);

        showTxMode = temp ? true : false;

        value = temp ? temp : 'No Segments';
        txModeValue = value;

      } else {

        value = 'No Segments';
        txModeValue = value;

      }

    } else if (item.type == COLUMNSTYPE.array) {
      // var tempArray = value.map((x) => x[item.format])
      //SHOW ONLY FIRST VALUE ONLY
      value = value && value.length > 0 ? value[0][item.format] : "";
    } else if (item.type == COLUMNSTYPE.time) {
      value = utility.convertMilisecondsToStringWithFrames(value);
    } else if (item.type == COLUMNSTYPE.date) {
      value = utility.convertMilisecondsToStringWithFrames(value);
    } else if (item.name == 'Language') {
      value = props.dataItem?.segment?.Language?.Language?.Description;
    }
    value = value?.toString();
    return value;
  });

  const tooltip = '(' + moment(props?.dataItem?.SlotDateTime).utc().format('HH:mm A') + ' - ' + moment(props?.dataItem?.SlotDateTime + props?.dataItem?.ActualDuration).utc().format('HH:mm A') + ')\n' + props?.dataItem?.Title + '\n' + uiData.join('\n');

  const divId = props?.id;
  let div = document.getElementById(divId)?.querySelector('div');
  if (div) {
    div.title = tooltip
  }

  const handleFocus = useCallback((event) => {
    //  props.onClick(event);
    // Call the default `onFocus` handler
    if (onFocus) {
      props.setFocusedPlanningItemPropRef(props);
      onFocus(event);
    }
  },
    [onFocus]
  );

  return <SchedulerItem {...props}
    style={style}

    onFocus={handleFocus}
    // onBlur={handleBlur}

    onClick={(event) => {
      if (event.syntheticEvent.detail == 2) {
        //dblclick
      } else {
        //singleClick
        props.onClick(event);
        props.onPlannerItemSelect(event, event.target.props.dataItem);
      }

    }}


  >
    {props.children}
    <SchedulerItemContent title={tooltip}>
      {
        uiData.map((item, index) => {
          if (item) return <div key={index + "-" + item._id} className='customItem'>{item}<br /></div>
        })
      }

    </SchedulerItemContent>
    <div style={{
      position: 'absolute', bottom: '0px', left: '0px',
      alignItems: 'center', fontSize: '14px',
      width: '100%',
      backgroundColor: "transparent",
      zIndex: 3,
      pointerEvents: 'none'
    }}>
      {isPlanningItemLocked && <i className={`fa fa-lock ml-1`} title={'Locked'} />}
    </div>
    <div style={{
      position: 'absolute', bottom: '0px', right: '0px',
      display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
      alignItems: 'center', fontSize: '10px', padding: (showFormat || showNoSegment || showContract || showRecursive) ? '3px' : 0,
      width: '100%',
      backgroundColor: props?.dataItem?.Style?.backgroundColor ?? "white",
      zIndex: 2,
      pointerEvents: 'none'
    }}>
      {showFormat && <i className="fa fa-cube mr-1" title="format" />}
      {showNoSegment && <i className="fa fa-window-close mr-1" title="no segments" />}
      {showContract && <i className="fa fa-pencil-square-o mr-1" title="contract" />}
      {showRecursive && <i className="fa fa-bars mr-1" title="recursive" />}
      {isChanged && <i className={"fa fa-refresh mr-1"} title="Changed" />}
      {txStatus.SID == TX_STATUS.Live ? <i className={"fa fa-circle"} style={{ color: 'red' }} title="live" /> :
        txStatus.SID == TX_STATUS.Repeat ? <i className={"fa fa-registered"} title="Repeat" /> :
          txStatus.SID == TX_STATUS.SDD ? <span className={"rounded-circle d-inline-flex"} style={{ width: '12px', height: '12px', fontSize: '8px', padding: '0px 0px 9px 2.5px', border: `1px solid ${style?.color ?? 'white'}` }} title="Delayed">D</span> :
            txStatus.SID == TX_STATUS.Transmission ? <span className={"rounded-circle d-inline-flex mr-1 mb-1"} style={{ width: '12px', height: '12px', fontSize: '5px', padding: '2px 0px 0px 1.5px', border: `1px solid ${style?.color ?? 'white'}` }} title="TX Mode">TX</span> : null}
    </div>
  </SchedulerItem>

};

export default CustomPlanningItem;