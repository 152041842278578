/* eslint-disable */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column, } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ENTITYNAME, MEDIACATEGORIES, MEDIACATEGORYTYPES, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../../framework/constant/constant";
import { toast } from "react-toastify";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import MyStatusCell from "../../components/MyStatusCell";
import { DateCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { MyPublishedChannelArrayCell } from "../LiveEvents/components/MyPublishedChannelArrayCell";
import { ContextMenu } from "primereact/contextmenu";
import { LiveMatchEditForm } from "../LiveEvents/components/LiveMatchEditForm";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from "moment";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import UnPublishSelectionCollection from "../LiveEvents/components/unPublishSelectionCollection";
import useSocket from "../../framework/socket/useSocket";

const PlanningLiveEventsTab = (props,ref) => {

    const lang = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [gridData, setGridData] = useState([]);
    const [page, setPage] = useState({ skip: 0, take: 50 });
    const [searchText, setSearchText] = useState("");
    const [tournamentValue, setTournamentValue] = useState({ SID: 0, Name: "All" });
    const [tournaments, setTournaments] = useState([]);
    const [total, setTotal] = useState(1);
    const [showAll, setShowAll] = useState(false);
    const [filterDataItem, setFilterDataItem] = useState({
        isLive: true,
        weekStartDate : props.payload.startDate,
        weekEndDate : props.payload.endDate,
        // channels : props.payload.channels
    });
    const [showMatchEditForm, setShowMatchEditForm] = useState(false);
    const [showPublishMenu, setShowPublishMenu] = useState(false);
    const [openPopupScheduleMatch, setOpenPopupScheduleMatch] = useState(false);
    const [scheduleMatchDataItem, setScheduleMatchDataItem] = useState({});
    const [openCollectionSelectionUnPublish, setOpenCollectionSelectionUnPublish] = useState(false);

    const contextMenuRef = useRef(null);
    const selectedItemsRef = useRef([]);
    const setSelectedItemRef = (data) => {
        selectedItemsRef.current = data;
    }
    const matchItem = useRef({ SID: 0 });
    const setMatchItem = (data) => {
        matchItem.current = data;
    };

    const unPublishRef = useRef({});
    const setUnPublishRef = (data) => {
        unPublishRef.current = data;
    };

    const liveEventTournamentRef = useRef({});
    const setLiveEventTournamentRef = (data) => {
        liveEventTournamentRef.current = data;
    }

    useEffect(() => {
        loadCombo();

        setFilterDataItem({
            isLive: true,
            weekStartDate : props.payload.startDate,
            weekEndDate : props.payload.endDate,
            // channels : props.payload.channels
        })
        if (props.channels.length > 0) {
            loaddata('', {
                isLive: true,
                weekStartDate : props.payload.startDate,
                weekEndDate : props.payload.endDate,
                // channels : props.payload.channels
            });
        }
    }, [props.channels,props.payload]);

    const socket = useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

        //console.log(socketData)
        if (socketData.module != MODULE.LIVE_EVENTS) return;


        switch (socketData.action) {
            case SOCKET_ACTION.LIVE_EVENT_REFRESH:
                loaddata('', filterDataItem);
                break;
            default:
                break;
        }
  
    });

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setIsLoading(true);
                loaddata(searchText, filterDataItem);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setIsLoading(true);
            loaddata('', filterDataItem);
        }
    }, [searchText]);

    useImperativeHandle(ref, () => {
        return {
            onRefresh() {
                onRefresh()
            },
        };
    }, []);


    const loadCombo = async () => {
        let tournamentRes = await API.getDataLookup(ENTITYNAME.Tournament, { sort: { Name: 1 } });
        tournamentRes.data.unshift({ SID: 0, Name: "All" });
        setTournaments(tournamentRes.data);
    }

    const onShowAllClick = () => {

        setShowAll(!showAll);

        let current_page = page.skip / page.take + 1;
        let filters = { ...filterDataItem }
        
        if (!showAll) {
            delete filters.weekStartDate
            delete filters.weekEndDate
            // delete filters.channels
            setFilterDataItem(filters)
            loaddata(searchText, filters, { per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" });
        } else {
            filters.weekStartDate =  props.payload.startDate
            filters.weekEndDate = props.payload.endDate
            // filters.channels : props.payload.channels
            setFilterDataItem(filters)
            loaddata(searchText, filters, { per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" });
        }
    }

    const loaddata = async (searchText, filters, pagination = { per_page: page.take, current_page: 1, orderby: "SID", direction: "asc" }) => {
        props.showLoading && setIsLoading(true);
        console.log(filters)
        const response = await API.loadPlanningMediaLibrary(searchText, filters, pagination, props.channels);
        console.log(response);
        if (response.success) {
            setGridData(response.data ?? []);
            setTotal(response.pagination.total);
        } else {
            toast.error(response.message);
            setGridData([]);
        }
        setIsLoading(false);
    }

    const onRefresh = () => {
        console.log('here live')
        let current_page = page.skip / page.take + 1;
        loaddata(searchText, filterDataItem, { per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" });
    }

    const StatusCell = (props) => {
        let title = "NOT SCHEDULED";
        if (props.dataItem?.mediaepisodedetailliveevent?.ScheduleStatus?.length > 0) {
            title = "SCHEDULED";
        }
        return (
            <MyStatusCell
                {...props}
                title={title}
                titleColor="#ffffff"
                tileBackColor={title == "SCHEDULED" ? "#47A992" : "#E76161"}
                margin={"20px 10px"}
                titleFontSize={"12px"}
            />
        );
    };

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        await loaddata(searchText, filterDataItem, { per_page: e.page.take, current_page: current_page, orderby: "SID", direction: "asc" });
        setPage(e.page);
    };

    const onChange = (e) => {
        setTournamentValue(e.target.value);
        let filters = { ...filterDataItem };
        if (e.target.value.SID != 0) {
            filters.Tournament_id = e.target.value._id;
        } else {
            delete filters.Tournament_id;
        }
        loaddata(searchText, filters);
        setFilterDataItem(filters);
    }

    const editMatch = (index, dataItem) => {
        setMatchItem(dataItem);
        let tournamnetData = tournaments.find((item) => item._id == dataItem.Tournament_id && item.SID != 0);
        setLiveEventTournamentRef(tournamnetData)
        setShowMatchEditForm(true);
    };

    const onScheduleMatch = (index, dataItem) => {
        setOpenPopupScheduleMatch(true);
        let item = {
            ...dataItem,
            Channels: props.channels ?? [],
            StartDate: moment(new Date(dataItem.StartDateTime)).utc().format("YYYY-MM-DD"),
            StartTime: moment(new Date(dataItem.StartDateTime)).utc().format("HH:mm:ss:SS"),
        };
        console.log(item);
        setScheduleMatchDataItem(item);
    };

    const onUnPublish = (index, dataItem) => {

        //CHECK IF IT IS PUBLISHED OR NOT
        if (dataItem.ScheduleStatus.length == 0) {
            toast.error(`Not published yet`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        setOpenCollectionSelectionUnPublish(true);
        setUnPublishRef(dataItem);
    };

    //onPublish
    const onPublish = async () => {
        const currentDateInMillisec = utility.getLocalDateTimeToUtcMiliseconds(new Date());

        //CHANNEL VALIDATION
        if (!scheduleMatchDataItem?.Channels || scheduleMatchDataItem?.Channels?.length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        //CANNOT SCHEDULE MATCH BEFORE CURRENT DATE //need to ask
        if ( currentDateInMillisec > scheduleMatchDataItem.StartDateTime) {
            let msg = scheduleMatchDataItem.Type.Description == "Live"
                ? `Cannot schedule ${scheduleMatchDataItem.Type.Description}, schedule date already passed`
                : `Cannot schedule ${scheduleMatchDataItem.Type.Description} before current date `;
            toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        //CANNOT SCHEDULE MATCH BEFORE LIVE DATE
        if (
            !scheduleMatchDataItem?.MediaEpisode?.IsLive &&
            scheduleMatchDataItem.StartDateTime >
            utility.getLocalDateTimeToUtcMiliseconds(
                new Date(scheduleMatchDataItem.StartDate)
            )
        ) {
            toast.error(
                ` Cannot schedule ${scheduleMatchDataItem.Type.Description} before live start date `,
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
            return false;
        }

        console.log("success");

        setOpenPopupScheduleMatch(false);

        let slotdatetime = utility.getLocalDateTimeToUtcMiliseconds(
            new Date(
                `${scheduleMatchDataItem.StartDate} ${scheduleMatchDataItem.StartTime}`
            )
        )

        var payload = {
            MediaEpisode_id: scheduleMatchDataItem?.MediaEpisode._id,
            SlotDateTime: slotdatetime,
            ScheduleDate: slotdatetime,
            Channel: scheduleMatchDataItem.Channels,
            MediaEpisodeLiveEventDetail_id: scheduleMatchDataItem._id,
        };

        //call schedule match api here
        var scheduleResponse = await API.scheduleMatch(payload);
        console.log(scheduleResponse);

        if (scheduleResponse.success) {
            onRefresh();
            props.planningLoading();
            toast.success(`${lang.match_scheduled_successfully_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });

            setScheduleMatchDataItem({
                Channels: [],
                StartDate: "",
                StartTime: "",
            });

            //REFRESH PLANNING, In Loop If Multiple Items Published
            for (let index = 0; index < payload.Channel.length; index++) {
                const ch = payload.Channel[index];

                socket.emit(SOCKET_EVENTS.onSocketData, {
                    action: SOCKET_ACTION.PLANNING_REFRESH,
                    module: MODULE.PLANNING,
                        data: {scheduleScheduleStartDate: payload.SlotDateTime, scheduleScheduleEndDate: payload.SlotDateTime, channelSID: ch.SID},
                });

            }
        } else {
            console.log(scheduleResponse)
            toast.error(scheduleResponse.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
    };

    //CONTEXT MENU TILE VIEW
    const publishMenu = [
        { label: lang.edit_actionbutton_option, icon: "fa-solid fa-pencil fa-lg", command: () => { editMatch(0, selectedItemsRef.current) } },
        { label: lang.publish_match_label, icon: "fa-solid fa-calendar-days fa-lg", command: () => { onScheduleMatch(0, selectedItemsRef.current) } },
    ];

    const unPublishMenu = [
        { label: lang.edit_actionbutton_option, icon: "fa-solid fa-pencil fa-lg", command: () => { editMatch(0, selectedItemsRef.current) } },
        { label: lang.un_publish_match_label, icon: "fa-solid fa-calendar-days fa-lg", command: () => { onUnPublish(0, selectedItemsRef.current) } },
    ];

    const onContextMenu = (e) => {
        e.syntheticEvent.preventDefault();
        contextMenuRef.current.show(e.syntheticEvent);
        let prepareData = {
            ...e?.dataItem?.mediaepisodedetailliveevent,
            MediaEpisode: e?.dataItem
        }
        setSelectedItemRef(prepareData);
        setShowPublishMenu(prepareData?.ScheduleStatus?.length == 0 ? true : false)
    }

    const onAddMatch = () => {
        setMatchItem({ SID: 0 });
        setShowMatchEditForm(true);
    };

    const onChangeScheduleMatch = (e) => {
        setScheduleMatchDataItem({
            ...scheduleMatchDataItem,
            [e.target.name]: e.target.value,
        });
    };

    const unPublish = async (dataList) => {

        setOpenCollectionSelectionUnPublish(false);
        let tempScheduleStatus = unPublishRef.current.ScheduleStatus;

        for (let i = 0; i < dataList.length; i++) {
            const obj = dataList[i];

            //call delete planning api here
            let payload = {
                _id: obj.ProgramSchedule_id,
                MediaDealRight_id: "",
            };

            // console.log(payload)
            let deleteResponse = await API.deletePlanning(payload);
            console.log(deleteResponse);

            let filter = tempScheduleStatus.filter(
                (x) =>
                    obj.ProgramSchedule_id != x.ProgramSchedule_id &&
                    obj.SlotDateTime != x.SlotDateTime
            );
            tempScheduleStatus = filter;
        }

        let isScheduled = tempScheduleStatus.length > 0 ? true : false

        let updateLiveEventDetail = await API.saveData(ENTITYNAME.MediaEpisodeDetailLiveEvent,
            { _id: unPublishRef.current._id, ScheduleStatus: tempScheduleStatus, IsScheduled: isScheduled }
        );

        if (updateLiveEventDetail.success) {
            onRefresh();
            props.planningLoading();
            socket.emit(SOCKET_EVENTS.onSocketData, {
                action: SOCKET_ACTION.PLANNING_REFRESH,
                module: MODULE.PLANNING,
                data: {scheduleScheduleStartDate: dataList[0]?.SlotDateTime, scheduleScheduleEndDate: dataList[0]?.SlotDateTime, channelSID: dataList[0]?.Channel?.SID},
            });


            toast.success(`Un-Published successfully!!`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (<>
        <div style={{ height: "100%", width: "100%!important" }}>
            <div className="row">
                <div className="col-10">
                    <h1 className="page-title" style={{margin : '8px 0px 15px 0px' }}>
                        <i
                            className="fa fa-table marlr"
                            style={{ fontSize: "20px", color: "GrayText" }}
                        ></i>
                        <span>{lang.live_events_label}</span>
                    </h1>
                </div>
                <div className="col-2 pull-right pt-2">
                    <RefreshButton onClick={onRefresh} />
                </div>
                <div className="col-5 pt-3 pl-3">
                    <AddRoundButton
                        title={lang.create_event_button_tooltip}
                        name={lang.create_event_button_text}
                        onClick={onAddMatch}
                    />
                </div>
                <div className="col-3 d-inline mt-4" style={{padding : "0px 0px 0px 28px"}}>
                    <input type='checkbox' name='FilteredArchive' checked={showAll} onClick={onShowAllClick} onChange={() => { }} />  {lang.show_all_label}
                </div>
                <div className="col-4 pull-right" style={{padding : "0px 26px 0px 0px"}}>
                    <label >{lang.tournament_label}</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={tournaments}
                        name="Tournament"
                        textField="Name"
                        dataItemKey="SID"
                        value={tournamentValue}
                        onChange={onChange}
                    />
                </div>
                <div className="flex-container">
                    <div className="input-group input-group martb marlr pl-3 pr-3" style={{ zIndex: 1 }}>
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            {isLoading && <div className="ml-1">
                <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
            </div>}
            {!isLoading && <Grid
                style={{ position: "absolute", left: 0, top: "210px", right: 0, bottom: "25px" }}
                data={gridData}
                resizable={true}
                pageable={true}
                skip={page.skip}
                take={page.take}
                rowRender={props.MediaLibraryCollectionGridRowRender}
                onPageChange={pageChange}
                onContextMenu={onContextMenu}
                total={total}
            >
                <Column field="mediaepisodedetailliveevent.PosterUrl" title={"Poster"} editable={false} cell={ImageCell} width={"100px"} />
                <Column field="AssetId" title={lang.assetId_column} editable={false} width={"150px"} />
                <Column field="Title" title={lang.title_column} editable={false} width={"150px"} />
                <Column field="mediaepisodedetailliveevent.ScheduleStatus" title={"Status"} editable={false} width={"150px"} cell={StatusCell} />
                <Column field="StartDateTime" title={"Start Date Time"} editable={false} cell={DateCell} width={"160px"} />
                <Column field="mediaepisodedetailliveevent.Type.Description" title={"Type"} editable={false} width={"100px"} />
                <Column field="mediaepisodedetailliveevent.TeamA" title={"Team A"} editable={false} width={"120px"} />
                <Column field="mediaepisodedetailliveevent.TeamB" title={"Team B"} editable={false} width={"120px"} />
                <Column field="Description" title={lang.description_column} editable={false} width={"150px"} />
                <Column field="Duration" title={lang.duration_column} editable={false} cell={TimeCell} width={"100px"} />
                <Column field="mediaepisodedetailliveevent.Round.Description" title={"Round"} editable={false} width={"100px"} />
                <Column field="mediaepisodedetailliveevent.Venue" title={"Venue"} editable={false} width={"150px"} />
                <Column field="mediaepisodedetailliveevent.ScheduleStatus" cell={MyPublishedChannelArrayCell} title="Published Channel" width={200} format={"Channel.FullChannelName"} />
            </Grid>}
            <div style={{paddingLeft :'10px', position: "absolute", left: 0, top: "84.2vh", right: 0, bottom: 0 }}>
            <p className="text-muted" style={{ fontSize: "12px" }}>Note : Valid Live Event for Selected Planning Date Range & Channel </p>
        </div>
        </div>

        
        {showMatchEditForm &&
            <LiveMatchEditForm
                title={lang.match_label}
                entityName={ENTITYNAME.MediaEpisode}
                item={matchItem.current}
                refresh={onRefresh}
                cancelEdit={() => { setShowMatchEditForm(false); setLiveEventTournamentRef({}) }}
                mediaCategory={MEDIACATEGORIES.Program}
                mediaCategoryType={MEDIACATEGORYTYPES.Program}
                selectedTournament={liveEventTournamentRef.current}
                isPlanning={true}
            />}
        <ContextMenu model={showPublishMenu ? publishMenu : unPublishMenu} ref={contextMenuRef} />
        {/* un-publish */}
        {openCollectionSelectionUnPublish && (
            <UnPublishSelectionCollection
                title={lang.un_publish_match_label}
                data={unPublishRef.current.ScheduleStatus}
                onConfirm={unPublish}
                closeForm={() => setOpenCollectionSelectionUnPublish(false)}
            />
        )}
        {/* scheduleMatch */}
        {openPopupScheduleMatch && (
            <Dialog
                title={lang.publish_match_header_dialog}
                onClose={() => {
                    setOpenPopupScheduleMatch(false);
                    setScheduleMatchDataItem({
                        Channels: [],
                        StartDate: "",
                        StartTime: "",
                    });
                }}
                width={"600px"}
            >
                <EditPageHeader showTitle={false} saveButtonTitle={lang.confirm_button_text} onSubmit={onPublish} onCancel={() => {
                    setOpenPopupScheduleMatch(false);
                    setScheduleMatchDataItem({
                        Channels: [],
                        StartDate: "",
                        StartTime: "",
                    });
                }} />
                <div className="row mt-2">
                    <div className="col-6 form-group">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <MultiSelect
                            data={scheduleMatchDataItem?.Channels}
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={scheduleMatchDataItem.Channels}
                            onChange={onChangeScheduleMatch}
                            name={"Channels"}
                        />
                    </div>
                    <div className="col-3 form-group">
                        <label htmlFor="TabView">{lang.start_date_label} *</label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="StartDate"
                            value={scheduleMatchDataItem.StartDate}
                            onChange={onChangeScheduleMatch}
                            disabled={scheduleMatchDataItem?.MediaEpisode?.IsLive}
                            min={moment(new Date(scheduleMatchDataItem.StartDateTime)).utc().format("YYYY-MM-DD")}
                        />
                    </div>

                    <div className="col-3">
                        <label htmlFor="">{lang.start_time_label} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="StartTime"
                            value={scheduleMatchDataItem.StartTime}
                            onChange={onChangeScheduleMatch}
                            disabled={scheduleMatchDataItem?.MediaEpisode?.IsLive}
                        />
                    </div>
                </div>
            </Dialog>
        )}
    </>);
}

export default forwardRef(PlanningLiveEventsTab);
