/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import * as API_Digi_Sign from "../../framework/API/api_digitalSign";
import { MODULE, SOCKET_EVENTS, SOCKET_ACTION, LOGEVENT } from "../../framework/constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { getter } from "@progress/kendo-data-query";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { utility } from "../../framework/utility/utilityProvider";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import { useTranslation } from "../../locale/useTranslation";
import socket from "../../framework/socket/socket";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import MyStatusCell from "../../components/MyStatusCell";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

export const DigitalSignScreenGroupCollection = (props) => {

    let page_take = props.page_take ?? 50;
    let showAdd = props.showAdd ?? true;
    let showBack = props.showBack ?? true;
    let showArchive = props.showArchive ?? true;
    let showCommandCell = props.showCommandCell ?? true;
    let CustomEditForm = props.customEditForm;
    let showEdit = props.showEdit ?? true;
    let showTitle = props.showTitle ?? true;
    let gridRowRender = props.gridRowRender;
    let filterable = props.filterable ?? false;
    let sortable = props.sortable ?? true;
    let isPageable = props.isPageable ?? true;
    let wherestatement = props.wherestatement ?? [];
    let andWhereStatement = props.andWhereStatement ?? [];
    let sort = props.sort ?? { SID: 1 };
    let openNewEditForm = props.openNewEditForm ?? false;
    let myTitle = props.title ?? "";
    let myMenuModel = props.menuModel ?? collectionMenuModel;
    let entityName = props.entityname;
    let hideArchiveProps = props.hideArchive ?? false;
    let enableMutliSelect = props.enableMutliSelect ?? false;
    let module = props?.module;
    let showLogs = props.showLogs ?? false;
    let doLoad = props.isLoading ?? true;

    const [gridData, setGridData] = useState([]);
    const [total, setTotal] = useState(1);
    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState({ SID: 1 });
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [hideArchive, setHideArchive] = useState(hideArchiveProps);
    const [selectedState, setSelectedState] = useState({});
    const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
    const [gridSort, setGridSort] = useState([initialSort]);

    const selectedItemsRef = useRef([]);
    const setSelectedItem = (items) => {
        selectedItemsRef.current = items;
    }

    const [showScreenForm, setShowScreenForm] = useState(false);
    const [selectedScreenGroupData, setSelectedScreenGroupData] = useState({});

    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });

    //only to get wherestatement from href cell
    const location = useLocation();
    const lang = useTranslation();

    // open by defualt create form
    useEffect(() => {
        if (location.state?.openNewEditForm) {
            setOpenForm(true);
        }
    }, [location.state?.openNewEditForm]);

    let href = location.state ? location.state.href : false;
    if (href) {
        wherestatement = location.state.wherestatement;
    }

    useEffect(() => {
        setShowArchived(false);
        doLoad ? setIsLoading(true) : setIsLoading(false);
        setPage({ skip: 0, take: page_take });
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, false);
    }, [props.entityname, props.gridData, props.title, props.wherestatement]);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        let sortEvent = gridSort[0] ?? initialSort;
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setIsLoading(true);
                loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setIsLoading(true);
            setPage({ skip: 0, take: page_take });
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
        }
    }, [searchText]);

    const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive) => {
        try {
            let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
            let res = await API_Digi_Sign.getScreenGroup({ query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: finalSort }, searchText);
            setGridData(res?.data ?? []);
            setTotal(res?.pagination?.total ?? 0);
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    };

    const onKillUserSession = (userData) => {
        delete userData["Module"];
        console.log(userData);
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.KILL_USER_SESSION, module: MODULE.ALL, data: userData })
        toast.success(`${userData.name} session has been killed successfully`, { position: toast.POSITION.TOP_RIGHT });
    }

    const enterEdit = (item) => {
        console.log("onEnterEdit");
        if (openNewEditForm === false) {
            setOpenForm(true);
            setEditItem(item);
        } // need to navigate to full page edit view
        else {
            navigate(`${item.SID}`, {
                state: {
                    copy: item.copy ? true : false,
                    quickEdit: item.quickEdit ? true : false,
                    prevPath: location.pathname
                }
            });
        }
    };

    const handleNavigate = (item) => {
        if (item?.Module) {
            window.open(item.path, "MyTargetWindowName");
        } else {
            navigate(`${item.path}`, { state: item });
        }
    }

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleArchive = async (dataItem) => {
        var resArchiveUpdated = await API_Digi_Sign.saveData(entityName, {
            ...dataItem,
            Archive: !dataItem.Archive
        })

        if (resArchiveUpdated.success) {
            console.log(`Archive is been updated to ${!dataItem.Archive}`);
            if (module) {
                let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: module, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
                API.SaveLogs(logData);
            }
            let sortEvent = gridSort[0] ?? initialSort;
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
            utility.deleteLocalStorageItem(entityName);
        } else {
            console.log(resArchiveUpdated.message);
        }
    }

    //Edit Row
    const MyCommandCell = (props) => (

        <MenuCommandCell
            {...props}
            showLogs={showLogs}
            enterEdit={enterEdit}
            cancelEdit={handleCancelEdit}
            item={editItem}
            onArchive={handleArchive}
            showEdit={showEdit}
            toNavigate={handleNavigate}
            myMenuModel={myMenuModel}
            module={module}
            openNewEditForm={openNewEditForm}
            onKillUserSession={onKillUserSession}
        />
    );

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
        setPage(e.page);
        cancelSelectedItem()
    };

    const onArchiveClick = () => {
        setShowArchived(!showArchived);
        let sortEvent = gridSort[0] ?? initialSort;
        if (!showArchived) {
            loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, true);
        } else {
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, false);
        }
        cancelSelectedItem();
    }

    const refreshCollection = () => {
        setIsLoading(true);
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, andWhereStatement, searchText, showArchived);
        setGridSort([initialSort]);
    }

    const onSortChange = (e) => {
        setGridSort(e.sort)
        let sortEvent = e.sort[0] ?? sort;
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];

        selectedIds.map((value) => {
            if (newSelectedState[value]) {
                let filter = gridData.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });

        setSelectedItem(tempSelected);
    };


    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];

        if (checked) {
            selectedIds.map((value) => {
                if (newSelectedState[value]) {
                    var filtered = gridData.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedItem(tempSelected);
    };

    const onArchiveButtonClick = async () => {
        if (selectedItemsRef.current.length == 0) {
            toast.error(`${lang.please_select_atleast_one_item}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        for (let i = 0; i < selectedItemsRef.current.length; i++) {
            const item = selectedItemsRef.current[i];
            await handleArchive(item);
        }
        cancelSelectedItem();
    }

    const onEnterEdit = () => {
        enterEdit({ SID: 0 });
        cancelSelectedItem();
    }

    const cancelSelectedItem = () => {
        setSelectedItem([]);
        setSelectedState({});
    }

    const MyHrefCell = (props) => {
        const showAttachScreen = (e) => {
            setSelectedScreenGroupData(props.dataItem);
            setShowScreenForm(true);
        }
        return (
            <td>
                <a style={{color: 'blue', cursor: 'pointer'}} onClick={showAttachScreen} >{props.dataItem.ScreenCount}</a>
            </td>
        )
    }

    const Mystatuscell = (props) => {

        const field = props.field

        let tileBackColor = props.dataItem[field] ? "#63aa43" : "#E72929";
        let status = props.dataItem[field] ? "Active" : "Inactive";

        return <MyStatusCell title={status} tileBackColor={tileBackColor} />

    }

    return (
        <div>
            {showTitle && (
                <h1 className="page-title txt-color-bludeDark">
                    <i
                        className="fa fa-table marlr"
                        style={{ fontSize: "20px", color: "GrayText" }}
                    ></i>
                    <span>{myTitle === "" ? props.entityname : myTitle}</span>
                </h1>
            )}
            <div className="flex-container">
                <div className="martb mar">
                    {showBack && (
                        <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                    )}
                    {showAdd && (
                        <AddRoundButton onClick={onEnterEdit}></AddRoundButton>
                    )}
                    {showArchive && enableMutliSelect && selectedItemsRef.current.length != 0 && (
                        <ActionButton title={showArchived ? lang.un_archive_label : lang.archive} name={showArchived ? lang.un_archive_label : lang.archive}
                            onClick={onArchiveButtonClick} />
                    )}
                </div>

                <div className="flex-container-reverse flex-item-auto">
                    <div className="input-group input-group w-300 martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                    <div className="mt-1" style={{ paddingTop: "12px" }}>
                        <RefreshButton
                            onClick={refreshCollection}
                        />
                    </div>
                    {!hideArchive && <div className="d-inline mt-3 mr-2">
                        <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
                    </div>}
                </div>
            </div>
            {isLoading && <Loader />}
            {!isLoading &&
                <Grid
                    style={props.gridstyle ?? {
                        height: "76vh",
                    }}
                    sort={gridSort}
                    sortable={sortable}
                    onSortChange={onSortChange}
                    rowRender={gridRowRender}
                    filterable={filterable}
                    pageable={isPageable}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChange}
                    total={total}
                    resizable={true}
                    data={
                        gridData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: enableMutliSelect,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                >

                    {/* Multi Select Checkbox row */}
                    {enableMutliSelect && (<GridColumn
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={
                            gridData.findIndex(
                                (item) => !selectedState[idGetter(item)]
                            ) === -1
                        }
                        locked={true}
                        filterable={false}
                    />
                    )}

                    {/* Edit Row */}
                    {showCommandCell && (
                        <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
                    )}

                    <GridColumn field="Name" title={lang.name_column} width="200px" />
                    <GridColumn field="Description" title={lang.description_column} width="200px" />
                    <GridColumn field="ScreenCount" title={lang.attach_screens_column} cell={MyHrefCell} width="200px" />
                    <GridColumn field="Archive" title={lang.archive} cell={CheckboxCell} width="100px" />
                </Grid>}
            {openForm && <CustomEditForm item={editItem} cancelEdit={handleCancelEdit} refresh={refreshCollection} />}
            {showScreenForm && <Dialog title={`${lang.attach_screen_dialog_header} - ( ${selectedScreenGroupData?.Name} )`} onClose={() => setShowScreenForm(false)} width="650px">
                <Grid
                    data={selectedScreenGroupData?.Screens ?? []}
                    style={{ height: "350px" }}
                >
                    <GridColumn title="Name" field="Name" width={200} />
                    <GridColumn title="Active ago" field="activeAgo" width={200} />
                    <GridColumn title="Status" field="active" width={150} cell={Mystatuscell} />
                    <GridColumn title="No. of Playlist" field="playlist" width={200} cell={ArrayCell} format="length" />
                </Grid>
            </Dialog>}
        </div>
    );
};