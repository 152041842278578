import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat"
import { PROTOTYPETRIGGERTYPE, SCHEDULECOLORPREFERENCE } from "../../framework/constant/constant";
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { toast } from "react-toastify";

export const SchedulePrototypeOverview = (props) =>  {

    const [dataItem, setDataItem] = useState({});
    const [scheduleItem, setScheduleItem] = useState({});
    const [secondaryEventData, setSecondaryEventData] = useState([]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);

    const DATA_ITEM_KEY = props.DATA_ITEM_KEY;
    const SELECTED_FIELD = props.SELECTED_FIELD;
    const MyTimeCell = props.MyTimeCell;
    const lang=useTranslation();

    useEffect(() => {

      if(props.selectedItem && props.selectedItem.length > 0){

        let protoTypes = [];
        props.selectedItem.map((y) => {
            if(y.Prototypes && y.Prototypes.length > 0){
              let tempPrototypes = y.Prototypes.map((x) => {
                return {...x,SlotDateTime : y.SlotDateTime };
              })
              protoTypes = [...protoTypes, ...tempPrototypes];
            }
        })

        setDataItem({...props.selectedItem[0].Prototypes[0],
          StartOffset: utility.convertMilisecondsToStringWithFrames(props.selectedItem[0].Prototypes[0].StartOffset),
          EndOffset: utility.convertMilisecondsToStringWithFrames(props.selectedItem[0].Prototypes[0].EndOffset)
        })
        setScheduleItem(props.selectedItem[0])
        setSecondaryEventData(protoTypes)

      } else {
        //IF NO ITEM IS SELECTED SHOW ALL PROTOTYPES
        if(props.data && props.data.length > 0){

          //CHILDRENS
          let childrens = [];
          let protoTypes = [];
          props.data.map((x) => {
            childrens = [...childrens, ...x.children];
            x.children.map((y) => {
              if(y.Prototypes && y.Prototypes.length > 0){
                let tempPrototypes = y.Prototypes.map((z) => {
                  return {...z,SlotDateTime : y.SlotDateTime };
                })
                protoTypes = [...protoTypes, ...tempPrototypes];
              }
            })
          })

          setDataItem({...protoTypes[0],
            StartOffset: utility.convertMilisecondsToStringWithFrames(protoTypes[0].StartOffset),
            EndOffset: utility.convertMilisecondsToStringWithFrames(protoTypes[0].EndOffset)
          })
          setScheduleItem(childrens[0])
          setSecondaryEventData(protoTypes)
        }

      }
      
    },[])
    
    const GridRowRenderScheduleSecondaryEvents = (tr, props) => {
      var style = props.dataIndex == selectedItemIndex ? SCHEDULECOLORPREFERENCE.segment : SCHEDULECOLORPREFERENCE.lightgrey;

      const trProps = {
          style : style,
          draggable: false,
      };
      return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };


    const onChange = (e) => 
    {
        if(e.target.name == "StartOffset")
        {
          setDataItem({...dataItem,
            StartOffset : e.target.value.replaceAll('_','0'),
          });

        }
        else if(e.target.name == "EndOffset")
        {
          setDataItem({...dataItem,
            EndOffset : e.target.value.replaceAll('_','0'),
          });

        }    
        else if(e.target.name == "IsStartNegative")
        {
          setDataItem({...dataItem,
            IsStartNegative : !dataItem.IsStartNegative,
          });
        }
        else if(e.target.name == "IsEndNegative")
        {
          setDataItem({...dataItem,
            IsEndNegative : !dataItem.IsEndNegative,
          });
        }
        else
        {
          setDataItem({
            ...dataItem,
            [e.target.name]: e.target.value,
          });
        }
      };

    const onSelectionSecondaryEvent = (event) => {

      let childrens = [];
      if(props.selectedItem && props.selectedItem.length > 0){
        childrens = [...props.selectedItem]
      } else {
        props.data.map((x) => {
          childrens = [...childrens, ...x.children];
        })
      }

        var startTime = event.dataItems[event.startRowIndex].StartOffset;
        var endTime = event.dataItems[event.startRowIndex].EndOffset;
        setDataItem({...event.dataItems[event.startRowIndex],
            StartOffset: utility.convertMilisecondsToStringWithFrames(startTime),
            EndOffset: utility.convertMilisecondsToStringWithFrames(endTime)
        })
        setSelectedItemIndex(event.startRowIndex);

        let parent = childrens.find((x) => x.SlotDateTime == event.dataItems[event.startRowIndex].SlotDateTime);
        setScheduleItem(parent)
    }

    const saveNewSecondaryList = () => 
    {
        setSecondaryEventData((old) => {
          old[selectedItemIndex] = {...dataItem, StartOffset: utility.convertStringWithFramesToMilliseconds(dataItem.StartOffset), EndOffset: utility.convertStringWithFramesToMilliseconds(dataItem.EndOffset)};
          return [...old]
      })
    }

    const deleteSelectedSecondaryEvent = () => {

      var selectedItem = secondaryEventData[selectedItemIndex];
      if(!selectedItem){
        toast.error(`${lang.select_secondary_event_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      var newData = secondaryEventData.filter((x) => x.SID != selectedItem.SID);
      setSecondaryEventData(newData)

    }

  return (
    <Dialog title={lang.secondary_event_overview_dialog_header} onClose={() =>{ props.setShowSecondaryEventOverviewDialog(false)}} width={900}>
    <div>
      <div className="mb-1"><DeleteRoundButton title={lang.delete_secondary_event_tooltip} onClick={() => ConfirmDeleteAlert(() => deleteSelectedSecondaryEvent(), () => { })} /></div>
      <Grid
        data={secondaryEventData}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: true,
          cell: false,
          mode: { value: 'multiple', label: 'Multiple selection mode'},
        }}
        onSelectionChange={onSelectionSecondaryEvent}
        rowRender={GridRowRenderScheduleSecondaryEvents}
        style={{ height: '280px' }}
      >
        <Column field="SlotDateTime" title={lang.slot_time_label} cell={MyTimeCell}/>
        <Column field="Name" title={lang.name_column}/>
        <Column field="ID" title={lang.id_column} />
        <Column field="ProtoTypeSource.Name" title={lang.source_column} />
        <Column field="StartTriggerType.Description" title={lang.start_type_column} />
        <Column field="StartOffset" title={lang.start_offset_column} cell={MyTimeCell} />
        <Column field="EndTriggerType.Description" title={lang.end_type_column} />
        <Column field="EndOffset" title={lang.end_offset_column} cell={MyTimeCell} />
      </Grid>
    </div>


      <div className="row" style={{ margin : '12px 5px 0px 5px',padding : '5px', border: '1px solid black'}}>
              <div className="col-5 ml-2" >
                <div>
                  <div className="row mt-1">
                    <div className="col-3 mt-4" style={{ paddingRight:"0" }}>
                      <span style={{ fontSize: "12px" }}>{lang.start_offset_label}</span>
                    </div>
                    <div className="col-5">
                      <div className="form-group" style={{ textAligh: "left" }}>
                        <label htmlFor="">{lang.time_hh_mm_ss_ff_label}</label>
                        <TimePickerWithFormat
                          className="form-control form-control-sm"
                          name="StartOffset"
                          value={dataItem.StartOffset}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <span className="col-4">
                      <input
                        name={"IsStartNegative"}
                        type={"Checkbox"}
                        checked={dataItem.IsStartNegative}
                        onChange={onChange}
                        style={{
                          marginTop: "30px",
                          textAlign: "right",
                          marginRight: "5px",
                        }}
                      />
                      {lang.negative_label}
                    </span>
                    {/* <div className="col-4 mt-4">
                                      <span style={{fontSize:"12px"}}>Start Offset Negattive</span>
                                  </div>                                                 */}
                  </div>
                  <div className="row">
                    <div className="col-3" style={{ marginTop: "0px" }}>
                      <span style={{ fontSize: "12px" }}>{lang.start_type_label}</span>
                    </div>
                    <div className="col-9">
                      <div className="form-group">
                        <label htmlFor="TabView"></label>
                        <DropDownList
                          style={{ backgroundColor: "white" }}
                          data={PROTOTYPETRIGGERTYPE}
                          name="StartTriggerType"
                          textField="Description"
                          dataItemKey="ID"
                          defaultValue={PROTOTYPETRIGGERTYPE[1]}
                          value={dataItem.StartTriggerType}
                          onChange={onChange}
                          validator={(value) =>
                            value ? "" : "Please select the value"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-5 ml-3"
              >
                <div >
                  <div className="row mt-1">
                    <div className="col-3 mt-4">
                      <span style={{ fontSize: "12px" }}>{lang.end_offset_label}</span>
                    </div>
                    <div className="col-5">
                      <div className="form-group" style={{ textAligh: "left" }}>
                        <label htmlFor="">{lang.time_hh_mm_ss_ff_label}</label>
                        <TimePickerWithFormat
                          className="form-control form-control-sm"
                          name="EndOffset"
                          value={dataItem.EndOffset}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <span className="col-4" style={{ marginRight: "0px" }}>
                      <input
                        name={"IsEndNegative"}
                        type={"Checkbox"}
                        value={dataItem.IsEndNegative}
                        onChange={onChange}
                        checked={dataItem.IsEndNegative}
                        style={{
                          marginTop: "30px",
                          textAlign: "right",
                          marginRight: "5px",
                        }}
                      />
                      {lang.negative_label}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-3" style={{ marginTop: "0px" }}>
                      <span style={{ fontSize: "12px" }}>{lang.end_type_label}</span>
                    </div>
                    <div className="col-9">
                      <div className="form-group">
                        <label htmlFor="TabView"></label>
                        <DropDownList
                          style={{ backgroundColor: "white" }}
                          data={PROTOTYPETRIGGERTYPE}
                          name="EndTriggerType"
                          textField="Description"
                          dataItemKey="ID"
                          value={dataItem.EndTriggerType}
                          onChange={onChange}
                          validator={(value) =>
                            value ? "" : "Please select the value"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base float-right" style={{marginBottom:"4px"}} onClick={()=>saveNewSecondaryList()}>
                  {lang.update_button_text}
                </button>
              </div>
      </div>
       



     <DialogActionsBar>
         <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={()=>{ props.setShowSecondaryEventOverviewDialog(false) }}>
             {lang.cancel_button_text}
         </button>
         <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => {
            props.saveSecondaryChangedData(secondaryEventData.filter((x) => x.SlotDateTime == scheduleItem.SlotDateTime), scheduleItem);
            }}>
             {lang.confirm_button_text}
         </button>
     </DialogActionsBar>
 </Dialog>
  )
}